import React, { useEffect, useState } from "react";
import translator from "@/translator";
import QRCode from "react-qr-code";
import { Typography, Space, Alert, Card, Button } from "antd";
import LoadingWrapper from "@components/LoadingWrapper";
import BackButton from "@/note_office/shared/BackButton";
import { Socket } from "phoenix";

const { Text, Title } = Typography;
const t = translator("note_office.views.whatsapp.index_view.");

const SuccessBox = () => {
  return (
    <div className="whatsapp-page__index_page-card_box">
      <Card className="whatsapp-page__index_page-card_box-card">
        <Title level={3}>{t("session_active")}</Title>
      </Card>
    </div>
  );
};

const QrCodeBox = ({ qrCode, inProgress, error }) => {
  return (
    <Space direction="vertical" size={22}>
      <Space direction="vertical" size={0}>
        <Title level={5}>{t("title")}</Title>
        <Text>{t("text")}</Text>
      </Space>
      <LoadingWrapper loading={inProgress} tip={error}>
        <div className="whatsapp-page__index_page-qr_code_box">
          {error ? (
            <Alert message={error} type="error" />
          ) : (
            <QRCode value={qrCode} size={256} level="H" bgColor="#ffffff" fgColor="#000000" />
          )}
        </div>
      </LoadingWrapper>
    </Space>
  );
};

const IndexView = ({ socket_url, socket_room, api_key, back_button_url }) => {
  const [authStatus, setAuthStatus] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [errorConnectionText, setErrorConnectionText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const socket = new Socket(socket_url, {
      params: { token: api_key },
      logger: (kind, msg, data) => {
        console.log(`${kind}: ${msg}`, data);
      },
    });

    socket.connect();

    const onError = (ev) => {
      console.log("ERROR", ev);
      setLoading(false);
      setErrorConnectionText(t("socket.error"));
    };

    socket.onOpen((ev) => console.log("OPEN", ev));
    socket.onError((ev) => onError(ev));
    socket.onClose((e) => console.log("CLOSE", e));

    const channel = socket.channel(socket_room, {});

    const onAuthState = (msg) => {
      setAuthStatus(msg.auth);

      if (msg.qr_code) {
        setQrCode(msg.qr_code);
        setLoading(false);
      } else {
        setLoading(true);
      }
    };

    channel
      .join()
      .receive("ok", (response) => {
        console.log("Joined successfully", response);
      })
      .receive("error", (response) => {
        console.log("Unable to join", response);
      });

    channel.on("auth_state", (msg) => onAuthState(msg));

    channel.onError((e) => console.log("something went wrong", e));
    channel.onClose((e) => {
      onError(e);
      console.log("channel closed", e);
    });

    return () => {
      channel.leave();
      socket.disconnect();
    };
  }, []);

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <BackButton link={back_button_url} text={t("back_button")} />
      {authStatus ? (
        <SuccessBox />
      ) : (
        <QrCodeBox qrCode={qrCode} inProgress={loading} error={errorConnectionText} />
      )}
    </Space>
  );
};

export default IndexView;
