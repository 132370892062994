import translator from "@/translator";
import React, { useState, useEffect } from "react";
import { Form, message, Spin, Space } from "antd";
import axios from "axios";
import BackButton from "@/note_office/shared/BackButton";
import ProfileForm from "./components/ProfileForm";
import Helper from "@/helper";

const t = translator("note_office.views.profile.settings_view.");

const SettingsView = ({ profileData }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const csrfToken = Helper.getCSRFToken();

  useEffect(() => {
    if (profileData) {
      form.setFieldsValue({
        phone: profileData.phone,
        email: profileData.email,
        timezone: profileData.timezone,
        locale: profileData.locale,
      });
    }
    setLoading(false);
  }, [profileData]);

  const onFinish = async (values) => {
    setSaving(true);

    try {
      const response = await axios.put(
        profileData.update_profile_url,
        { profile: values },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
          },
        },
      );
      message.success(t("success_message"));
      window.location.href = response.data.redirect_path;
    } catch (error) {
      if (error.response?.data?.errors) {
        form.setFields(
          Object.entries(error.response.data.errors).map(([field, messages]) => ({
            name: field,
            errors: messages,
          })),
        );
      } else {
        message.error(t("error_message"));
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      {loading ? (
        <Spin size="large" className="loader" />
      ) : (
        <>
          <BackButton link={profileData.profile_url} title={t("title")} />

          <ProfileForm
            form={form}
            loading={loading}
            saving={saving}
            onFinish={onFinish}
            logout_link={profileData.logout_profile_url}
            delete_link={profileData.update_profile_url}
            locales={profileData.locales}
            timezones={profileData.timezones}
          />
        </>
      )}
    </div>
  );
};

export default SettingsView;
