import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';
import Button from '@components/Button';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';

import translator from '@/translator';
const t = translator('front_office.views.integrations.telegram_tdlib.phone_form.');

export const PhoneForm = ({
  setStep,
  forwardStep,
  backwardStep,
  routingSelectorStep,
  passwordStep,
  setCustomerHasTdLib,
  foreignCustomer,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [inputStatus, setInputStatus] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const defaultCountry = foreignCustomer ? 'ae' : 'ru';

  const onFinish = () => {
    sendPhoneNumber();
    setInputStatus({
      validateStatus: 'validating',
      help: t('sending_number'),
    });
  };

  const setError = (errorMessage) => {
    setInputStatus({
      validateStatus: 'error',
      help: errorMessage,
    });
  };

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
    setInputStatus({});
    setButtonDisabled(phone ? false : true);
  };

  const sendPhoneNumber = () => {
    setFormDisabled(true);
    setButtonDisabled(true);
    const tdlibData = new FormData();
    tdlibData.append('tdlib_session[phone]', phoneNumber);

    const queryOptions = {
      method: 'PATCH',
      headers: {
        Accept: 'text/javascript',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      body: tdlibData,
    };

    const requestServer = async (url, options = {}) => {
      const response = await fetch(url, options);
      const body = await response.json();
      setFormDisabled(false);
      setButtonDisabled(false);
      return body;
    };

    const handleResponse = (body: { errors: []; success: string }) => {
      if (body.success) {
        const handleStatus = {
          authorized: () => {
            setCustomerHasTdLib(true);
            setStep(routingSelectorStep);
          },
          wait_phone: () => setError(t('check_number')),
          wait_code: () => setStep(forwardStep),
          wait_password: () => setStep(passwordStep),
        };
        handleStatus[body.success]();
      } else {
        const { errors } = body;
        setError(errors);
      }
    };

    requestServer('/front_office/send_phone', queryOptions)
      .then((body) => handleResponse(body))
      .catch((error) => {
        console.error('Cant fetch', error);
        setError(t('server_error'));
      });
  };

  return (
    <div className="telegram-flow_split-pane_right">
      <Row justify="center">
        <Col>
          <p className="telegram-flow_sub-title">{t('please_enter_phone')}</p>
        </Col>
      </Row>
      <Form onFinish={onFinish}>
        <Row>
          <Col>
            <Form.Item className="telegram-flow_bottom-spacing" {...inputStatus}>
              <PhoneInput
                inputProps={{ autoFocus: true }}
                country={defaultCountry}
                value={phoneNumber}
                onEnterKeyPress={onFinish}
                onChange={(phone) => handlePhoneChange(phone)}
                placeholder={t('phone_placeholder')}
                inputClass="telegram-flow_input"
                inputStyle={{ width: '320px' }}
                disabled={formDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <div className="telegram-flow_footer">
                <Button
                  htmlType="button"
                  className="btn_v2-secondary"
                  onClick={() => setStep(backwardStep)}
                >
                  {t('back')}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  className={classNames('btn_v2', { 'btn_v2-primary_disabled': buttonDisabled })}
                  disabled={buttonDisabled}
                >
                  {t('next')}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
