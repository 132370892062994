import React, { useState } from "react";
import { Col, Checkbox, Button, Typography, Dropdown, Select } from "antd";
import { DownOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useFormContext, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useNotificationsState } from "./State";
const { Text } = Typography;
import translator from "@/translator";
const t = translator("front_office.views.yclients_notification_settings.");

const FilterCategory = ({ name }) => {
  const { control, setValue, watch } = useFormContext();
  const { state } = useNotificationsState();
  const isEnabled = watch(`filters.${name}_enabled`);
  const label = t(`fields.filters.${name}`);
  const placeholder = t(`fields.filters.${name}_placeholder`);

  const modeOptions = {
    items: state.filters.cats_modes,
    onClick: ({ key }) => setValue(`filters.${name}_mode`, key),
  };

  const options = state.filters.cats_values[state.selectedSalon]?.[name] ?? [];

  const valueToLabel = (value) => {
    return modeOptions.items.find((item) => item.key === value).label;
  };

  return (
    <div className="yclients-notification-settings-create__filters_cats-i">
      <Controller
        control={control}
        name={`filters.${name}_enabled`}
        defaultValue={false}
        render={({ field: { onChange, value } }) => (
          <div className="yclients-notification-settings-create__filters_cats-mode">
            <Checkbox checked={value} onChange={onChange}>
              {label}
            </Checkbox>

            <Controller
              control={control}
              name={`filters.${name}_mode`}
              defaultValue={"allow"}
              render={({ field: { value } }) => (
                <Dropdown overlayClassName="ant-custom-dropdown" menu={modeOptions}>
                  <div>
                    <span>{valueToLabel(value)}</span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              )}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name={`filters.${name}`}
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            onChange={onChange}
            size="large"
            disabled={!isEnabled}
            className="ant-custom-select"
            popupClassName="ant-custom-select-dropdown"
            style={{ width: "100%" }}
            mode="multiple"
            optionFilterProp="label"
            options={options}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  );
};

const AdvancedSettings = () => {
  return null; // temp. disable while backend is not yet ready

  const { action_type } = useParams();
  const { watch } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  const selectedServices = watch("salon_service_id_and_title", []);
  const isDisabled = (selectedServices || []).length === 0 && !isOpen;

  const toggleOpen = () => {
    setIsOpen((open) => !open);
  };

  if (action_type === "cross_sell" || action_type === "return_visit") {
    return null;
  }

  return (
    <>
      <Col span={24}>
        <Button
          className="ant-custom-btn"
          type="primary"
          style={{ width: "100%" }}
          onClick={toggleOpen}
          disabled={isDisabled}
          icon={isOpen ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        >
          {isOpen ? t("fields.hide_advanced_settings") : t("fields.show_advanced_settings")}
        </Button>
      </Col>
      {isOpen && (
        <>
          <Col sm={24} xl={11}>
            <Text strong>{t("fields.filters.cats")}</Text>
          </Col>
          <Col sm={0} xl={2} />
          <Col sm={24} xl={11} className="yclients-notification-settings-create__filters_cats">
            <FilterCategory name="booking_cats" />
            <FilterCategory name="service_cats" />
            <FilterCategory name="client_cats" />
            <FilterCategory name="staff_cats" />
          </Col>
        </>
      )}
    </>
  );
};

export default AdvancedSettings;
