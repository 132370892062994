import ReactRailsUJS from "react_ujs";
import { viteConstructorRequireContext } from "@/viteHelper";

const componentsRequireContext = import.meta.glob("~/**/*.{js,jsx,ts,tsx}", { eager: true });
ReactRailsUJS.getConstructor = viteConstructorRequireContext(componentsRequireContext);

import {
  go_to_step,
  play_firework,
  init_whats_app,
  select_dispatch_routing,
  whats_app_open_modal,
  restart_step_one,
  change_delivery_length_limit,
} from './utils_scripts';

window.go_to_step = go_to_step;
window.play_firework = play_firework;
window.init_whats_app = init_whats_app;
window.select_dispatch_routing = select_dispatch_routing;
window.whats_app_open_modal = whats_app_open_modal;
window.restart_step_one = restart_step_one;
window.change_delivery_length_limit = change_delivery_length_limit;
