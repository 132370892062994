import React from 'react';
import { Row, Col, Button } from 'antd';
import Characters from '@front_office/Icons/developer_dashboard/Characters';
import Box from '@front_office/Icons/developer_dashboard/Box';
import Success from '@front_office/Icons/developer_dashboard/Success';
import Reset from '@front_office/Icons/developer_dashboard/Reset';
import Currency from '@front_office/Icons/developer_dashboard/Currency';
import Pole from '@front_office/Icons/developer_dashboard/Pole';
import Pencil from '@front_office/Icons/developer_dashboard/Pencil';

import translator from '@/translator';
const t = translator('front_office.views.developer_dashboard.');

const DashboardView = ({
  count,
  active_count,
  renew_sub_count,
  sub_price,
  test_period,
  developer_url,
  docs_url,
}) => {
  return (
    <div className="developer_dashboard">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={24} lg={12}>
          <div className="introduction-card">
            <div className="introduction-card__header">{t('introduction.title')}</div>
            <div className="introduction-card__body">
              <div className="introduction-card__body__desc">{t('introduction.desc')}</div>
              <div className="introduction-card__body__back_img">
                <Characters />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} md={8} lg={4}>
          <div className="developer-card mini">
            <div className="developer-card__header">
              <Box />
            </div>
            <div className="developer-card__body">{t('instance.counting_text')}</div>
            <div className="developer-card__footer mini">{count}</div>
          </div>
        </Col>
        <Col xs={24} md={8} lg={4}>
          <div className="developer-card mini">
            <div className="developer-card__header">
              <Success />
            </div>
            <div className="developer-card__body">{t('instance.active_counting_text')}</div>
            <div className="developer-card__footer mini">{active_count}</div>
          </div>
        </Col>
        <Col xs={24} md={8} lg={4}>
          <div className="developer-card mini">
            <div className="developer-card__header">
              <Reset />
            </div>
            <div className="developer-card__body">{t('instance.renew_sub_counting_text')}</div>
            <div className="developer-card__footer mini">{renew_sub_count}</div>
          </div>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <div className="developer-card big">
            <div className="developer-card__header">
              <div>
                <Pole />
              </div>
              <div>{t('instance_card.title')}</div>
            </div>
            <div className="developer-card__body big">{t('instance_card.desc')}</div>
            <div className="developer-card__footer big">
              <Button size="large" rootClassName={'vanilla-ant-d'} href={developer_url}>
                {t('instance_card.button')}
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <div className="developer-card big">
            <div className="developer-card__header">
              <div>
                <Currency />
              </div>
              <div>{t('tariff_card.title')}</div>
            </div>
            <div className="developer-card__body">
              <p>{t('tariff_card.subs', { price: sub_price })}</p>
              <p>{t('tariff_card.test_days', { days: test_period })}</p>
            </div>
            <div className="developer-card__footer big"></div>
          </div>
        </Col>
        <Col xs={24} md={24} lg={8}>
          <div className="developer-card big">
            <div className="developer-card__header">
              <div>
                <Pencil />
              </div>
              <div>{t('api_card.title')}</div>
            </div>
            <div className="developer-card__body big">{t('api_card.desc')}</div>
            <div className="developer-card__footer big">
              <Button size="large" rootClassName={'vanilla-ant-d'} href={docs_url}>
                {t('api_card.button')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardView;
