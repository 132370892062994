import React from "react";
import { Menu } from "antd";
import translator from "@/translator";
import {
  Wallet,
  Next,
  Settings,
  Crown,
  File,
  Refresh,
  Sms,
  User,
  Whatsapp,
} from "@/note_office/icons/profile";

const t = translator("note_office.views.profile.menuItems.");

const ProfileMenu = ({ profileData }) => {
  const menuSubscription = [
    {
      key: "subscription",
      icon: <Wallet />,
      label: (
        <div className="profile__menu-item">
          <a href={profileData.subscriptions_url} className="profile__menu-link">
            {t("subscription")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
  ];

  const menuAccountSetting = [
    {
      key: "settings",
      icon: <Settings />,
      label: (
        <div className="profile__menu-item">
          <a href={profileData.settings_url} className="profile__menu-link">
            {t("account_settings")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
  ];

  const menuCommunication = [
    {
      key: "whatsapp",
      icon: <Whatsapp />,
      label: (
        <div className="profile__menu-item">
          <a href="/note_office/whatsapp" className="profile__menu-link">
            {t("whatsapp_settings")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
    {
      key: "sms",
      icon: <Sms />,
      label: (
        <div className="profile__menu-item">
          <a href="/note_office/sms" className="profile__menu-link">
            {t("sms_settings")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
    {
      key: "mass_mailing",
      icon: <Sms />,
      label: (
        <div className="profile__menu-item">
          <a href={profileData.bulk_deliveries_url} className="profile__menu-link">
            {t("mass_mailing")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
  ];

  const menuExtras = [
    {
      key: "referral",
      icon: <Crown />,
      label: (
        <div className="profile__menu-item">
          <a href={profileData.referrals_url} className="profile__menu-link">
            {t("referral_program")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
    {
      key: "assistant",
      icon: <User />,
      label: (
        <div className="profile__menu-item">
          <a href="/note_office/assistant" className="profile__menu-link">
            {t("assistant_admin")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
    {
      key: "forms",
      icon: <File />,
      label: (
        <div className="profile__menu-item">
          <a href="/note_office/forms" className="profile__menu-link">
            {t("forms")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
    {
      key: "history",
      icon: <Refresh />,
      label: (
        <div className="profile__menu-item">
          <a href={profileData.deliveries_url} className="profile__menu-link">
            {t("history")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Menu
        mode="vertical"
        items={menuSubscription}
        className="profile__menu profile__menu-margin"
        selectedKeys={[]}
      />

      <Menu
        mode="vertical"
        items={menuAccountSetting}
        className="profile__menu profile__menu-margin"
        selectedKeys={[]}
      />

      <Menu
        mode="vertical"
        items={menuCommunication}
        className="profile__menu profile__menu-margin"
        selectedKeys={[]}
      />

      <Menu mode="vertical" items={menuExtras} className="profile__menu" selectedKeys={[]} />
    </>
  );
};

export default ProfileMenu;
