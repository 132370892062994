import Chatpush from "./Chatpush.jsx";
import ConnectChategy from "./ConnectChategy.jsx";
import Chategy from "./Chategy.jsx";
import Jetapi from "./Jetapi.jsx";

const LogoCollection = {
  "chatpush.ru": Chatpush,
  "connect.chategy.io": ConnectChategy,
  "jetapi.io": Jetapi,
  "chategy.io": Chategy,
};

export default LogoCollection;
