import React, { FocusEvent } from 'react';
import { useState } from 'react';
import { Row, Cell } from '@components/grid';
import { InfoPane } from '../InfoPane';
import Button from '@components/Button';
import { Form, Input } from 'antd';
import { string } from 'yup';

import translator from '@/translator';
const t = translator('front_office.views.integrations.telegram_bot.site_settings.');

interface Props {
  setStep: (arg: string) => void;
  telegramBotsUrl: string;
  menuTitle: string;
  setMenuTitle: (arg: string) => void;
  menuLink: string;
}

export default ({ setStep, telegramBotsUrl, menuTitle, setMenuTitle, menuLink }: Props) => {
  const [inputStatus, setInputStatus] = useState({});

  const handleSubmit = (newMenuLink: string) => {
    const siteLinkData = new FormData();
    siteLinkData.append('telegram_bot[menu_title]', menuTitle);
    siteLinkData.append('telegram_bot[menu_link]', newMenuLink);
    siteLinkData.append('telegram_bot[bot_update]', 'true');

    const queryOptions = {
      method: 'PATCH',
      headers: {
        Accept: 'text/html',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      body: siteLinkData,
    };

    const sendSiteData = async (url: RequestInfo | URL, options: RequestInit) => {
      const response = await fetch(url, options);
      const body = await response.json();
      return body;
    };

    sendSiteData(telegramBotsUrl, queryOptions).catch((error) => {
      console.error('Cant fetch', error);
    });
  };

  const yupUrlObject = string().matches(
    /^((http|https):\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\/{0,1}[\w!$&'()*+,;=:%._-]*)*$/,
  );

  const setError = () => {
    setInputStatus({
      validateStatus: 'error',
      help: t('invalid_format_help_text'),
    });
  };

  const onFinish = ({ siteLink }) => {
    const trimmedLink = siteLink.trim();
    if (trimmedLink) {
      if (yupUrlObject.isValidSync(trimmedLink)) {
        handleSubmit(trimmedLink);
        setStep('lastStep');
      } else {
        setError();
      }
    } else {
      setInputStatus({
        validateStatus: 'error',
        help: t('empty_link_help_text'),
      });
    }
  };

  const onChange = (e) => {
    const { value } = e.target;
    setMenuTitle(value || t('menu_text'));
  };

  const handleInputChange = () => {
    setInputStatus({});
  };

  const checkUrl = (event: FocusEvent<HTMLInputElement, Element>) => {
    const { value } = event.target;
    if (value && !yupUrlObject.isValidSync(value.trim())) {
      setError();
    }
  };

  return (
    <div className="telegram-flow_split-pane_right">
      <Row>
        <Cell>
          <InfoPane title={t('link_title')}>
            <p>{t('link_description')}</p>
          </InfoPane>
        </Cell>
      </Row>
      <Form onFinish={onFinish}>
        <Row>
          <Cell>
            <p className="telegram-flow_text">{t('address_text')}</p>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <Form.Item
              {...inputStatus}
              className="telegram-flow_bottom-spacing"
              name="siteLink"
              initialValue={menuLink}
            >
              <Input
                className="telegram-flow_input"
                onChange={handleInputChange}
                onBlur={(event) => checkUrl(event)}
              />
            </Form.Item>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <p className="telegram-flow_text">{t('button_text')}</p>
          </Cell>
        </Row>
        <Row className="telegram-flow_bottom-spacing">
          <Cell>
            <Form.Item>
              <Input showCount maxLength={15} defaultValue={menuTitle} onChange={onChange} />
            </Form.Item>
          </Cell>
          <Cell className="telegram-flow_explanation-text_bot">
            <p>{t('button_text_explanation')}</p>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <Form.Item>
              <div className="telegram-flow_footer">
                <Button
                  className="btn_v2-secondary"
                  text={t('back_button_text')}
                  onClick={() => setStep('routingSelector')}
                />
                <Button className="btn_v2-primary" text={t('forward_button_text')} />
              </div>
            </Form.Item>
          </Cell>
        </Row>
      </Form>
    </div>
  );
};
