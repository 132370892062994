import React, { useState, useEffect } from "react";
import { Start, WhatsApp, Sms, Manual, PaymentMethods, End } from "./variants";
import Router from "@/router";
import jstz from "jstz";

import balance from "@imgs/front_office/payment/balance.svg";
import cg_balance from "@imgs/front_office/payment/chategy.io/balance.svg";
import jet_balance from "@imgs/front_office/payment/jetapi.io/balance.svg";
import sms from "@imgs/front_office/payment/sms.svg";
import whats_app from "@imgs/front_office/payment/whats_app.svg";
import cg_whats_app from "@imgs/front_office/payment/chategy.io/whats_app.svg";
import jet_whats_app from "@imgs/front_office/payment/jetapi.io/whats_app.svg";
import end from "@imgs/front_office/payment/end.svg";
import cg_end from "@imgs/front_office/payment/chategy.io/end.svg";
import jet_end from "@imgs/front_office/payment/jetapi.io/end.svg";
import translator from "@/translator";

const whats_app_translation = translator("front_office.views.payments.variants.whats_app_success.");
const sms_translation = translator("front_office.views.payments.variants.sms_success.");

const balanceImg = (projectScope) => {
  switch (projectScope) {
    case "chatpush.ru":
      return balance;
    case "jetapi.io":
      return jet_balance;
    default:
      return cg_balance;
  }
};

const whatsAppImg = (projectScope) => {
  switch (projectScope) {
    case "chatpush.ru":
      return whats_app;
    case "jetapi.io":
      return jet_whats_app;
    default:
      return cg_whats_app;
  }
};

const smsImg = (projectScope) => {
  switch (projectScope) {
    case "chatpush.ru":
      return sms;
    case "jetapi.io":
      return jet_balance;
    default:
      return cg_balance;
  }
};

const endImg = (projectScope) => {
  switch (projectScope) {
    case "chatpush.ru":
      return end;
    case "jetapi.io":
      return jet_end;
    default:
      return cg_end;
  }
};

const PaymentView = ({
  initStep,
  payment_object,
  has_payment_status,
  sms_enabled,
  sms_payment_list,
  create_url,
  subscription_enabled,
  whatsapp_data,
  onboarding_url,
  edit_customer_url,
  gathering_timezone_on_payments,
  update_customer_path,
  reccurentPaymentsUrl,
  payment_methods,
  currency,
  project_scope,
  paid_period_translation,
  skip_renew_sub,
}) => {
  const [step, setStep] = useState(initStep || "start");
  const [payment_sum, setPaymentSum] = useState(0);
  const [payment_type, setPaymentType] = useState();
  const [period, setPeriod] = useState();
  const [paymentObject, setPaymentObject] = useState();
  const [paidPeriodTranslation, setPaidPeriodTranslation] = useState("");
  const [skipRenewSub, setSkipRenewSub] = useState();

  useEffect(() => {
    if (["whats_app", "sms", "manual"].includes(step)) {
      setPaymentType(step);
    }
  });

  const textElements = (paymentObject, skipRenewSub) => {
    const defaultTextElements = {
      buttonLink: reccurentPaymentsUrl,
      success_header: sms_translation("success_header"),
      success_message: sms_translation("success_message"),
      card_link_text: sms_translation("card_link_text"),
      card_link_desc: sms_translation("card_link_desc"),
      isButtonShowed: true,
    };
    switch (paymentObject) {
      case "sms":
        return defaultTextElements;
      case "whats_app":
        return {
          success_header: whats_app_translation("success_header"),
          success_message: whats_app_translation("success_message"),
          card_link_desc:
            skipRenewSub === "true"
              ? whats_app_translation("card_link_skip_renew_desc")
              : whats_app_translation("card_link_desc", { paid_until: paid_period_translation }),
          isButtonShowed: false,
        };
      default:
        return defaultTextElements;
    }
  };

  if (gathering_timezone_on_payments) {
    useEffect(() => {
      Router.patch(update_customer_path, { time_zone: jstz.determine().name() }, "customer");
    });
  }

  const currentComponent = {
    start: (
      <Start
        subscription_enabled={subscription_enabled}
        onboarding_url={onboarding_url}
        setStep={setStep}
        setPaymentType={setPaymentType}
        sms_enabled={sms_enabled}
        whatsAppImg={whatsAppImg(project_scope)}
        smsImg={smsImg(project_scope)}
        balanceImg={balanceImg(project_scope)}
      />
    ),
    whats_app: (
      <WhatsApp
        whatsAppImg={whatsAppImg(project_scope)}
        whatsapp_data={whatsapp_data}
        setStep={setStep}
        setPaymentSum={setPaymentSum}
        setPaidPeriodTranslation={setPaidPeriodTranslation}
        setSkipRenewSub={setSkipRenewSub}
        currency={currency}
        setPeriod={setPeriod}
        setPaymentObject={setPaymentObject}
      />
    ),
    sms: (
      <Sms
        smsImg={smsImg(project_scope)}
        setStep={setStep}
        sms_payment_list={sms_payment_list}
        setPaymentSum={setPaymentSum}
        setPaymentObject={setPaymentObject}
      />
    ),
    manual: (
      <Manual smsImg={smsImg(project_scope)} setStep={setStep} setPaymentSum={setPaymentSum} />
    ),
    payment_method: (
      <PaymentMethods
        projectScope={project_scope}
        create_url={create_url}
        setStep={setStep}
        payment_type={payment_type}
        payment_sum={payment_sum}
        payment_methods={payment_methods}
        currency={currency}
        period={period}
        paymentObject={paymentObject}
        skipRenewSub={skipRenewSub}
        paidPeriodTranslation={paidPeriodTranslation}
      />
    ),
    end: (
      <End
        endImg={endImg(project_scope)}
        has_payment_status={has_payment_status}
        edit_customer_url={edit_customer_url}
        textElements={textElements(payment_object, skip_renew_sub)}
      />
    ),
  };

  return <div className="payment">{currentComponent[step]}</div>;
};

export default PaymentView;
