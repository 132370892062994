import React from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Cell } from "@components/grid";
import Button from "@components/Button";
import small_cross from "@imgs/front_office/shared/small_cross.svg";
import small_checkmark from "@imgs/front_office/shared/small_checkmark.svg";
import Helper from "@/helper";
import PaymentRadios from "./PaymentRadios";

import translator from "@/translator";
const t = translator("front_office.views.payments.variants.whatsapp.");

const WhatsApp = ({
  whatsapp_data,
  setPaymentSum,
  setStep,
  currency,
  whatsAppImg,
  setPeriod,
  setPaymentObject,
  setPaidPeriodTranslation,
  setSkipRenewSub,
}) => {
  const {
    subscription_cost,
    tariff_title,
    tariff_title_cross,
    tariff_desc,
    whatsapp_payment_list,
    subscription_period_translation,
  } = whatsapp_data;

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if ($.isEmptyObject(errors)) {
      setPaymentSum(data.payment_sum);
      setPeriod(data.period);
      setPaidPeriodTranslation(data.translation);
      setSkipRenewSub(data.skip_renew_sub);
      setPaymentObject("whats_app");
      setStep("payment_method");
    }
  };

  const payment_sum_validate = register("payment_sum", {
    required: true,
    valueAsNumber: true,
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Cell md={4} lg={4}></Cell>
          <Cell md={4} lg={4}>
            <div className="payment-header vertical whatsapp">
              <div className="img_box">
                <img src={whatsAppImg} />
              </div>
              <div>
                <h3>{t("whatsapp_pay")}</h3>
                <div className="tariff-title">
                  {t("your_tariff")}{" "}
                  <div className="tariff-label">{subscription_period_translation}</div>
                </div>
                <div className="tariff-sub_title">
                  <img src={(tariff_title_cross && small_cross) || small_checkmark} />{" "}
                  {tariff_title}
                </div>
                <div className="tariff-desc" dangerouslySetInnerHTML={{ __html: tariff_desc }} />
              </div>
            </div>
          </Cell>
          <Cell md={4} lg={4}></Cell>
        </Row>
        <Row>
          <Cell md={4} lg={4}></Cell>
          <Cell md={4} lg={4}>
            <div className="payment-body">
              <div className="errors_box">
                {errors.payment_sum && <span>{t("errors_payment_sum")}</span>}
              </div>
              <PaymentRadios list={whatsapp_payment_list} setValue={setValue} currency={currency} />
              <input type="hidden" name="payment_sum" {...payment_sum_validate} />
            </div>
          </Cell>
          <Cell md={4} lg={4}></Cell>
        </Row>
        <Row>
          <Cell md={4} lg={4}></Cell>
          <Cell md={4} lg={4}>
            <div className="payment-footer vertical">
              <Button htmlType={"submit"} className={"btn_v2-primary"} text={t("button_next")} />
              <Button
                className={"btn_v2-secondary"}
                text={t("button_back")}
                onClick={() => setStep("start")}
              />
            </div>
          </Cell>
          <Cell md={4} lg={4}></Cell>
        </Row>
      </form>
    </Container>
  );
};

export default WhatsApp;
