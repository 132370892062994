import React, { useState, useEffect } from 'react';
import { Popover, Avatar, Badge, Spin } from 'antd';
import BellRingin from '@front_office/Icons/BellRingin';
import Wallet from '@front_office/Icons/Wallet';
import MenuBurger from '@front_office/Icons/MenuBurger';
import ReadyToHelp from '@front_office/components/ReadyToHelp';
import DownOutlined from '@front_office/Icons/DownOutlined';
import classNames from 'classnames';

import { locale, withoutCurrentLocales } from '@/translator';

import axios from 'axios';

const CustomerInfoBox = ({ customer, account_balance }) => {
  const [openAvatarPopover, setOpenAvatarPopover] = useState(false);
  const [openLangPopover, setOpenLangPopover] = useState(false);

  const handleOpenChangeAvatarPopover = (newOpen) => {
    setOpenAvatarPopover(newOpen);
  };

  const handleOpenChangeLangPopover = (newOpen) => {
    setOpenLangPopover(newOpen);
  };

  const avatarPopoverContent = () => {
    return (
      <div className="navbar_v2-customer_popover">
        <div className="navbar_v2-customer_popover__info">
          <ReadyToHelp {...customer} />
        </div>
      </div>
    );
  };

  const switchLang = (lang) => {
    axios({
      method: 'post',
      url: `/front_office/customers/switch_lang`,
      data: { locale: lang },
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        location.reload();
      }
    });
  };

  const langPopoverContent = () => {
    const langs = withoutCurrentLocales();

    return (
      <div className="navbar_v2-customer_popover">
        <div className="navbar_v2-customer_popover__langs">
          {langs.map((lang) => {
            return (
              <div
                key={`navbar_v2-customer_popover__langs-item-${lang}`}
                className="navbar_v2-customer_popover__langs-item"
                onClick={() => switchLang(lang)}
              >
                {lang.toUpperCase()}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="navbar_v2-wallet_with_avatar">
      <div className="navbar_v2-wallet_with_avatar__wallet">
        <div className="icon">
          <Wallet />
        </div>
        <div className="sum">{account_balance}</div>
      </div>
      <div className="navbar_v2-wallet_with_avatar__avatar">
        <Popover
          content={avatarPopoverContent}
          trigger="click"
          open={openAvatarPopover}
          onOpenChange={handleOpenChangeAvatarPopover}
          overlayClassName="navbar_v2-popover"
          showArrow={false}
        >
          <Avatar size={40} icon={customer.email[0]} />
        </Popover>
      </div>
      <div className="navbar_v2-wallet_with_avatar__switch_lang">
        <Popover
          content={langPopoverContent}
          trigger="click"
          open={openLangPopover}
          showArrow={false}
          overlayClassName="navbar_v2-wallet_with_avatar__switch_lang-popover"
          onOpenChange={handleOpenChangeLangPopover}
        >
          <div className="navbar_v2-wallet_with_avatar__switch_lang-picker">
            <div>{locale().toUpperCase()}</div>
            <div>
              <DownOutlined />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

const NotificationPopover = ({ notification_absence_text }) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getNotifications = () => {
    axios({
      method: 'post',
      url: `/front_office/customers_read_notifications`,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    })
      .then(({ data }) => {
        if (data.data.notifications) {
          setNotifications(data.data.notifications);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const formatingNotifications = () => {
    return isLoading ? (
      <Spin spinning />
    ) : (
      <>
        {notifications?.length
          ? notifications.map((notification) => {
              return (
                <div
                  className="navbar_v2-notifications_popover__notification"
                  key={`notication-${notification.id}`}
                >
                  <div
                    className={classNames([
                      'navbar_v2-notifications_popover__notification-alert',
                      ` navbar_v2-notifications_popover__notification-alert-${notification.category}`,
                    ])}
                  >
                    <BellRingin />
                  </div>
                  <div className="navbar_v2-notifications_popover__notification-content_box">
                    <div className="navbar_v2-notifications_popover__notification-content_box__title">
                      {notification.title}
                    </div>
                    <div
                      className="navbar_v2-notifications_popover__notification-content_box__content"
                      dangerouslySetInnerHTML={{ __html: notification.content }}
                    ></div>
                    <div className="navbar_v2-notifications_popover__notification-content_box__info">
                      {notification.created_at}
                    </div>
                  </div>
                </div>
              );
            })
          : notification_absence_text}
      </>
    );
  };
  return <div className="navbar_v2-notifications_popover">{formatingNotifications()}</div>;
};

const Navbar = ({ customer, account_balance, notifications }) => {
  const openMobileSidebar = () => {
    document.getElementById('sidebar_mobile_button_trigger').click();
  };
  const [openNotifications, setOpenNotifications] = useState(false);
  const [countNotifications, setCountNotifications] = useState(notifications.count);

  const handleOpenNotificationsChange = (newOpen) => {
    setOpenNotifications(newOpen);
    setCountNotifications(0);
  };

  return (
    <div className="navbar_v2">
      <div className="navbar_v2-container1">
        <CustomerInfoBox customer={customer} account_balance={account_balance} />
      </div>
      <div className="navbar_v2-container2">
        {notifications.show && (
          <Popover
            content={<NotificationPopover notification_absence_text={notifications.absence_text} />}
            trigger="click"
            open={openNotifications}
            showArrow={false}
            overlayClassName="navbar_v2-popover"
            onOpenChange={handleOpenNotificationsChange}
          >
            <Badge size="small" count={countNotifications}>
              <div className="navbar_v2-container2__alert">
                <div className="navbar_v2-container2__alert_icon">
                  <BellRingin />
                </div>
              </div>
            </Badge>
          </Popover>
        )}
        <CustomerInfoBox customer={customer} account_balance={account_balance} />
        <div className="navbar_v2-container2__menu_button" onClick={() => openMobileSidebar()}>
          <MenuBurger />
        </div>
      </div>
    </div>
  );
};
export default Navbar;
