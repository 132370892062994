import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm, FormProvider } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumb, Steps, Col, Row, Divider, Button, notification, Spin } from "antd";
import { useNotificationsState, getActionTypeName } from "./State";
import formSteps from "./formSteps.jsx";
import translator from "@/translator";
const t = translator("front_office.views.altegio_notification_settings.");

const showNotification = (type, config) => {
  const defaultConfig = {
    className: "ant-custom-notification",
  };

  notification[type]({ ...defaultConfig, ...config });
};

const Form = ({ isEditMode }) => {
  const { state } = useNotificationsState();
  const { id, action_type, salon_id } = useParams();
  const navigate = useNavigate();
  const steps = formSteps[action_type];
  const [currentStep, setCurrentStep] = useState(0);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialFormValues = () => {
    setIsLoading(true);
    return axios(`/front_office/altegio_notification_settings/${action_type}/${id}/edit`, {
      headers: { Accept: "application/json" },
    })
      .then(({ data }) => {
        if (data.success) {
          return data.frontend_data;
        } else {
          return {};
        }
      })
      .catch((_error) => {
        return {};
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formMethods = useForm({
    defaultValues: isEditMode
      ? () => fetchInitialFormValues()
      : {
          action_type: action_type,
          salon_id: salon_id,
        },
  });
  const {
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting, isLoading: isFormLoading },
  } = formMethods;
  const formValues = watch();

  const goToPrevStep = () => setCurrentStep((step) => step - 1);
  const goToNextStep = () => setCurrentStep((step) => step + 1);

  useEffect(() => {
    if (currentStep === 0) {
      setIsFirstStep(true);
      setIsLastStep(false);
    } else if (currentStep === steps.length - 1) {
      setIsFirstStep(false);
      setIsLastStep(true);
    } else {
      setIsFirstStep(false);
      setIsLastStep(false);
    }
  }, [currentStep]);

  const handlePrevButton = () => {
    if (isFirstStep) {
      navigate("/");
    } else {
      goToPrevStep();
    }
  };
  const handleNextButton = () => {
    if (!isLastStep) {
      goToNextStep();
    }
  };

  useEffect(() => {
    let validationRule = steps[currentStep]["validation"] || ((_) => true);
    let isValid = validationRule(formValues, state);
    setIsCurrentStepValid(isValid);
  }, [formValues, state, currentStep]);

  const onSubmit = (actionTypeName, formData, e) => {
    e.preventDefault();

    const preparedFormData = {
      altegio_notification_setting: {
        ...formData,
        dispatch_routing: formData.dispatch_routing
          .filter(([, isActive]) => isActive)
          .map(([channel]) => channel),
      },
    };

    return axios({
      method: isEditMode ? "PATCH" : "POST",
      data: preparedFormData,
      url: isEditMode
        ? `/front_office/altegio_notification_settings/${action_type}/${id}`
        : `/front_office/altegio_notification_settings/${action_type}`,
      headers: {
        Accept: "application/javascript",
        "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
      },
    })
      .then(({ data }) => {
        if (data.success) {
          showNotification("success", {
            description: actionTypeName,
            message: isEditMode ? t("edit.notification_updated") : t("create.notification_added"),
          });
          navigate("/");
        } else {
          data.errors.forEach((error) =>
            showNotification("error", { description: error, message: t("create.error") }),
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          showNotification("error", {
            description: error.response.data,
            message: t("create.error"),
          });
        } else if (error.request) {
          showNotification("error", {
            description: t("create.network_error"),
            message: t("create.error"),
          });
        } else {
          showNotification("error", { description: error.message, message: t("create.error") });
        }
      });
  };

  return (
    <>
      <Breadcrumb className="altegio-notification-settings-breadcrumb">
        <Breadcrumb.Item>{t("breadcrumb.altegio")}</Breadcrumb.Item>
        <Breadcrumb.Item
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          {t("breadcrumb.notifications")}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {t(isEditMode ? "breadcrumb.edit" : "breadcrumb.new", {
            name: getActionTypeName(action_type),
          })}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Steps
        responsive={false}
        className="ant-custom-steps"
        type="navigation"
        items={steps.map(({ title }) => ({ title }))}
        current={currentStep}
      />
      <FormProvider {...formMethods}>
        <Spin spinning={isLoading}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit.bind(this, getActionTypeName(action_type)))}
          >
            <input type="hidden" {...register("action_type")} />
            <input type="hidden" {...register("salon_id")} />

            <div className="altegio-notification-settings-create__body">
              {steps.map(({ Component }, index) => (
                <Component key={index} isVisible={currentStep === index} />
              ))}
            </div>

            <Divider />

            <Row justify="space-between">
              <Col>
                <Button
                  className="ant-custom-btn"
                  htmlType="button"
                  onClick={handlePrevButton}
                  disabled={isSubmitting}
                >
                  {isFirstStep ? t("create.cancel_button") : t("create.back_button")}
                </Button>
              </Col>
              <Col>
                <Button
                  className="ant-custom-btn"
                  htmlType={isLastStep ? "submit" : "button"}
                  type="primary"
                  onClick={handleNextButton}
                  disabled={!isCurrentStepValid || isSubmitting || isFormLoading}
                >
                  {isLastStep
                    ? isSubmitting
                      ? t("create.saving")
                      : t("create.save_and_close_button")
                    : t("create.next_button")}
                </Button>
              </Col>
            </Row>
          </form>
        </Spin>
      </FormProvider>
    </>
  );
};

export default Form;
