import React, { useEffect, useState, useMemo } from "react";
import qs from "qs";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Typography,
  Col,
  Row,
  Table,
  Space,
  Grid,
  Select,
  Checkbox,
  Pagination,
  Button,
  Input,
  Breadcrumb,
  notification,
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useClientsState } from "./State.js";
const Text = Typography.Text;

import translator from "@/translator";
const t = translator("front_office.views.yclients_customer_clients.booking_table.");

const ClientsTable = ({}) => {
  const { state, updateState } = useClientsState();
  let [searchParams, _] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [allCheckboxMarked, setAllCheckboxMarked] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [checkedClients, setCheckedClients] = useState([]);
  const [currentPerPage, setCurrentPerPage] = useState(
    searchParams.get("per_page") || state.per_pages[0],
  );
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const MAX_SELECTED_CLIENTS = 5000;
  const allSalonsOption = state.all_salons_option;

  const [filterParams, setFilterParams] = useState({
    yclients_customer_id_in: [allSalonsOption.value],
  });

  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();

  const showNotification = (type, config) => {
    const defaultConfig = {
      className: "ant-custom-notification",
    };

    notification[type]({ ...defaultConfig, ...config });
  };

  const onLinkClick = (_e) => {
    updateState({ selectedClientIds: checkedClients });
    navigate(`/new`);
  };

  const fetchTableData = () => {
    setIsLoading(true);
    const params = {
      page: currentPage,
      per_page: currentPerPage,
      q: filterParams,
    };
    axios("/front_office/yclients_customer_clients", {
      params: params,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "brackets" }),
      },
      headers: { Accept: "application/json" },
    })
      .then(({ data }) => {
        if (data.success) {
          updateState({ clients: data.clients, clients_count: data.clients_count });
        } else {
          updateState({ clients: [], clients_count: 0 });
        }
      })
      .catch((_error) => {
        updateState({ clients: [], clients_count: 0 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [currentPerPage]);

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    const checkedClientsPhoneNumbers = new Set(checkedClients || []);
    const showedClientsPhoneNumbers = new Set((dataToDisplay || []).map(({ id }) => id));

    if (showedClientsPhoneNumbers.size === 0 || checkedClientsPhoneNumbers.size === 0) {
      return;
    }

    if (showedClientsPhoneNumbers.difference(checkedClientsPhoneNumbers).size === 0) {
      setAllCheckboxMarked(true);
    } else {
      setAllCheckboxMarked(false);
    }
  }, [checkedClients, dataToDisplay]);

  useEffect(() => {
    setDisabledButton((checkedClients || []).length === 0);
  }, [checkedClients]);

  useEffect(() => {
    setDataToDisplay(state.clients);
  }, [state.clients]);

  useEffect(() => {
    fetchTableData();
    navigate(`?page=${currentPage}&per_page=${currentPerPage}`, { replace: true });
  }, [currentPage, currentPerPage, filterParams]);

  const calculateViewCount = (clientsCount, perPageCount) => {
    return clientsCount < perPageCount ? clientsCount : perPageCount;
  };

  const onClickMainCheckbox = (event) => {
    const checkedClientsIds = new Set(checkedClients || []);
    const showedClientsIds = new Set((dataToDisplay || []).map(({ id }) => id));

    if (event.target.checked) {
      if (checkedClientsIds.union(showedClientsIds).size >= MAX_SELECTED_CLIENTS) {
        showNotification("error", {
          description: t("checked_limit.error.description", { count: MAX_SELECTED_CLIENTS }),
          message: t("checked_limit.error.title"),
        });
        return;
      }
      setAllCheckboxMarked(true);
      setCheckedClients(Array.from(checkedClientsIds.union(showedClientsIds)));
    } else {
      const remainingClientsIds = Array.from(checkedClientsIds.difference(showedClientsIds));

      setAllCheckboxMarked(false);
      setCheckedClients(remainingClientsIds);
    }
  };

  const onSearch = (value) => {
    setFilterParams((params) => ({ ...params, name_or_phone_cont: value }));
  };

  const setSalons = (yclients_customer_id_in) => {
    setFilterParams((params) => {
      return { ...params, yclients_customer_id_in: yclients_customer_id_in };
    });
  };

  const addSalon = (value) => {
    setFilterParams((params) => {
      return { ...params, yclients_customer_id_in: [...params.yclients_customer_id_in, value] };
    });
  };

  const removeSalon = (value) => {
    setFilterParams((params) => {
      const idsWithoutRemoved = params.yclients_customer_id_in.filter((id) => id !== value);

      const newYclientsCustomerIdIn =
        idsWithoutRemoved.length === 0 ? [allSalonsOption.value] : idsWithoutRemoved;

      return {
        ...params,
        yclients_customer_id_in: newYclientsCustomerIdIn,
      };
    });
  };

  const onSalonSelect = (value) => {
    if (value === allSalonsOption.value) {
      setSalons([allSalonsOption.value]);
    } else {
      addSalon(value);
      removeSalon(allSalonsOption.value);
    }
  };

  const onSalonDeselect = (value) => {
    if (value === allSalonsOption.value) {
      setSalons([allSalonsOption.value]);
    } else {
      removeSalon(value);
    }
  };

  const onClickSoloCheckbox = (event, record) => {
    const { checked } = event.target;
    const { id } = record;

    if (checked) {
      if (checkedClients.length >= MAX_SELECTED_CLIENTS) {
        showNotification("error", {
          description: t("checked_limit.error.description", { count: MAX_SELECTED_CLIENTS }),
          message: t("checked_limit.error.title"),
        });
        return;
      }
      setCheckedClients([id, ...checkedClients]);
    } else {
      setCheckedClients(checkedClients.filter((clientId) => clientId !== id));
    }
  };

  const syncData = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: state.sync_data_url,
      headers: {
        Accept: "application/javascript",
        "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
      },
    }).finally(() => {
      setSyncInProgress(true);
    });
  };

  const columns = [
    {
      title: () => {
        return <Checkbox checked={allCheckboxMarked} onChange={(e) => onClickMainCheckbox(e)} />;
      },
      dataIndex: "main_checkbox",
      width: "10%",
      render: (_, record) => {
        return (
          <Checkbox
            onChange={(e) => onClickSoloCheckbox(e, record)}
            checked={checkedClients.includes(record.id)}
          />
        );
      },
    },
    {
      responsive: ["md"],
      title: t("table.fields.full_name"),
      dataIndex: "full_name",
      width: "45%",
    },
    {
      responsive: ["md"],
      title: t("table.fields.phone_number"),
      dataIndex: "phone_number",
      width: "45%",
    },
  ];

  const isShowSalonsSelect = (state.salons?.length ?? 0) > 1;
  const salonsOptions = useMemo(() => [allSalonsOption, ...state.salons], [state]);

  return (
    <div className="yclients_customer_clients">
      <Breadcrumb className="ant-custom-breadcrumbs">
        <Breadcrumb.Item>{t("breadcrumb.clients", { count: state.clients_count })}</Breadcrumb.Item>
      </Breadcrumb>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            {isShowSalonsSelect && (
              <Select
                value={filterParams.yclients_customer_id_in}
                onSelect={onSalonSelect}
                onDeselect={onSalonDeselect}
                className="ant-custom-select"
                popupClassName="ant-custom-select-dropdown"
                style={{ width: "100%" }}
                mode="multiple"
                optionFilterProp="label"
                options={salonsOptions}
              />
            )}
          </Col>
          <Col span={12} />
          <Col span={6}>
            <Input.Search
              className="ant-custom-search-input"
              placeholder={t("search_input")}
              onSearch={(value, _e, _s) => onSearch(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space direction="vertical" size={24} style={{ width: "100%" }}>
              <Row>
                <Col span={24}>
                  {syncInProgress ? (
                    <Text className="yclients_customer_clients__data-sync">
                      <ExclamationCircleFilled />
                      {t("sync.data_sync_success")}
                    </Text>
                  ) : (
                    <Text className="yclients_customer_clients__data-sync">
                      <ExclamationCircleFilled />
                      {t("sync.data_sync_start")}
                      <a href="#" onClick={syncData}>
                        {t("sync.data_sync_link_text")}
                      </a>
                      {t("sync.data_sync_end")}
                    </Text>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    loading={isLoading}
                    className="ant-custom-table"
                    scroll={{ x: true }}
                    rowKey="id"
                    columns={columns}
                    dataSource={dataToDisplay}
                    showHeader={screens.md}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div>
              {t("pagination.how_show", {
                total: state.clients_count,
                count: calculateViewCount(state.clients_count, currentPerPage),
              })}
            </div>
          </Col>
          <Col span={8}>
            <Pagination
              className="ant-custom-pagination"
              defaultCurrent={currentPage}
              total={state.clients_count}
              showSizeChanger={false}
              onChange={(page, _) => setCurrentPage(page)}
              pageSize={currentPerPage}
            />
          </Col>
          <Col span={8}>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Select
                className="ant-custom-select"
                defaultValue={t("select_perp_page", { value: currentPerPage })}
                style={{ width: "50%", maxWidth: 420 }}
                options={state.per_pages.map((value) => {
                  return { value: value, label: t("select_perp_page", { value: value }) };
                })}
                popupClassName="ant-custom-select-dropdown"
                onChange={(value) => {
                  setCurrentPerPage(value);
                }}
              />
            </div>
          </Col>
        </Row>
      </Space>
      {!disabledButton && (
        <div className="flow_bar">
          <div className="flow_bar-buttons">
            <Space size={8}>
              <Button type="text" variant="solid">
                {t("flow_bar.clients_count", { count: checkedClients.length })}
              </Button>
              <Button type="primary" disabled={disabledButton} onClick={onLinkClick}>
                {t("flow_bar.send_button")}
              </Button>
            </Space>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsTable;
