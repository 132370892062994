import React, { useState } from 'react';
import { Typography, Space, List, Radio, Button, Badge } from 'antd';
import { LeftOutlined, CheckOutlined, CloseOutlined, StarFilled } from '@ant-design/icons';

const { Text, Title } = Typography;

const NewView = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('3');

  const features = [
    { title: 'База контактов', included: true },
    { title: 'Чаты с клиентами в мессенджерах', included: true },
    { title: 'Приоритетный чат с поддержкой', included: true },
    { title: 'Реферальная ссылка', included: true },
    { title: 'Массовые рассылки (без ограничений)', included: true },
    { title: 'Отправка и рассылка SMS', included: false },
    { title: 'Ассистент-администратор', included: false },
  ];

  const periods = [
    { value: '1', months: '1 месяц', price: 29, oldPrice: null, discount: null },
    { value: '3', months: '3 месяца', price: 79, oldPrice: 87, discount: '10%' },
    { value: '6', months: '6 месяца', price: 79, oldPrice: 87, discount: '10%', featured: true },
    { value: '12', months: '12 месяца', price: 279, oldPrice: 348, discount: '20%' },
  ];

  return (
    <div style={{ padding: '16px', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      {/* Header */}
      <Space style={{ marginBottom: '24px' }}>
        <a href="/note_office/subscriptions">
          <LeftOutlined />
        </a>
        <Text strong>Сменить тариф</Text>
      </Space>

      {/* Tariff Name */}
      <Title level={2} style={{ color: '#e8a44d', marginBottom: '24px' }}>
        Standart
      </Title>

      {/* Features List */}
      <List
        style={{ marginBottom: '24px' }}
        itemLayout="horizontal"
        dataSource={features}
        renderItem={(item) => (
          <List.Item>
            <Space>
              {item.included ? (
                <CheckOutlined style={{ color: '#e8a44d' }} />
              ) : (
                <CloseOutlined style={{ color: '#999' }} />
              )}
              <Text>{item.title}</Text>
            </Space>
          </List.Item>
        )}
      />

      {/* Period Selection */}
      <Radio.Group
        value={selectedPeriod}
        onChange={(e) => setSelectedPeriod(e.target.value)}
        style={{ width: '100%' }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {periods.map((period) => (
            <Radio.Button
              key={period.value}
              value={period.value}
              style={{
                width: '100%',
                height: 'auto',
                padding: '12px',
                borderRadius: '8px',
                border: period.featured ? '2px solid #e8a44d' : '1px solid #d9d9d9',
              }}
            >
              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <Space direction="vertical">
                  <Text strong>{period.months}</Text>
                  {period.discount && (
                    <Text style={{ color: '#e8a44d' }}>Выгода {period.discount}</Text>
                  )}
                </Space>
                <Space direction="vertical" align="end">
                  <Text strong>${period.price}</Text>
                  {period.oldPrice && (
                    <Text delete style={{ color: '#999' }}>
                      ${period.oldPrice}
                    </Text>
                  )}
                </Space>
                {period.featured && (
                  <StarFilled
                    style={{
                      color: '#e8a44d',
                      position: 'absolute',
                      top: -10,
                      right: -10,
                    }}
                  />
                )}
              </Space>
            </Radio.Button>
          ))}
        </Space>
      </Radio.Group>

      {/* Payment Button */}
      <Button
        type="primary"
        block
        size="large"
        style={{
          marginTop: '24px',
          backgroundColor: '#e8a44d',
          borderColor: '#e8a44d',
        }}
      >
        Оплатить 79 $
      </Button>
    </div>
  );
};

export default NewView;
