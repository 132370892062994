import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default () => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
      >
        <path
          d="M4 2.1873V3.35918C4 3.43886 4.03906 3.51386 4.10313 3.56074L10.2219 7.9998L4.10313 12.4389C4.03906 12.4857 4 12.5607 4 12.6404V13.8123C4 13.9139 4.11563 13.9732 4.19844 13.9139L11.7937 8.40449C12.0687 8.20449 12.0687 7.79511 11.7937 7.59668L4.19844 2.0873C4.11563 2.02636 4 2.08574 4 2.1873Z"
          fill="black"
          fillOpacity="0.88"
        />
      </svg>
    </SVGUniqueID>
  );
};
