import React from "react";
import { Spin } from "antd";

const LoadingWrapper = ({ loading, children }) => {
  return loading ? (
    <Spin wrapperClassName="spin_box" className="spin_box">
      {children}
    </Spin>
  ) : (
    children
  );
};

export default LoadingWrapper;
