import React, { useState } from 'react';
import SelectorView from './SelectorView';
import axios from 'axios';
import { Modal, Button } from 'antd';

import translator from '@/translator';
const t = translator('front_office.components.main_selector.');

const MainSelector = ({
  routingList,
  currentRoutingSet,
  dispatchingRouteUrl,
  hasTelegramBot,
  hasTdLib,
  editTelegramBotsUrl,
  editTdLibSessionsUrl,
}) => {
  const activeChannels: [string, boolean][] = currentRoutingSet.map((channel) => [channel, true]);

  const channelsWithStatus: [string, boolean][] = routingList.reduce(
    (acc: [string, boolean][], channel: string) => {
      if (currentRoutingSet.includes(channel)) {
        return acc;
      }
      return [...acc, [channel, false]];
    },
    [...activeChannels],
  );

  const [orderedItems, setItems] = useState(channelsWithStatus);
  const [modalMessage, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const saveRoutingOrder = () => {
    const dispatchingRouteData = new FormData();
    const selectedChannels = orderedItems
      .filter(([, isActive]) => isActive)
      .map(([channel]) => channel)
      .join(',');

    dispatchingRouteData.append('dispatch[dispatch_routing]', selectedChannels);

    axios({
      method: 'PATCH',
      url: dispatchingRouteUrl,
      data: dispatchingRouteData,
      headers: {
        Accept: 'application/javascript',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    })
      .then(() => {
        setMessage(t('success_request'));
        showModal();
      })
      .catch(() => {
        setMessage(t('bad_request'));
        showModal();
      });
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        title={t('notify')}
        onCancel={handleCancel}
        footer={<Button onClick={handleCancel}>{t('ok')}</Button>}
      >
        <p>{modalMessage}</p>
      </Modal>
      <SelectorView
        channels={orderedItems}
        setChannelsOrder={setItems}
        title={t('selector_view_title')}
        setRouting={saveRoutingOrder}
        hasTelegramBot={hasTelegramBot}
        hasTdLib={hasTdLib}
        editTelegramBotsUrl={editTelegramBotsUrl}
        editTdLibSessionsUrl={editTdLibSessionsUrl}
      />
    </>
  );
};

export default MainSelector;
