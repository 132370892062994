import translator from '@/translator';
import React from 'react';
import { Dropdown, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const t = translator('note_office.views.contacts.dropdown_menu.');

const DropdownMenu = ({ contactsData }) => {
  const handleMenuClick = (e) => {
    if (e.key === 'import') {
      window.location.href = contactsData.import_url;
    }
    if (e.key === 'sort') {
      console.log('Sorting action');
    }
    if (e.key === 'filter') {
      console.log('Filtering action');
    }
  };

  const menuItems = [
    { key: 'sort', label: t('sort') },
    { key: 'filter', label: t('filter') },
    { key: 'import', label: t('import') },
  ];

  return (
    <Dropdown
      menu={{ items: menuItems, onClick: handleMenuClick }}
      trigger={['click']}
      overlayClassName="dropdown-menu__menu"
    >
      <Button type="text" icon={<EllipsisOutlined className="dropdown-menu__btn" />} />
    </Dropdown>
  );
};

export default DropdownMenu;
