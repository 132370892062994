import React from 'react';

import translator from '@/translator';
const t = translator('front_office.views.integrations.selector_info.');

interface Props {
  orderedChannels: [string, boolean][];
  setStep?: (arg1: string) => void;
  forwardStep?: string;
  backwardStep?: string;
}

export default ({ orderedChannels }: Props) => {
  const channelNames = {
    whatsapp: t('whatsapp'),
    telegram: t('telegram_bot'),
    sms: t('sms'),
    notify: t('vk_ok'),
    tdlib: t('telegram'),
  };
  const currentRoute = orderedChannels
    .filter(([, isActive]) => isActive)
    .map(([channel]) => channelNames[channel])
    .join(' → ');

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        maxWidth: '398px',
        height: '376px',
        padding: '20px',
        borderRadius: '24px',
        background: '#FAFAFA',
      }}
    >
      <div>
        <h3>{t('setup_delivery_order')}</h3>
        <p>{t('setup_help')}</p>
        <p>
          {t('message_path')} {currentRoute}
        </p>
      </div>
    </div>
  );
};
