import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import classNames from 'classnames';
import { useIntegrationState } from './State';
import Helper from '@/helper';

export function Confirm() {
  const navigate = useNavigate();
  const { state, updateState } = useIntegrationState();
  const [countdown, setCountdown] = useState(0);

  const formMethods = useForm();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  useEffect(() => {
    setCountdown(
      Math.max(
        0,
        state.code_resend_countdown_secs -
          Math.floor((Date.now() - state.last_code_sent_at * 1000) / 1000),
      ),
    );
  }, [state.last_code_sent_at]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    onSend(false, null);
  }, []);

  const onSend = (isResend, e) => {
    e?.preventDefault();

    const formData = isResend ? { resend_code: 1 } : { send_code: 1 };
    const url = new URL(window.location.href);
    url.pathname += '/send';

    axios({
      method: 'POST',
      url: url,
      data: { integration_choice: formData },
      headers: {
        Accept: 'application/javascript',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
    })
      .then(({ data }) => {
        if (data.success) {
          if (!!data.redirect_to) return (window.location.href = data.redirect_to);

          updateState({ last_code_sent_at: data.last_code_sent_at });
        } else {
          Object.entries(data.errors).map(([fieldName, errorMessages]) => {
            setError(fieldName, { type: 'manual', message: errorMessages.join(', ') });
          });
        }
      })
      .catch((_error) => {
        alert('Что-то пошло не так. Попробуйте ещё раз или обратитесь к администратору.');
      });
  };

  const onSubmit = (formData, e) => {
    e.preventDefault();

    return axios({
      method: 'POST',
      url: window.location.href,
      data: { integration_choice: formData },
      headers: {
        Accept: 'application/javascript',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
    })
      .then(({ data }) => {
        if (data.success) {
          window.location.href = data.redirect_to;
        } else {
          Object.entries(data.errors).map(([fieldName, errorMessages]) => {
            setError(fieldName, { type: 'manual', message: errorMessages.join(', ') });
          });
        }
      })
      .catch((_error) => {
        alert('Что-то пошло не так. Попробуйте ещё раз или обратитесь к администратору.');
      });
  };

  const codeError = errors.confirmation_code?.message;

  return (
    <div className="integration-choice-v2-main">
      <div className="integration-choice-v2-header">
        <h2>Подтвердите номер</h2>
      </div>
      <div className="integration-choice-v2-body">
        <div className="integration-choice-v2-body__text">
          Мы отправили код в сообщении на номер <strong>{state.formatted_phone_to_confirm}</strong>.
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="integration-choice-v2-body__inputs">
              <div
                className={classNames('integration-choice-v2-body__inputs-item', {
                  'integration-choice-v2-body__inputs-item--invalid': codeError,
                })}
              >
                <label htmlFor="confirmation_code">Код из сообщения</label>
                <input
                  id="confirmation_code"
                  autoComplete="off"
                  type="text"
                  placeholder="1234"
                  {...register('confirmation_code')}
                />
                {codeError && (
                  <div className="integration-choice-v2-body__inputs-error">{codeError}</div>
                )}
              </div>
            </div>
            <div className="integration-choice-v2-body__actions">
              <button
                className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--primary"
                disabled={isSubmitting}
                type="submit"
              >
                Далее
              </button>
              <button
                disabled={isSubmitting || countdown > 0}
                className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--secondary"
                onClick={(e) => onSend(true, e)}
                type="button"
              >
                {countdown > 0
                  ? `Выслать другой код ${Helper.formatInterval(countdown)}`
                  : 'Выслать другой код'}
              </button>
              <button
                type="button"
                disabled={isSubmitting}
                className="integration-choice-v2-body__actions-btn integration-choice-v2-body__actions-btn--text"
                onClick={() => navigate('/edit')}
              >
                Указать другой телефон
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
