import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => {
  return (
    <SVGUniqueID>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2381 7.18057C18.5526 7.45014 18.589 7.92361 18.3194 8.23811L12.7378 14.75L18.3194 21.2619C18.589 21.5764 18.5526 22.0499 18.2381 22.3195C17.9236 22.589 17.4501 22.5526 17.1806 22.2381L11.1806 15.2381C10.9398 14.9572 10.9398 14.5428 11.1806 14.2619L17.1806 7.26192C17.4501 6.94743 17.9236 6.911 18.2381 7.18057Z"
          fill="#1C274C"
        />
      </svg>
    </SVGUniqueID>
  );
};
