import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 13.6C12.75 11.2528 14.6528 9.34998 17 9.34998C19.3472 9.34998 21.25 11.2528 21.25 13.6C21.25 15.9472 19.3472 17.85 17 17.85C14.6528 17.85 12.75 15.9472 12.75 13.6ZM17 11.05C15.5917 11.05 14.45 12.1917 14.45 13.6C14.45 15.0083 15.5917 16.15 17 16.15C18.4083 16.15 19.55 15.0083 19.55 13.6C19.55 12.1917 18.4083 11.05 17 11.05Z"
          fill="#808080"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.81665 17C4.81665 10.2713 10.2713 4.81665 17 4.81665C23.7287 4.81665 29.1833 10.2713 29.1833 17C29.1833 23.7287 23.7287 29.1833 17 29.1833C10.2713 29.1833 4.81665 23.7287 4.81665 17ZM17 6.51665C11.2102 6.51665 6.51665 11.2102 6.51665 17C6.51665 19.885 7.68202 22.4978 9.5677 24.3933C9.77215 23.2904 10.1738 22.2506 10.9801 21.4144C12.1862 20.1637 14.1147 19.55 16.9999 19.55C19.8852 19.55 21.8137 20.1637 23.0197 21.4144C23.826 22.2506 24.2277 23.2905 24.4322 24.3934C26.3179 22.4979 27.4833 19.885 27.4833 17C27.4833 11.2102 22.7898 6.51665 17 6.51665ZM22.8862 25.6761C22.7707 24.3026 22.4724 23.296 21.796 22.5944C21.0599 21.8311 19.6961 21.25 16.9999 21.25C14.3038 21.25 12.9399 21.8311 12.2039 22.5944C11.5274 23.296 11.2292 24.3025 11.1137 25.676C12.7917 26.8167 14.818 27.4833 17 27.4833C19.1819 27.4833 21.2081 26.8167 22.8862 25.6761Z"
          fill="#808080"
        />
      </svg>
    </SVGUniqueID>
  );
};
