import React, { useEffect } from 'react';
import { Col, Row, Radio, Typography } from 'antd';
import WarningAlert from './WarningAlert';
import { useNotificationsState } from './State';
import { useFormContext, Controller } from 'react-hook-form';
import translator from '@/translator';
const t = translator('front_office.views.altegio_notification_settings.');
const { Text } = Typography;

const createSaleServicesStep = () => ({
  title: t('steps.cross_sell'),
  validation: ({ salon_sale_service_id_and_title }) =>
    (salon_sale_service_id_and_title || []).length > 0,
  Component: ({ isVisible }) => {
    const { state } = useNotificationsState();
    const { control, setValue } = useFormContext();
    const services = state.services[state.selectedSalon];

    useEffect(() => {
      setValue('salon_sale_service_id_and_title', null);
    }, [services]);

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
        <WarningAlert message={t('alerts.sale_services')} />
        <Col sm={24} xl={11}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text strong>{t('fields.sale_services')}</Text>
            </Col>
            <Col span={24}>
              <Text>{t('fields.sale_services_help')}</Text>
            </Col>
          </Row>
        </Col>
        <Col sm={0} xl={2} />
        <Col sm={24} xl={11}>
          <Controller
            control={control}
            name="salon_sale_service_id_and_title"
            defaultValue={null}
            render={({ field: { onChange, value } }) => (
              <Radio.Group value={value} onChange={onChange}>
                <Row gutter={[0, 2]}>
                  {services.map(({ value, label }) => (
                    <Col span={24} key={value}>
                      <Radio value={value}>{label}</Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            )}
          />
        </Col>
      </Row>
    );
  },
});

export default createSaleServicesStep;
