import React from 'react';
import { Button } from 'antd';

const RedirectButton = ({ url, children, className, type = 'default', block = true, ...props }) => {
  const handleRedirect = () => {
    window.location.href = url;
  };

  return (
    <Button type={type} block={block} className={className} onClick={handleRedirect} {...props}>
      {children}
    </Button>
  );
};

export default RedirectButton;
