import React, { useState } from "react";
import BackButton from "@/note_office/shared/BackButton";
import translator from "@/translator";
import { Form, Input, Button, Typography, message, Space } from "antd";

const t = translator("note_office.views.subscriptions.recharge_view.");
const RechargeView = ({ subscriptionsData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      <BackButton link={subscriptionsData.subscriptions_url} title={t("title")} />

      <Typography.Text className="subscriptions__recharge-label">
        {t("amount_label")}
      </Typography.Text>
      <Typography.Paragraph className="subscriptions__recharge-description" type="secondary">
        {t("amount_description")}
      </Typography.Paragraph>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="subscriptions__recharge-form"
      >
        <Form.Item name="amount">
          <Input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={4}
            placeholder="10$"
            className="contacts-form__input"
            onInput={(e) => (e.target.value = e.target.value.replace(/\D/g, ""))}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" block loading={loading} className="button-base">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RechargeView;
