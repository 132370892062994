import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const ContactAvatar = () => (
  <div className="contacts-avatar">
    <Avatar size={80} icon={<UserOutlined />} className="contacts-avatar__icon" />
  </div>
);

export default ContactAvatar;
