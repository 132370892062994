import translator from "@/translator";
import React from "react";
import Bee from "@/note_office/icons/Bee.png";
import { Button } from "antd";
const t = translator("note_office.views.contacts.import_success_view.");

const SuccessView = ({ link, text, buttonText }) => {
  return (
    <div className="success-view__container overflow">
      <div className="success-view__img">
        <img src={Bee} alt="Bee" />
      </div>
      <h2 className="success-view__title">{text}</h2>
      {link && (
        <Button type="primary" block className="button-base" href={link}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default SuccessView;
