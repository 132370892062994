import translator from "@/translator";
import React, { useEffect, useState } from "react";
import { Checkbox, Button, Spin, List } from "antd";
import BackButton from "@/note_office/shared/BackButton";
import Info from "@/note_office/icons/Info";
import { useClientContactsState } from "../State";
import { useNavigate } from "react-router-dom";
import CustomPagination from "@/note_office/shared/CustomPagination";
import EmptyView from "@/note_office/shared/EmptyView";
import axios from "axios";

const t = translator("note_office.views.bulk_deliveries.contacts_view.");

const ContactsView = () => {
  const MAX_SELECTED_CONTACTS = 5000;
  const { state, updateState } = useClientContactsState();
  const {
    contacts_url,
    bulk_deliveries_url,
    contacts: initialContacts,
    total_contacts,
    total_pages,
    new_contact_url,
    import_url,
  } = state.bulkDeliveriesData || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [contacts, setContacts] = useState(initialContacts);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(8);
  const [totalContacts, setTotalContacts] = useState(total_contacts);

  const selectedContacts = new Set(state.selectedContacts || []);

  useEffect(() => {
    loadContacts();
  }, [currentPage]);

  const loadContacts = async () => {
    setLoading(true);

    const url = `${contacts_url}?page=${currentPage}&per_page=${perPage}`;

    try {
      const { data } = await axios.get(url);

      setContacts(data.contacts || []);
      setPerPage(data.per_page);
      setTotalContacts(data.total_contacts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const isContactSelected = (id) => selectedContacts.has(id);

  const toggleContact = (id) => {
    const updatedSelection = new Set(selectedContacts);
    updatedSelection.has(id) ? updatedSelection.delete(id) : updatedSelection.add(id);
    updateState({ selectedContacts: Array.from(updatedSelection) });
  };

  const toggleSelectAllOnPage = () => {
    const updatedSelection = new Set(selectedContacts);

    if (contacts.every((c) => updatedSelection.has(c.id))) {
      contacts.forEach((c) => updatedSelection.delete(c.id));
    } else {
      contacts.forEach((c) => updatedSelection.add(c.id));
    }

    updateState({ selectedContacts: Array.from(updatedSelection) });
  };

  const toggleSelectAll = async () => {
    const url = `${contacts_url}?per_page=${MAX_SELECTED_CONTACTS}`;

    if (selectedContacts.size === totalContacts) {
      updateState({ selectedContacts: [] });
    } else {
      try {
        setLoading(true);
        let allContacts = state.allContactsCache;

        if (!allContacts) {
          const response = await fetch(url);
          const data = await response.json();

          allContacts = data.contacts.map((c) => c.id);

          updateState({ allContactsCache: allContacts });
        }

        updateState({ selectedContacts: allContacts });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleConfirmSelection = () => {
    navigate("/create");
  };

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      <BackButton link={bulk_deliveries_url} text={t("title")} extra={<Info />} />
      {!total_contacts ? (
        <EmptyView new_contact_url={new_contact_url} import_url={import_url} />
      ) : (
        <>
          <div className="bulk_deliveries__actions">
            <a
              className="bulk_deliveries__actions_link"
              style={{
                color: contacts.every((c) => isContactSelected(c.id)) ? "#ff3b30" : "#7aa873",
              }}
              onClick={toggleSelectAllOnPage}
            >
              {contacts.every((c) => isContactSelected(c.id))
                ? t("deselect_all_on_page")
                : t("select_all_on_page")}
            </a>

            <a
              className="bulk_deliveries__actions_link"
              style={{ color: selectedContacts.size === totalContacts ? "#ff3b30" : "#ffc917" }}
              onClick={toggleSelectAll}
            >
              {selectedContacts.size === totalContacts
                ? t("deselect_all_contacts")
                : t("select_all_contacts")}
            </a>
          </div>

          <div className="bulk_deliveries__content overflow">
            <div className="contacts-index__list">
              <List
                itemLayout="horizontal"
                dataSource={contacts}
                renderItem={(contact) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Checkbox
                          checked={isContactSelected(contact.id)}
                          onChange={() => toggleContact(contact.id)}
                        />
                      }
                      title={<div className="contacts-index__name">{contact.name}</div>}
                      description={<div className="contacts-index__phone">{contact.phone}</div>}
                    />
                  </List.Item>
                )}
              />
            </div>

            {loading && <Spin size="large" className="loader" />}

            {total_pages > 1 && (
              <CustomPagination
                current={currentPage}
                total={totalContacts}
                pageSize={perPage}
                onChange={handlePageChange}
              />
            )}
          </div>

          <Button
            type="primary"
            block
            onClick={handleConfirmSelection}
            disabled={selectedContacts.size === 0}
            className="button-base"
          >
            {t("confirm_selection", { count: selectedContacts.size })}
          </Button>
        </>
      )}
    </div>
  );
};

export default ContactsView;
