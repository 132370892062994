import translator from "@/translator";
import React from "react";
import { Form, Space } from "antd";
import RedirectButton from "@/note_office/shared/RedirectButton";
import Blob from "@/note_office/icons/Blob.png";

const t = translator("note_office.views.contacts.import_failure_view.");

const ImportFailureView = ({ contactsData }) => {
  return (
    <div className="contacts-import__failure overflow">
      <div className="contacts-import__failure-view">
        <div className="empty-contacts__illustration-min">
          <img src={Blob} alt="Blob" />
        </div>

        <h2 className="contacts-import__failure-title">{t("contacts.title")}</h2>
        <div className="contacts-import__failure-area">
          <p className="contacts-import__failure-description">{t("contacts.description_one")}</p>
          <p className="contacts-import__failure-description">{t("contacts.description_two")}</p>
          <p className="contacts-import__failure-description">{t("contacts.description_three")}</p>
        </div>
      </div>
      <Form.Item style={{ marginBottom: 20 }}>
        <Space direction="vertical" size={16} className="button-group">
          <RedirectButton
            type="primary"
            block
            className="button-base"
            url={contactsData.import_contacts_url}
          >
            {t("contacts.try_again")}
          </RedirectButton>

          <RedirectButton
            type="default"
            block
            className="button-base button-secondary"
            url={contactsData.contacts_url}
          >
            {t("contacts.import_later")}
          </RedirectButton>
        </Space>
      </Form.Item>
    </div>
  );
};

export default ImportFailureView;
