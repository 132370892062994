import React, { useRef, useState, useEffect, useCallback } from "react";
import axios from "axios";
import Helper from "@/helper.js";
import EmojiPicker from "@front_office/components/EmojiPicker";
import { SmileOutlined, QuestionCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Row, Col, Typography, Dropdown, Popover, Button, Checkbox, Tooltip } from "antd";
import WarningAlert from "./WarningAlert";
import AttachmentUpload from "./AttachmentUpload";
import { useFormContext, Controller } from "react-hook-form";
import { useClientsState } from "../State";
import translator from "@/translator";
import classNames from "classnames";
import TextPreview from "./TextPreview";
import SmsProgressBar from "./SmsProgressBar";

const t = translator("front_office.views.yclients_customer_clients.form.create_text_step.");
const { Text } = Typography;
const { TextArea } = Input;

const createTextStep = ({ alerts = [] } = {}) => ({
  title: t("title"),
  validation: ({ text, attachment_id }, { text_step_errors }) => {
    let errors = (text_step_errors || []).length === 0;
    const validations = () => {
      if (attachment_id) {
        return true;
      }

      if (text && (text || "").length > 0) {
        return true;
      }
    };
    return errors && validations();
  },
  Component: ({ isVisible }) => {
    const { state, updateState } = useClientsState();
    const { control, setValue, watch, getValues } = useFormContext();
    const currentText = watch("text");
    const dispatchRouting = watch("dispatch_routing") || [];
    const [lastInsertPosition, setLastInsertPosition] = useState(-1);
    const [needUrlShort, setNeedUrlShort] = useState(state.url_shorting);
    const [preview, setPreview] = useState("");
    const [runningPreviewRequestsCount, setRunningPreviewRequestsCount] = useState(0);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [abortController, setAbortController] = useState(new AbortController());
    const textInputRef = useRef(null);
    const nativeTextArea = textInputRef.current?.resizableTextArea.textArea;

    const insertText = (text) => {
      const inputValue = nativeTextArea.value;
      const start = nativeTextArea.selectionStart;
      const end = nativeTextArea.selectionEnd === start ? start : nativeTextArea.selectionEnd;

      const before = inputValue.substring(0, start);
      const after = inputValue.substring(end);

      const newInputValueBeginning = before + text;
      const newInputValue = newInputValueBeginning + after;

      setValue("text", newInputValue);

      setLastInsertPosition(newInputValueBeginning.length);
    };

    useEffect(() => {
      if (lastInsertPosition >= 0) {
        nativeTextArea.setSelectionRange(lastInsertPosition, lastInsertPosition);
        setLastInsertPosition(-1);
      }
    }, [lastInsertPosition]);

    const fetchPreview = useCallback(
      Helper.debounce((formData, abortController) => {
        abortController.abort();
        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        setRunningPreviewRequestsCount((x) => x + 1);
        axios({
          signal: newAbortController.signal,
          method: "POST",
          data: { yclients_bulk_delivery: formData },
          url: state.preview_url,
          headers: {
            Accept: "application/javascript",
            "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
          },
        })
          .then(({ data }) => {
            if (data.success) {
              setPreview(data.preview.text);
              updateState({ text_step_errors: data.preview.text_errors });
            } else {
              setPreview(formData.text);
              updateState({ text_step_errors: data.preview.text_errors });
            }
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              setPreview(formData.text);
            }
          })
          .finally(() => {
            setRunningPreviewRequestsCount((x) => x - 1);
          });
      }, 500),
      [],
    );

    useEffect(() => {
      setIsPreviewLoading(runningPreviewRequestsCount !== 0);
    }, [runningPreviewRequestsCount]);

    useEffect(() => {
      if (isVisible) {
        const { text, dispatch_routing } = getValues();

        const filteredFormData = {
          body: text,
          dispatch_routing,
        };

        fetchPreview(filteredFormData, abortController);
      } else {
        setPreview("");
        updateState({ text_step_errors: [] });
      }
    }, [isVisible, currentText]);

    const bodyVariableOptions = {
      items: state.body_variables,
      onClick: ({ key }) => insertText(key),
    };

    const isErrorPresent = (state.text_step_errors || []).length !== 0;

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? "flex" : "none" }}>
        {alerts.map((alert, index) => (
          <WarningAlert key={index} message={alert} />
        ))}
        <Col span={1}></Col>
        <Col span={11}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text strong>{t("fields.text")}</Text>
            </Col>
            <Col span={24}>
              <Text>
                <span dangerouslySetInnerHTML={{ __html: t("fields.text_help") }} />
              </Text>
            </Col>
            <Col span={24}>
              <div
                className={classNames("yclients_customer_clients-create__text-input", {
                  "yclients_customer_clients-create__text-input_error": isErrorPresent,
                })}
              >
                <Controller
                  control={control}
                  name="text"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      autoSize
                      bordered={false}
                      ref={textInputRef}
                      value={value}
                      onChange={onChange}
                      style={{ padding: 0, flexGrow: 1 }}
                    />
                  )}
                />
                <div className="yclients_customer_clients-create__text-input-actions">
                  <Dropdown
                    trigger="click"
                    menu={bodyVariableOptions}
                    overlayClassName="ant-custom-dropdown"
                  >
                    <Button className="ant-custom-btn">
                      {t("fields.text_variables")}
                      <PlusOutlined />
                    </Button>
                  </Dropdown>
                  <Popover
                    placement="right"
                    popupClassName={"yclients_customer_clients-create"}
                    trigger="click"
                    content={
                      <EmojiPicker
                        stylesStr="section.picker{border: none;}"
                        onClick={(e) => insertText(e.detail.unicode)}
                      />
                    }
                  >
                    <Button className="ant-custom-btn" htmlType="button">
                      <SmileOutlined />
                    </Button>
                  </Popover>
                </div>
              </div>
              {isErrorPresent && (
                <div className="yclients_customer_clients-create__text-input-error-message">
                  {state.text_step_errors.join(", ")}
                </div>
              )}
            </Col>
            {dispatchRouting.includes("sms") && (
              <Col span={24}>
                <SmsProgressBar
                  isSmsSelected={dispatchRouting.includes("sms")}
                  currentText={currentText}
                  shortUrl={needUrlShort}
                />
              </Col>
            )}
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  defaultValue={needUrlShort}
                  checked={needUrlShort}
                  onChange={(e) => {
                    setNeedUrlShort(e.target.checked), setValue("url_shorting", e.target.checked);
                  }}
                >
                  {t("fields.url_short_checkbox")}
                </Checkbox>
                <Tooltip title={t("fields.url_short_help", { example_url: "https://test.com" })}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            </Col>
            <Col span={24}>
              <AttachmentUpload />
            </Col>
          </Row>
        </Col>
        <Col sm={0} xl={1} />
        <Col sm={24} xl={11}>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
            <Text strong>{t("fields.reciver_preview")}</Text>
          </div>

          <TextPreview
            sender_name={"Салон"}
            text={preview}
            isLoading={isPreviewLoading}
            attachmentUrl={state.currentFileUrl}
            shortUrl={needUrlShort}
            skeletonHeight={Math.floor((currentText || "").split(/\r\n|\r|\n/).length / 2)}
          />
        </Col>
      </Row>
    );
  },
});

export default createTextStep;
