import React, { useRef, useState } from 'react';
import { Button, Form, Input, Space, Tabs, notification } from 'antd';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import Captcha from '@front_office/components/Captcha';

import translator from '@/translator';
const t = translator('front_office.views.registration.password.restore.');

const Restore = ({
  setSection,
  setCustomerData,
  setConfirmationType,
  onlyRussianNumbersAllowed,
  setConfirmRouting,
  defaultCountry,
}) => {
  const captchaRef = useRef(null);
  const skipCaptcha = new URLSearchParams(window.location.search).get('skip_captcha');
  const [disableSubmit, setDisableSubmit] = useState(skipCaptcha != 'true');

  const NotificationDescription = ({ text }) => <span dangerouslySetInnerHTML={{ __html: text }} />;
  const onChangeCaptcha = (value) => {
    setDisableSubmit(false);
  };

  const onFinish = (values) => {
    const token = captchaRef.current.getValue();

    let params = Object.assign(values, { 'smart-token': token });
    if (skipCaptcha) params = Object.assign(params, { skip_captcha: skipCaptcha });

    axios({
      method: 'post',
      url: '/customers/password',
      data: params,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        const customer = Object.assign(data.customer, { login: values.login });
        setCustomerData(customer);
        setConfirmRouting(values.login.includes('@') ? 'email' : 'phone');
        setConfirmationType('reset_password');
        setSection('sms');
      } else {
        captchaRef.current.reset();

        data.errors.forEach((error) => {
          return notification['error']({
            description: <NotificationDescription text={error} />,
            placement: 'top',
          });
        });
      }
    });
  };

  return (
    <>
      <Tabs defaultActiveKey="email">
        <Tabs.TabPane tab={t('email_tab')} key="email">
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item name="login" label={t('login_label')}>
              <Input name="customer[login]" size="large" />
            </Form.Item>
            <Form.Item>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Captcha ref={captchaRef} onChange={onChangeCaptcha} />

                <Button block={true} type="primary" htmlType="submit" disabled={disableSubmit}>
                  {t('restore_password_button')}
                </Button>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setSection('sign_in');
                  }}
                  className="ant-btn ant-btn-default ant-btn-block"
                >
                  {t('sign_in_link')}
                </a>
              </Space>
            </Form.Item>
          </Form>
        </Tabs.TabPane>

        <Tabs.TabPane tab={t('phone_tab')} key="phone">
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item name="login" label={t('login_label')}>
              <PhoneInput
                country={defaultCountry}
                preferredCountries={
                  onlyRussianNumbersAllowed ? [defaultCountry] : [defaultCountry, 'kz', 'ua', 'by']
                }
                onlyCountries={onlyRussianNumbersAllowed ? [defaultCountry] : []}
                inputClass="ant-input ant-input-lg"
                containerClass="react-tel-input-custom-antd"
              />
            </Form.Item>
            <Form.Item>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Captcha ref={captchaRef} onChange={onChangeCaptcha} />

                <Button block={true} type="primary" htmlType="submit" disabled={disableSubmit}>
                  {t('restore_password_button')}
                </Button>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setSection('sign_in');
                  }}
                  className="ant-btn ant-btn-default ant-btn-block"
                >
                  {t('sign_in_link')}
                </a>
              </Space>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default Restore;
