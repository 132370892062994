import translator from "@/translator";
import React, { useState } from "react";
import { Form, message, Space } from "antd";
import axios from "axios";
import BackButton from "@/note_office/shared/BackButton";
import ContactAvatar from "@/note_office/shared/ContactAvatar";
import ContactForm from "./components/ContactForm";
import Helper from "@/helper";

const t = translator("note_office.views.contacts.new_view.");

const NewView = ({ contactsData }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const csrfToken = Helper.getCSRFToken();

  const onFinish = async (values) => {
    setLoading(true);
    form.setFields([]);

    try {
      await axios.post(
        contactsData.create_contact_url,
        { customer_contact: values },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
          },
        },
      );
      message.success(t("success_message"));
      form.resetFields();
      window.location.href = contactsData.contacts_url;
    } catch (error) {
      if (error.response?.data?.errors) {
        form.setFields(
          Object.entries(error.response.data.errors).map(([field, messages]) => ({
            name: field,
            errors: messages,
          })),
        );
      } else {
        message.error(t("error_message"));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper overflow">
      <BackButton link={contactsData.contacts_url} />

      <ContactAvatar />
      <ContactForm
        form={form}
        initialValues={{
          name: "",
          phone: "",
          telegram: "",
          email: "",
          instagram: "",
          country: "",
        }}
        loading={loading}
        onFinish={onFinish}
      />
    </div>
  );
};

export default NewView;
