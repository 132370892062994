import React from 'react';
import { Row } from 'antd';
import WarningAlert from './WarningAlert';
import TextWithPreview from './TextWithPreview';
import translator from '@/translator';
const t = translator('front_office.views.yclients_notification_settings.');

const createTextStep = ({ alerts = [], helpText = t('fields.text_help') } = {}) => ({
  title: t('steps.text'),
  validation: ({ body }, { body_errors }) =>
    (body || '').length > 0 && (body_errors || []).length === 0,
  Component: ({ isVisible }) => {
    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
        {alerts.map((alert, index) => (
          <WarningAlert key={index} message={alert} />
        ))}
        <TextWithPreview
          fieldName="body"
          isWithAttachment
          isVisible={isVisible}
          helpText={helpText}
        />
      </Row>
    );
  },
});

export default createTextStep;
