import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default () => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2807 8.80263C19.2354 8.79977 19.186 8.79979 19.1348 8.79981L19.1221 8.79981H17.1153C15.4612 8.79981 14.0458 10.1019 14.0458 11.7998C14.0458 13.4978 15.4612 14.7998 17.1153 14.7998H19.1221L19.1348 14.7998C19.186 14.7998 19.2354 14.7999 19.2807 14.797C19.9526 14.7546 20.5468 14.2288 20.5968 13.4944C20.6001 13.4462 20.6 13.3943 20.6 13.3462L20.6 13.3331V10.2665L20.6 10.2534C20.6 10.2053 20.6001 10.1534 20.5968 10.1052C20.5468 9.37085 19.9526 8.84506 19.2807 8.80263ZM16.9373 12.5998C17.3632 12.5998 17.7084 12.2416 17.7084 11.7998C17.7084 11.358 17.3632 10.9998 16.9373 10.9998C16.5115 10.9998 16.1663 11.358 16.1663 11.7998C16.1663 12.2416 16.5115 12.5998 16.9373 12.5998Z"
          fill="#FFC917"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1343 15.9998C19.2536 15.9967 19.3439 16.1072 19.3115 16.222C19.1509 16.792 18.896 17.2778 18.487 17.6868C17.8883 18.2855 17.1292 18.5512 16.1912 18.6773C15.2798 18.7998 14.1153 18.7998 12.6451 18.7998H10.9549C9.48466 18.7998 8.32015 18.7998 7.40878 18.6773C6.47084 18.5512 5.71169 18.2855 5.113 17.6868C4.5143 17.0881 4.24861 16.329 4.12251 15.391C3.99998 14.4797 3.99999 13.3151 4 11.8449V11.7547C3.99999 10.2845 3.99998 9.11996 4.12251 8.20859C4.24861 7.27065 4.5143 6.51149 5.11299 5.9128C5.71169 5.31411 6.47084 5.04842 7.40878 4.92231C8.32015 4.79978 9.48466 4.79979 10.9549 4.79981L12.6451 4.7998C14.1153 4.79979 15.2798 4.79978 16.1912 4.92231C17.1292 5.04842 17.8883 5.31411 18.487 5.9128C18.896 6.32182 19.1509 6.8076 19.3115 7.37755C19.3439 7.49241 19.2536 7.6029 19.1343 7.59981L17.1152 7.59981C14.8459 7.59981 12.8457 9.39253 12.8457 11.7998C12.8457 14.2071 14.8459 15.9998 17.1152 15.9998L19.1343 15.9998ZM8 14.7998C7.66863 14.7998 7.4 14.5312 7.4 14.1998V9.3998C7.4 9.06843 7.66863 8.7998 8 8.7998C8.33137 8.7998 8.6 9.06843 8.6 9.3998V14.1998C8.6 14.5312 8.33137 14.7998 8 14.7998Z"
          fill="#FFC917"
        />
      </svg>
    </SVGUniqueID>
  );
};
