import React, { FC } from 'react';
import Button from '@components/Button';

import translator from '@/translator';
const t = translator('front_office.views.integrations.footer_buttons.');

interface Props {
  forwardAction: () => void;
  backwardAction: () => void;
  className?: string;
}

export const FooterButtons: FC<Props> = ({ forwardAction, backwardAction, className }) => (
  <div className={`telegram-flow_footer ${className || ''}`}>
    <Button
      htmlType="button"
      className="btn_v2-secondary"
      text={t('back')}
      onClick={backwardAction}
    />
    <Button htmlType="submit" className="btn_v2-primary" text={t('next')} onClick={forwardAction} />
  </div>
);
