import React, { useState, useEffect } from "react";
import { DatePicker, TimePicker, Button, ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import enUS from "antd/es/locale/en_US";
import ptPT from "antd/es/locale/pt_PT";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import "dayjs/locale/pt";
import BackButton from "@/note_office/shared/BackButton";
import { useNavigate } from "react-router-dom";
import { useClientContactsState } from "../State";
import translator from "@/translator";

dayjs.extend(weekday);
dayjs.extend(localeData);

const localeMapping = {
  ru: "ru",
  en: "en",
  "pt-BR": "pt",
};

const antdLocales = {
  ru: ruRU,
  en: enUS,
  pt: ptPT,
};

const t = translator("note_office.views.bulk_deliveries.schedule_view.");

const ScheduleView = () => {
  const navigate = useNavigate();
  const { state } = useClientContactsState();

  const customerLocale = localeMapping[state.bulkDeliveriesData.customer?.locale] || "en";

  const [date, setDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs());

  useEffect(() => {
    dayjs.locale(customerLocale);
  }, [customerLocale]);

  const handleSchedule = () => {
    navigate("/schedule_delivery");
  };

  const handleBackToDelivery = () => {
    navigate("/create");
  };

  return (
    <ConfigProvider locale={antdLocales[customerLocale]}>
      <div className="wrapper wrapper__menu-layout overflow">
        <BackButton onClick={handleBackToDelivery} text={t("title")} />

        <div className="schedule__container">
          <div className="schedule__content">
            <div className="schedule__time">
              <TimePicker
                value={startTime}
                onChange={(value) => setStartTime(value)}
                format="HH:mm"
                minuteStep={5}
                popupClassName="schedule__timepicker"
              />
            </div>

            <div className="schedule__date">
              <DatePicker
                popupClassName="calendar"
                value={date}
                onChange={(value) => setDate(value)}
                format="DD.MM.YYYY"
              />
            </div>
          </div>

          <Button type="primary" block className="button-base" onClick={handleSchedule}>
            {t("schedule")}
          </Button>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default ScheduleView;
