import React, { useState } from 'react';
import TelegramBot from './telegram_bot/TelegramBot';
import { TelegramTdLib } from './telegram_tdlib/TelegramTdLib';
import VariantsView from './VariantsView';
import Helper from '@/helper';

export default ({
  hasTelegramBot,
  hasTdLib,
  telegramBotName,
  telegramBotsUrl,
  dispatchingRouteUrl,
  authorizedNumber,
  customerBotMenuTitle,
  customerBotMenuLink,
  currentRoutingSet,
  routingList,
  editTelegramBotsUrl,
  editTdLibSessionsUrl,
  helpPhone,
  helpWhatsapp,
  helpEmail,
  foreignCustomer,
}) => {
  const initialVariant = Helper.getHashFromLocation('variantsView');
  Helper.removeHashFromLocation();
  const [variant, setVariant] = useState(initialVariant);
  const component = {
    telegramBot: (
      <TelegramBot
        setVariant={setVariant}
        currentRoutingSet={currentRoutingSet}
        routingList={routingList}
        telegramBotsUrl={telegramBotsUrl}
        customerBotMenuTitle={customerBotMenuTitle}
        customerBotMenuLink={customerBotMenuLink}
        variantsStep="variantsView"
        hasTelegramBot={hasTelegramBot}
        hasTdLib={hasTdLib}
        botName={telegramBotName}
        dispatchingRouteUrl={dispatchingRouteUrl}
        editTdLibSessionsUrl={editTdLibSessionsUrl}
        editTelegramBotsUrl={editTelegramBotsUrl}
        foreignCustomer={foreignCustomer}
      />
    ),
    telegramTdLib: (
      <TelegramTdLib
        setVariant={setVariant}
        variantsStep="variantsView"
        currentRoutingSet={currentRoutingSet}
        routingList={routingList}
        dispatchingRouteUrl={dispatchingRouteUrl}
        authorizedNumber={authorizedNumber}
        hasTdLib={hasTdLib}
        hasTelegramBot={hasTelegramBot}
        editTdLibSessionsUrl={editTdLibSessionsUrl}
        editTelegramBotsUrl={editTelegramBotsUrl}
        foreignCustomer={foreignCustomer}
      />
    ),
    variantsView: (
      <VariantsView
        helpPhone={helpPhone}
        helpWhatsapp={helpWhatsapp}
        helpEmail={helpEmail}
        setVariant={setVariant}
        foreignCustomer={foreignCustomer}
        telegramBotStep="telegramBot"
        telegramTdLibStep="telegramTdLib"
      />
    ),
  };
  return component[variant];
};
