import { I18n } from 'i18n-js';
import Helper from '@/helper';
import merge from 'lodash.merge';

const localeFiles = import.meta.glob('./locales/*.yml', { eager: true });
const translations = Object.values(localeFiles).reduce((acc, module) => {
  return merge({}, acc, module.default);
}, {});
const i18n = new I18n(translations);

const defaultLocale = 'ru';

i18n.locale = Helper.getCookie('locale') || defaultLocale;
i18n.defaultLocale = defaultLocale;
i18n.enableFallback = true;

i18n.pluralization.register('ru', (_i18n, count) => {
  const mod10 = count % 10;
  const mod100 = count % 100;
  let key;

  const one = mod10 === 1 && mod100 !== 11;
  const few = [2, 3, 4].includes(mod10) && ![12, 13, 14].includes(mod100);
  const many = mod10 === 0 || [5, 6, 7, 8, 9].includes(mod10) || [11, 12, 13, 14].includes(mod100);

  if (one) {
    key = 'one';
  } else if (few) {
    key = 'few';
  } else if (many) {
    key = 'many';
  } else {
    key = 'other';
  }

  return [key];
});

export const locale = () => {
  return i18n.locale;
};

export const withoutCurrentLocales = () => {
  let allLocales = ['ru', 'en', 'pt-BR'];
  var filteredLocales = allLocales.filter(function(lc) {
    return lc !== locale();
  });

  return filteredLocales;
};

export const locale_for_video = () => {
  return i18n.locale == 'ru' ? 'ru' : 'en';
};

export default (namespace) =>
  (key, options = {}) =>
    i18n.t(namespace + key, options);
