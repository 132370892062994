import translator from "@/translator";
import React from "react";
import { Form, Input, Button, Space, Typography } from "antd";
import { InstagramOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import Telegram from "@/note_office/icons/Telegram";

const t = translator("note_office.views.contacts.contact_form.");

const { Text } = Typography;

const ContactForm = ({ form, initialValues, onFinish, loading, saving, onCancel }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
      className="wrapper_form wrapper_form__with-button overflow"
    >
      <div>
        <Form.Item name="name" className="contacts-form__item">
          <Input placeholder={t("placeholders.name")} className="contacts-form__input" />
        </Form.Item>

        <Form.Item name="phone" className="contacts-form__item">
          <PhoneInput
            country={initialValues?.country || "ru"}
            enableSearch={true}
            disableDropdown={false}
            inputClass="contacts-form__input-phone"
            containerClass="contacts-form__phone-container"
            buttonClass="contacts-form__phone-dropdown"
            placeholder={t("placeholders.phone")}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: false,
            }}
          />
        </Form.Item>

        <Text className="contacts__label">{t("labels.additional")}</Text>

        <Form.Item name="instagram" className="contacts-form__item">
          <Input
            placeholder={t("placeholders.instagram")}
            className="contacts-form__input"
            prefix={<InstagramOutlined className="contacts-form__prefix-icon" />}
          />
        </Form.Item>

        <Form.Item name="telegram" className="contacts-form__item">
          <Input
            placeholder={t("placeholders.telegram")}
            className="contacts-form__input"
            prefix={
              <span className="contacts-form__prefix-icon">
                <Telegram className="contacts-form__prefix-icon" />
              </span>
            }
          />
        </Form.Item>

        <Form.Item name="email" className="contacts-form__item">
          <Input placeholder={t("placeholders.email")} className="contacts-form__input" />
        </Form.Item>
      </div>

      <Form.Item style={{ marginBottom: "20px" }}>
        <Space direction="vertical" size={16} className="button-group">
          <Button type="primary" htmlType="submit" block loading={saving} className="button-base">
            {t("buttons.save")}
          </Button>

          {onCancel && (
            <Button
              type="default"
              block
              onClick={onCancel}
              className="button-base button-secondary"
            >
              {t("buttons.cancel")}
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
