import React from "react";
import { Card, Typography, Button, Table, Menu, Space } from "antd";
import BackButton from "@/note_office/shared/BackButton";
import translator from "@/translator";
import TariffCard from "@/note_office/shared/TariffCard";
import { Next } from "@/note_office/icons/profile";
import { PlusOutlined } from "@ant-design/icons";
import Calendar from "@/note_office/icons/Calendar2";
import Sort from "@/note_office/icons/Sort";

const { Text, Title } = Typography;

const t = translator("note_office.views.subscriptions.index_view.");

const IndexView = ({ subscriptionsData }) => {
  const paymentHistory = [
    {
      key: "1",
      date: "20.12.2025 12:30",
      amount: "10 ₽",
      type: "Бонусное пополнение",
    },
    {
      key: "2",
      date: "20.12.2025 12:30",
      amount: "10 ₽",
      type: "Бонусное пополнение",
    },
    {
      key: "3",
      date: "19.12.2025 15:45",
      amount: "20 ₽",
      type: "Оплата подписки",
    },
    {
      key: "4",
      date: "18.12.2025 10:20",
      amount: "15 ₽",
      type: "Пополнение через карту",
    },
    {
      key: "5",
      date: "17.12.2025 08:10",
      amount: "5 ₽",
      type: "Бонусное пополнение",
    },
    {
      key: "6",
      date: "16.12.2025 14:30",
      amount: "30 ₽",
      type: "Оплата подписки",
    },
  ];

  const columns = [
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Тип операции",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Тип платежа",
      dataIndex: "payment_type",
      key: "payment_type",
    },
  ];

  const changeTariffSetting = [
    {
      key: "settings",
      label: (
        <div className="profile__menu-item">
          <a href={""} className="profile__menu-link">
            {t("tariff_settings")}
          </a>
          <span className="profile__menu-arrow">
            <Next />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      <BackButton link={subscriptionsData.profile_url} title={t("title")} />

      <div className="overflow">
        <div className="profile__card subscriptions__card">
          <TariffCard tariffEndDate={subscriptionsData.tariffEndDate} />
        </div>

        <Menu
          mode="vertical"
          items={changeTariffSetting}
          className="profile__menu profile__menu-margin"
          selectedKeys={[]}
        />

        <Text className="subscriptions__label">SMS</Text>

        <div className="profile__card sms__card">
          <Card className="tariff-card">
            <div className="tariff-card__header">
              <p type="secondary" className="tariff-card__text">
                {t("balance")}
              </p>

              <Button
                type="default"
                className="tariff-card__badge"
                icon={<PlusOutlined />}
                href={subscriptionsData.recharge_url}
              >
                {t("recharge")}
              </Button>
            </div>
            <h2 className="tariff-card__title">${10}</h2>
          </Card>
        </div>

        <div className="subscriptions__label-container">
          <Text className="subscriptions__label">{t("payment_history")}</Text>
          <Calendar />
        </div>

        <div className="subscriptions__sort">
          <Sort />
        </div>

        <div className="subscriptions__table">
          <Table
            columns={columns}
            dataSource={paymentHistory}
            pagination={{ pageSize: 5 }}
            size="small"
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </div>
  );
};

export default IndexView;
