import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66671 1.8335C4.1689 1.8335 1.33337 4.66903 1.33337 8.16683C1.33337 11.6646 4.1689 14.5002 7.66671 14.5002C9.17547 14.5002 10.561 13.9726 11.6489 13.0918L13.5286 14.9716C13.789 15.2319 14.2111 15.2319 14.4714 14.9716C14.7318 14.7112 14.7318 14.2891 14.4714 14.0288L12.5917 12.149C13.4725 11.0611 14 9.67559 14 8.16683C14 4.66903 11.1645 1.8335 7.66671 1.8335ZM2.66671 8.16683C2.66671 5.40541 4.90528 3.16683 7.66671 3.16683C10.4281 3.16683 12.6667 5.40541 12.6667 8.16683C12.6667 10.9283 10.4281 13.1668 7.66671 13.1668C4.90528 13.1668 2.66671 10.9283 2.66671 8.16683Z"
          fill="black"
          fillOpacity="0.45"
        />
      </svg>
    </SVGUniqueID>
  );
};
