import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default () => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 22V4C2 3.45 2.196 2.97933 2.588 2.588C2.98 2.19667 3.45067 2.00067 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.0217 17.805 20.5507 18.0007 20 18H6L2 22ZM8 11C8.28333 11 8.521 10.904 8.713 10.712C8.905 10.52 9.00067 10.2827 9 10C8.99933 9.71733 8.90333 9.48 8.712 9.288C8.52067 9.096 8.28333 9 8 9C7.71667 9 7.47933 9.096 7.288 9.288C7.09667 9.48 7.00067 9.71733 7 10C6.99933 10.2827 7.09533 10.5203 7.288 10.713C7.48067 10.9057 7.718 11.0013 8 11ZM12 11C12.2833 11 12.521 10.904 12.713 10.712C12.905 10.52 13.0007 10.2827 13 10C12.9993 9.71733 12.9033 9.48 12.712 9.288C12.5207 9.096 12.2833 9 12 9C11.7167 9 11.4793 9.096 11.288 9.288C11.0967 9.48 11.0007 9.71733 11 10C10.9993 10.2827 11.0953 10.5203 11.288 10.713C11.4807 10.9057 11.718 11.0013 12 11ZM16 11C16.2833 11 16.521 10.904 16.713 10.712C16.905 10.52 17.0007 10.2827 17 10C16.9993 9.71733 16.9033 9.48 16.712 9.288C16.5207 9.096 16.2833 9 16 9C15.7167 9 15.4793 9.096 15.288 9.288C15.0967 9.48 15.0007 9.71733 15 10C14.9993 10.2827 15.0953 10.5203 15.288 10.713C15.4807 10.9057 15.718 11.0013 16 11Z"
          fill="#FFC917"
        />
      </svg>
    </SVGUniqueID>
  );
};
