import translator from "@/translator";
import React, { useEffect, useState } from "react";
import { List, Input, Button, Avatar, Typography, Spin } from "antd";
import { PlusOutlined, MessageOutlined } from "@ant-design/icons";
import Search from "@/note_office/icons/Search";
import DropdownMenu from "./components/DropdownMenu";
import EmptyView from "@/note_office/shared/EmptyView";
import RedirectButton from "@/note_office/shared/RedirectButton";
import CustomPagination from "@/note_office/shared/CustomPagination";

const { Title } = Typography;
const t = translator("note_office.views.contacts.index_view.");

const getRandomColor = () => {
  const colors = ["#FFC107", "#03A9F4", "#8BC34A", "#FF5722", "#9C27B0", "#FF9800"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const IndexView = ({ contactsData }) => {
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState(contactsData.contacts || []);
  const [currentPage, setCurrentPage] = useState(contactsData.current_page || 1);
  const [loading, setLoading] = useState(false);

  const {
    total_contacts = 0,
    per_page = 8,
    total_pages,
    contacts_json_url,
    new_contact_url,
    import_url,
    dialogs_url,
  } = contactsData;

  useEffect(() => {
    fetchContacts(currentPage);
  }, [currentPage]);

  const fetchContacts = async (page) => {
    setLoading(true);

    try {
      const response = await fetch(`${contacts_json_url}?page=${page}&per_page=${per_page}`);
      const data = await response.json();

      setContacts(data.contacts || []);
    } catch (error) {
      console.error(t("errors.server_error"), error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      {!total_contacts ? (
        <EmptyView new_contact_url={new_contact_url} import_url={import_url} />
      ) : (
        <>
          <div className="contacts-index__header">
            <Title level={4} className="contacts-index__title">
              {t("contacts.header")} <span style={{ color: "#EA9308" }}>{total_contacts}</span>{" "}
              {t("contacts", { count: total_contacts })} {t("contacts.header_tail")}
            </Title>
            <DropdownMenu contactsData={contactsData} />
          </div>

          <Input
            placeholder={t("placeholders.search_input")}
            prefix={<Search />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="contacts-index__search"
          />

          <div className="contacts-index__list overflow">
            <List
              itemLayout="horizontal"
              dataSource={contacts}
              renderItem={(contact) => (
                <List.Item onClick={() => (window.location.href = contact.show_url)}>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        className="contacts-index__avatar"
                        style={{
                          backgroundColor: contactsData.avatar ? "transparent" : getRandomColor(),
                        }}
                      >
                        {contact.name[0].toUpperCase()}
                      </Avatar>
                    }
                    title={<div className="contacts-index__name">{contact.name}</div>}
                    description={<div className="contacts-index__phone">{contact.phone}</div>}
                  />
                  <Button
                    shape="circle"
                    icon={<MessageOutlined className="contacts-index__chat-icon" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.location.href = dialogs_url;
                    }}
                    className="contacts-index__chat-btn"
                  />
                </List.Item>
              )}
            />
            {loading && <Spin size="large" className="loader" />}
          </div>
        </>
      )}
      {total_pages > 1 && (
        <CustomPagination
          current={currentPage}
          total={total_contacts}
          pageSize={per_page}
          onChange={handlePageChange}
        />
      )}
      <div className="contacts-index__add-btn">
        <RedirectButton
          url={new_contact_url}
          type="primary"
          shape="circle"
          icon={<PlusOutlined style={{ color: "white" }} />}
          size="large"
        />
      </div>
    </div>
  );
};

export default IndexView;
