import React from "react";
import translator from "@/translator";
const t = translator("front_office.components.ready_to_help.");

const AdviceCard = (props) => {
  return (
    <div className="rec_cart">
      <div className="rec_cart-title">{props.title}</div>
      <div className="rec_cart-desc">{props.desc}</div>
      {props.link && (
        <a href={props.link} target="_blank" className="">
          {props.link_text ? props.link_text : t("read_article")}
        </a>
      )}
    </div>
  );
};

export default AdviceCard;
