import translator from "@/translator";
import React, { useEffect, useState } from "react";
import { Button, Card, Tag, Typography, Space, Spin } from "antd";
import BackButton from "@/note_office/shared/BackButton";
import CustomPagination from "@/note_office/shared/CustomPagination";
import axios from "axios";

const { Text } = Typography;
const t = translator("note_office.views.bulk_deliveries.index_view.");

const IndexView = ({ bulkDeliveriesData }) => {
  const {
    per_page = 5,
    profile_url,
    total_count,
    bulk_deliveries,
    bulk_deliveries_url,
    new_bulk_delivery_url,
  } = bulkDeliveriesData || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [bulkDeliveries, setBulkDeliveries] = useState(bulk_deliveries);
  const [totalCount, setTotalCount] = useState(total_count);

  const fetchBulkDeliveries = async () => {
    setLoading(true);

    try {
      const url = `${bulk_deliveries_url}?page=${currentPage}&per_page=${per_page}`;
      const { data } = await axios.get(url);

      setBulkDeliveries(data.bulk_deliveries);
      setTotalCount(data.total_count);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBulkDeliveries();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      <BackButton link={profile_url} text={t("title")} />

      <Button
        type="primary"
        href={new_bulk_delivery_url}
        block
        className="button-base"
        style={{ marginBottom: "10px" }}
      >
        {t("create_bulk_deliveries")}
      </Button>

      <div className="bulk_deliveries__card-container overflow">
        {bulkDeliveries.length === 0 ? (
          <Text type="secondary">{t("no_data")}</Text>
        ) : (
          bulkDeliveries.map((delivery) => (
            <Card key={delivery.id} className="bulk_deliveries__card">
              <div className="bulk_deliveries__info">
                <div className="bulk_deliveries__column">
                  <span className="bulk_deliveries__label">{t("sent")}:</span>
                  <span className="bulk_deliveries__value">{delivery.sent}</span>
                </div>
                <div className="bulk_deliveries__column">
                  <span className="bulk_deliveries__label">{t("delivered")}:</span>
                  <span className="bulk_deliveries__value">{delivery.delivered}</span>
                </div>
              </div>

              <div className="bulk_deliveries__details">
                <span className="bulk_deliveries__label">{t("status.label")}:</span>
                <Tag color={delivery.status_color}>{delivery.status}</Tag>
              </div>

              <div className="bulk_deliveries__details">
                <span className="bulk_deliveries__label">{t("date_time")}:</span>
                <span className="bulk_deliveries__text">{delivery.date}</span>
              </div>

              <div className="bulk_deliveries__details">
                <span className="bulk_deliveries__label">{t("channel")}:</span>
                <span className="bulk_deliveries__text">{delivery.channel}</span>
              </div>

              <div className="bulk_deliveries__details bulk_deliveries__details-last">
                <span className="bulk_deliveries__label">{t("text.label")}:</span>
                <span className="bulk_deliveries__text">{delivery.text}</span>
              </div>
            </Card>
          ))
        )}
      </div>

      {loading && <Spin size="large" className="loader" />}

      {totalCount > 1 && (
        <CustomPagination
          current={currentPage}
          total={totalCount}
          pageSize={per_page}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default IndexView;
