import React from "react";
import Back from "@/note_office/icons/Back";

const BackButton = ({ link, text, onClick, extra, children }) => {
  const Component = onClick ? "button" : "a";

  return (
    <div className="back_button__container">
      <Component
        {...(onClick ? { onClick, type: "button" } : { href: link })}
        className="back_button"
      >
        <div className="back_button__content">
          <div className="back_button__link">
            <Back />
            {text && <div className="back_button__text">{text}</div>}
          </div>
          {extra && <div className="back_button__extra">{extra}</div>}
        </div>
      </Component>

      {children && <div>{children}</div>}
    </div>
  );
};

export default BackButton;
