import React from 'react';
import { Modal, Alert, Space, Button } from 'antd';
import LoadingSpin from '@front_office/components/bulk_deliveries/LoadingSpin';

import translator from '@/translator';
const t = translator('front_office.views.bulk_deliveries.modals.bulk.');

const Bulk = ({ visible, onCancel, onOk, response, isLoading }) => {
  const { errors, api_errors, modal_dispatch_desc, modal_dispatch_warnings } = response;
  const without_errors = api_errors && api_errors.length == 0 && errors && errors.length == 0;
  return (
    <Modal
      centered
      title={t('title')}
      visible={visible}
      onCancel={onCancel}
      maskClosable={true}
      footer={
        <>
          <Button type="default" onClick={onCancel}>
            {t('back')}
          </Button>

          {without_errors && (
            <Button type="primary" disabled={isLoading} onClick={onOk}>
              {isLoading && <LoadingSpin />}
              {t('confirm')}
            </Button>
          )}
        </>
      }
    >
      <Space size="small" direction="vertical">
        {errors &&
          errors.map((error) => (
            <Alert
              key={error}
              type="error"
              description={<div dangerouslySetInnerHTML={{ __html: error }}></div>}
            ></Alert>
          ))}
        {modal_dispatch_warnings &&
          modal_dispatch_warnings.map((warn) => (
            <Alert
              key={warn}
              type="warning"
              description={<div dangerouslySetInnerHTML={{ __html: warn }}></div>}
            ></Alert>
          ))}
        {without_errors && <div dangerouslySetInnerHTML={{ __html: modal_dispatch_desc }}></div>}
      </Space>
    </Modal>
  );
};

export default Bulk;
