import translator from "@/translator";
import React from "react";
import { Form, Space } from "antd";
import RedirectButton from "@/note_office/shared/RedirectButton";
import Blob from "@/note_office/icons/Blob.png";

const t = translator("note_office.views.contacts.empty_view.");

const EmptyView = ({ new_contact_url, import_url }) => {
  return (
    <div className="contacts-empty">
      <div>
        <div className="contacts-empty__illustration">
          <img src={Blob} alt="Blob" />
        </div>

        <h2 className="contacts-empty__title">{t("no_contacts")}</h2>

        <Form.Item>
          <Space direction="vertical" size={16} className="button-group">
            <RedirectButton type="primary" block className="button-base" url={new_contact_url}>
              {t("add_contact")}
            </RedirectButton>

            <RedirectButton
              type="default"
              block
              className="button-base button-secondary"
              url={import_url}
            >
              {t("import_contacts")}
            </RedirectButton>
          </Space>
        </Form.Item>
      </div>
    </div>
  );
};

export default EmptyView;
