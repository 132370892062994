import translator from "@/translator";
import React from "react";
import { Form, Input, Button, Space, Typography, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const t = translator("note_office.views.profile.profile_form.");

const { Text } = Typography;

const ProfileForm = ({
  form,
  onFinish,
  loading,
  saving,
  logout_link,
  delete_link,
  timezones,
  locales,
}) => {
  const timezoneOptions = timezones.map(([label, value]) => ({ label, value }));
  const localeOptions = locales.map(([label, value]) => ({ label, value }));

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="wrapper_form wrapper_form__with-button overflow"
    >
      <div>
        <Text className="contacts__label profile__label">{t("contact_info")}</Text>

        <Form.Item name="phone" className="contacts-form__item">
          <PhoneInput
            enableSearch={true}
            disableDropdown={false}
            inputClass="contacts-form__input-phone"
            containerClass="contacts-form__phone-container"
            buttonClass="contacts-form__phone-dropdown"
            placeholder={t("placeholders.phone")}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: false,
            }}
            onChange={() => form.setFields([{ name: "phone", errors: [] }])}
          />
        </Form.Item>

        <Form.Item name="email" className="contacts-form__item">
          <Input
            placeholder={t("placeholders.email")}
            className="contacts-form__input"
            onChange={() => form.setFields([{ name: "email", errors: [] }])}
          />
        </Form.Item>

        <Text className="contacts__label profile__label profile__label-item">
          {t("password_change")}
        </Text>

        <Form.Item name="current_password" className="contacts-form__item">
          <Input.Password
            placeholder={t("placeholders.current_password")}
            className="contacts-form__input"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={() => form.setFields([{ name: "current_password", errors: [] }])}
          />
        </Form.Item>

        <Form.Item name="password" className="contacts-form__item" validateTrigger="onChange">
          <Input.Password
            placeholder={t("placeholders.password")}
            className="contacts-form__input"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={() => form.setFields([{ name: "password", errors: [] }])}
          />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          className="contacts-form__item"
          validateTrigger="onChange"
        >
          <Input.Password
            placeholder={t("placeholders.password_confirmation")}
            className="contacts-form__input"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={() => form.setFields([{ name: "password_confirmation", errors: [] }])}
          />
        </Form.Item>

        <Text className="contacts__label profile__label profile__label-item">{t("timezone")}</Text>

        <Form.Item name="timezone">
          <Select
            options={timezoneOptions}
            value={form.getFieldValue("timezone") || "Europe/Moscow"}
            onChange={(timezone) => form.setFieldsValue({ timezone })}
            className="profile-form__select"
          />
        </Form.Item>

        <Text className="contacts__label profile__label profile__label-item">{t("language")}</Text>

        <Form.Item name="locale">
          <Select
            options={localeOptions}
            value={form.getFieldValue("locale") || "en"}
            onChange={(locale) => form.setFieldsValue({ locale })}
            className="profile-form__select"
          />
        </Form.Item>
      </div>

      <Form.Item style={{ marginBottom: 0 }}>
        <Space direction="vertical" size={16} className="button-group">
          <Button type="primary" htmlType="submit" block loading={saving} className="button-base">
            {t("save_changes")}
          </Button>

          <Button
            type="link"
            block
            href={logout_link}
            data-turbo-method="delete"
            data-method="delete"
            className="button-base button-secondary"
          >
            {t("logout")}
          </Button>

          <Button
            type="link"
            block
            href={delete_link}
            data-method="delete"
            className="button-base button-third"
            data-confirm={t("delete_confirm")}
          >
            {t("delete_account")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ProfileForm;
