import translator from "@/translator";
import React, { useContext, useState, useEffect } from "react";
import { Button, Card, Spin, Typography, Space } from "antd";
import BackButton from "@/note_office/shared/BackButton";
import CustomPagination from "@/note_office/shared/CustomPagination";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../State";

const { Text } = Typography;
const t = translator("note_office.views.bulk_deliveries.template_view.");

const fakeTemplates = Array.from({ length: 25 }, (_, i) => ({
  id: i + 1,
  channel: "WhatsApp / SMS",
  text: `Шаблон ${i + 1}: Добрый вечер! У нас замечательная новость...`,
}));

const TemplateView = () => {
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state, updateState } = useContext(StateContext);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }, [currentPage]);

  const indexOfLastTemplate = currentPage * perPage;
  const indexOfFirstTemplate = indexOfLastTemplate - perPage;
  const currentTemplates = fakeTemplates.slice(indexOfFirstTemplate, indexOfLastTemplate);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectTemplate = () => {
    const selectedTemplate = fakeTemplates.find((t) => t.id === selectedTemplateId);
    if (selectedTemplate) {
      updateState({
        selectedTemplateText: selectedTemplate.text,
        selectedChannels: parseChannels(selectedTemplate.channel),
      });
      navigate("/create");
    }
  };

  const parseChannels = (channelText) => ({
    whatsapp: channelText.includes("WhatsApp"),
    sms: channelText.includes("SMS"),
  });

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      <BackButton onClick={() => navigate("/")} text={t("title")} />

      <div className="bulk_deliveries__card-container overflow">
        {currentTemplates.length === 0 ? (
          <Text type="secondary">{t("no_templates")}</Text>
        ) : (
          currentTemplates.map((template) => (
            <Card
              key={template.id}
              className={`bulk_deliveries__card ${selectedTemplateId === template.id ? "selected-template" : ""}`}
              onClick={() => setSelectedTemplateId(template.id)}
            >
              <div className="bulk_deliveries__details-template">
                <span className="bulk_deliveries__label">{t("channel_label")}:</span>
                <span className="bulk_deliveries__text">{template.channel}</span>
              </div>

              <div className="bulk_deliveries__details-template bulk_deliveries__details-last">
                <span className="bulk_deliveries__label">{t("text_label")}:</span>
                <span className="bulk_deliveries__fulltext">{template.text}</span>
              </div>
            </Card>
          ))
        )}
      </div>

      {loading && <Spin size="large" className="loader" />}

      <CustomPagination
        current={currentPage}
        total={fakeTemplates.length}
        pageSize={perPage}
        onChange={handlePageChange}
      />

      <div className="button-group">
        <Button
          type="primary"
          block
          className="button-base"
          onClick={handleSelectTemplate}
          disabled={!selectedTemplateId}
        >
          {t("choose_template")}
        </Button>

        <Button
          type="link"
          block
          data-method="delete"
          className="button-base button-third"
          data-confirm={t("delete_confirm")}
        >
          {t("delete_template")}
        </Button>
      </div>
    </div>
  );
};

export default TemplateView;
