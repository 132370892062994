import React from 'react';
import { Input, List, Badge, Space, Typography } from 'antd';
import { SearchOutlined, RightOutlined, EditOutlined } from '@ant-design/icons';

const { Text } = Typography;

const IndexView = () => {
  const messages = [
    {
      id: 1,
      title: 'Массовые рассылки',
      icon: 'mail',
      unread: 0,
    },
    {
      id: 2,
      title: 'Администрация Chategy',
      icon: 'whatsapp',
      time: '14:29',
      message: 'Ваш аккаунт подтвержден, перейдите по ссылке чтобы продолжить...',
      unread: 2,
    },
  ];

  return (
    <div style={{ padding: '16px', backgroundColor: '#f5f5f5', height: '100vh' }}>
      {/* Search Header */}
      <div style={{ position: 'relative', marginBottom: '16px' }}>
        <Input
          placeholder="Поиск сообщений"
          prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
          suffix={<EditOutlined style={{ color: '#e8a44d' }} />}
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '8px 12px',
          }}
        />
      </div>

      {/* Messages List */}
      <List
        dataSource={messages}
        renderItem={(item) => (
          <List.Item
            style={{
              padding: '12px 0',
              cursor: 'pointer',
              borderBottom: '1px solid #f0f0f0',
            }}
          >
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space size={12}>
                <span style={{ fontSize: '20px' }}>✉️</span>

                <Space direction="vertical" size={0}>
                  <Text strong>{item.title}</Text>
                  {item.message && (
                    <Text type="secondary" style={{ fontSize: '14px' }}>
                      {item.message}
                    </Text>
                  )}
                </Space>
              </Space>
              <Space direction="vertical" align="end">
                {item.time && (
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    {item.time}
                  </Text>
                )}
                {item.unread > 0 && (
                  <Badge
                    count={item.unread}
                    style={{
                      backgroundColor: '#e8a44d',
                      boxShadow: 'none',
                    }}
                  />
                )}
                <RightOutlined style={{ color: '#bfbfbf' }} />
              </Space>
            </Space>
          </List.Item>
        )}
      />
    </div>
  );
};

export default IndexView;
