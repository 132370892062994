import React from 'react';
import { Button, Form, Input, Space, notification } from 'antd';
import axios from 'axios';

import translator from '@/translator';
const t = translator('front_office.views.registration.password.reset.');

const Reset = ({ customer, setSection }) => {
  const onFinish = (values) => {
    axios({
      method: 'put',
      url: '/customers/password',
      data: values,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        setSection('sign_in');
        notification['success']({ description: t('success_message'), placement: 'top' });
      } else {
        data.errors.forEach((error) =>
          notification['error']({ description: error, placement: 'top' }),
        );
      }
    });
  };

  return (
    <>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.List name="customer">
          {(_fields) => (
            <>
              <Form.Item hidden name="confirm_code" initialValue={customer.code}>
                <Input />
              </Form.Item>
              <Form.Item hidden name="id" initialValue={customer.id}>
                <Input />
              </Form.Item>

              <Form.Item label={t('new_password_label')} name="password">
                <Input name="password" size="large" type="password" />
              </Form.Item>
              <Form.Item label={t('confirm_password_label')} name="password_confirmation">
                <Input name="password_confirmation" size="large" type="password" />
              </Form.Item>
              <Form.Item>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Button block={true} type="primary" htmlType="submit">
                    {t('confirm_button')}
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
};

export default Reset;
