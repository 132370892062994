import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#747474" />
        <path
          d="M7.5218 11V6.09091H8.47741V11H7.5218ZM8.00439 5.33345C7.8382 5.33345 7.69545 5.27805 7.57613 5.16726C7.45894 5.05433 7.40035 4.9201 7.40035 4.76456C7.40035 4.60689 7.45894 4.47266 7.57613 4.36186C7.69545 4.24893 7.8382 4.19247 8.00439 4.19247C8.17059 4.19247 8.31228 4.24893 8.42947 4.36186C8.54878 4.47266 8.60844 4.60689 8.60844 4.76456C8.60844 4.9201 8.54878 5.05433 8.42947 5.16726C8.31228 5.27805 8.17059 5.33345 8.00439 5.33345Z"
          fill="#747474"
        />
      </svg>
    </SVGUniqueID>
  );
};
