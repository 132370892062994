// Based on https://github.com/reactjs/react-rails/issues/1134#issuecomment-1415112288
export const viteConstructorRequireContext = function(reqCtx) {
  const componentNameMatcher = className => {
    return path => {
      return (
        path.includes(`/${className}.js`) || path.includes(`/${className}/index.js`) ||
        path.includes(`/${className}.ts`) || path.includes(`/${className}/index.ts`)
      );
    };
  };

  const fromRequireContext = function(reqCtx) {
    return function(className) {
      const componentPath = Object.keys(reqCtx).find(componentNameMatcher(className));

      const component = reqCtx[componentPath];
      return component.default;
    }
  }

  const fromCtx = fromRequireContext(reqCtx);
  return function(className) {
    var component;
    try {
      // `require` will raise an error if this className isn't found:
      component = fromCtx(className);
    } catch (firstErr) {
      console.error(firstErr);
    }
    return component;
  }
}
