import React from "react";
import { Row, Col, Avatar } from "antd";
import UserCircle from "@/note_office/icons/UserCircle";
import Calendar from "@/note_office/icons/Calendar";
import Chat from "@/note_office/icons/Chat";
import translator from "@/translator";
import classNames from "classnames";

const t = translator("note_office.shared.menu.");

const Menu = ({ menuData }) => {
  const currentPath = window.location.pathname;

  return (
    <div className="menu">
      <Row className="menu__row">
        <Col xs={6}>
          <a
            href={menuData.contacts_url}
            className={classNames("menu-item", {
              active: currentPath === menuData.contacts_url,
            })}
          >
            <div className="menu-item__img">
              <UserCircle />
            </div>
            <div className="menu-item__text">{t("contacts")}</div>
          </a>
        </Col>
        <Col xs={6}>
          <a href="#" className="menu-item">
            <div className="menu-item__img">
              <Calendar />
            </div>
            <div className="menu-item__text">{t("calendar")}</div>
          </a>
        </Col>
        <Col xs={6}>
          <a
            href={menuData.dialogs_url}
            className={classNames("menu-item", {
              active: currentPath === menuData.dialogs_url,
            })}
          >
            <div className="menu-item__img">
              <Chat />
            </div>
            <div className="menu-item__text">{t("chats")}</div>
          </a>
        </Col>
        <Col xs={6}>
          <a
            href={menuData.profile_url}
            className={classNames("menu-item", {
              active: menuData.profile_urls.some((url) => currentPath.startsWith(url)),
            })}
          >
            <div className="menu-item__img">
              <Avatar style={{ background: "#FFD8BF" }}>{menuData.account_letter}</Avatar>
            </div>
            <div className="menu-item__text">{t("profile")}</div>
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default Menu;
