import React from 'react';
import { Row, Cell } from '@components/grid';
import { InfoPane } from '../InfoPane';
import { FooterButtons } from '../FooterButtons';
import VideoView from '../VideoView';

import translator, { locale_for_video } from '@/translator';
const t = translator('front_office.views.integrations.telegram_bot.bot_creation.');

export default ({ setStep }) => (
  <div className="telegram-flow_split-pane">
    <VideoView link={`/step2_${locale_for_video()}.mp4`} />
    <div className="telegram-flow_split-pane_right">
      <Row>
        <Cell>
          <InfoPane title={t('info_pane_title')}>
            <p className="telegram-flow_link">
              {t('info_pane_text_first')}
              <span>
                <a href="https://t.me/BotFather" target="_blank">
                  {t('info_pane_text_link')}
                </a>
              </span>
              {t('info_pane_text_second')} <span>/newbot</span>.
            </p>
          </InfoPane>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <FooterButtons
            forwardAction={() => setStep('connectingBot')}
            backwardAction={() => setStep('notification')}
          ></FooterButtons>
        </Cell>
      </Row>
    </div>
  </div>
);
