import React from "react";
import { Dropdown, Button } from "antd";
import Sort from "@/note_office/icons/Sort";
import translator from "@/translator";

const t = translator("note_office.views.deliveries.dropdown_menu.");

const DropdownMenu = ({ onSortChange }) => {
  const handleMenuClick = (e) => {
    onSortChange(e.key);
  };

  const menuItems = [
    { key: "executed_at", label: t("date") },
    { key: "delivery_status.status", label: t("status") },
    { key: "sender_name", label: t("sender_name") },
  ];

  return (
    <Dropdown
      menu={{ items: menuItems, onClick: handleMenuClick }}
      trigger={["click"]}
      overlayClassName="dropdown-menu__menu"
    >
      <Button type="text" icon={<Sort className="dropdown-menu__btn" />} />
    </Dropdown>
  );
};

export default DropdownMenu;
