import React from 'react';
import SelectorView from '../../components/SelectorView';
import { Row, Col, Typography } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import { useNotificationsState } from './State';
import translator from '@/translator';
const t = translator('front_office.views.yclients_notification_settings.');
const { Text } = Typography;

const createDispatchRoutingStep = ({ isForBot = false } = {}) => ({
  title: t('steps.dispatch_routing'),
  validation: ({ dispatch_routing }) =>
    (dispatch_routing || []).some(([_channel, isActive]) => !!isActive),
  Component: ({ isVisible }) => {
    const disabled = isForBot;
    const { state } = useNotificationsState();
    const { control } = useFormContext();
    const defaultValue = isForBot ? state.bot_channels : state.channels;
    const helpText = isForBot
      ? t('fields.dispatch_routing_help_bot_feedback_request')
      : t('fields.dispatch_routing_help');

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
        <Col sm={24} xl={11}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text strong>{t('fields.dispatch_routing')}</Text>
            </Col>
            <Col span={24}>
              <Text>{helpText}</Text>
            </Col>
          </Row>
        </Col>
        <Col sm={0} xl={2} />
        <Col
          sm={24}
          xl={11}
          flex="auto"
          className="yclients-notification-settings-create__dispatch_routing"
        >
          <Controller
            control={control}
            name="dispatch_routing"
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
              <SelectorView
                disabled={disabled}
                channels={value}
                setChannelsOrder={onChange}
                hasTelegramBot={state.has_telegram_bot}
                hasTdLib={state.has_td_lib}
                editTelegramBotsUrl={state.edit_telegram_bots_url}
                editTdLibSessionsUrl={state.edit_td_lib_sessions_url}
              />
            )}
          />
        </Col>
      </Row>
    );
  },
});

export default createDispatchRoutingStep;
