import React, { useRef, useState } from 'react';
import jstz from 'jstz';
import Helper from '@/helper';
import PhoneInput from 'react-phone-input-2';
import { Button, Checkbox, Form, notification, Input, Space } from 'antd';
import axios from 'axios';
import Captcha from '@front_office/components/Captcha';

import translator from '@/translator';
const t = translator('front_office.views.registration.sign_up.start.');

const Start = ({
  onlyRussianNumbersAllowed,
  setSection,
  setCustomerData,
  defaultCountry,
  projectScopeShort,
}) => {
  const captchaRef = useRef(null);

  const [form] = Form.useForm();
  Helper.setCookie('time_zone', jstz.determine().name());

  const skipCaptcha = new URLSearchParams(window.location.search).get('skip_captcha');
  const [disableSubmit, setDisableSubmit] = useState(skipCaptcha != 'true');

  const changeCountry = ({ countryCode }) => {
    if (countryCode) {
      form.setFieldsValue({ registration_create: { phone_iso: countryCode.toUpperCase() } });
    }
  };

  const onChangeCaptcha = (value) => {
    setDisableSubmit(false);
  };

  const onFinish = (values) => {
    var railsViewErrors = document.getElementById('rails-view-errors');
    if (railsViewErrors) {
      railsViewErrors.innerHTML = '';
    }
    const token = captchaRef.current.getValue();

    let params = Object.assign(values, { 'smart-token': token });
    if (skipCaptcha) params = Object.assign(params, { skip_captcha: skipCaptcha });

    axios({
      method: 'post',
      url: '/customers',
      data: params,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        setCustomerData(data.customer);
        setSection(data.section || 'sms');
      } else {
        captchaRef.current.reset();
        data.errors.forEach((error) =>
          notification['error']({ description: error, placement: 'top' }),
        );
      }
    });
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.List name="registration_create">
        {(_fields) => (
          <>
            <Form.Item hidden name="phone_iso" initialValue={'RU'}>
              <Input />
            </Form.Item>
            <Form.Item
              label={t('email_label')}
              name="email"
              initialValue={decodeURIComponent(Helper.getCookie('autoreg_email') || '')}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label={t('phone_label')}
              name="phone"
              initialValue={decodeURIComponent(Helper.getCookie('autoreg_phone') || '')}
            >
              <PhoneInput
                country={defaultCountry}
                preferredCountries={
                  onlyRussianNumbersAllowed ? [defaultCountry] : [defaultCountry, 'kz', 'ua', 'by']
                }
                onlyCountries={onlyRussianNumbersAllowed ? [defaultCountry] : []}
                inputClass="ant-input ant-input-lg"
                containerClass="react-tel-input-custom-antd"
                onChange={(_phone, countryData) => changeCountry(countryData)}
              />
            </Form.Item>
            <Form.Item label={t('password_label')} name="password">
              <Input size="large" type="password" />
            </Form.Item>
            <Form.Item label={t('confirm_password_label')} name="password_confirmation">
              <Input size="large" type="password" />
            </Form.Item>
            <Form.Item name="tos_agreement" valuePropName="checked">
              <Checkbox>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('tos_agreement_text', { project_short: projectScopeShort }),
                  }}
                ></span>
              </Checkbox>
            </Form.Item>
            <Form.Item name="offer_agreement" valuePropName="checked">
              <Checkbox>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('offer_agreement_text', { project_short: projectScopeShort }),
                  }}
                ></span>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Captcha ref={captchaRef} onChange={onChangeCaptcha} />

                <Button block={true} type="primary" htmlType="submit" disabled={disableSubmit}>
                  {t('register_button')}
                </Button>
                <a
                  className="ant-btn ant-btn-default ant-btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    setSection('sign_in');
                  }}
                >
                  {t('already_account_text')}
                </a>
              </Space>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default Start;
