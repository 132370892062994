import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default () => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.2328 22.003 8.4966 21.5353 6.97002 20.645L2.00402 22L3.35602 17.032C2.46497 15.5049 1.99692 13.768 2.00002 12C2.00002 6.477 6.47702 2 12 2ZM8.59202 7.3L8.39201 7.308C8.26271 7.31691 8.13636 7.35087 8.02002 7.408C7.91159 7.46951 7.81258 7.5463 7.72602 7.636C7.60602 7.749 7.53802 7.847 7.46502 7.942C7.09514 8.4229 6.89599 9.01331 6.89902 9.62C6.90102 10.11 7.02902 10.587 7.22902 11.033C7.63802 11.935 8.31102 12.89 9.19902 13.775C9.41302 13.988 9.62302 14.202 9.84902 14.401C10.9524 15.3724 12.2673 16.073 13.689 16.447L14.257 16.534C14.442 16.544 14.627 16.53 14.813 16.521C15.1042 16.5056 15.3885 16.4268 15.646 16.29C15.7769 16.2223 15.9047 16.1489 16.029 16.07C16.029 16.07 16.0714 16.0413 16.154 15.98C16.289 15.88 16.372 15.809 16.484 15.692C16.568 15.6053 16.638 15.5047 16.694 15.39C16.772 15.227 16.85 14.916 16.882 14.657C16.906 14.459 16.899 14.351 16.896 14.284C16.892 14.177 16.803 14.066 16.706 14.019L16.124 13.758C16.124 13.758 15.254 13.379 14.722 13.137C14.6663 13.1128 14.6067 13.0989 14.546 13.096C14.4776 13.0888 14.4084 13.0965 14.3432 13.1184C14.278 13.1403 14.2182 13.176 14.168 13.223C14.163 13.221 14.096 13.278 13.373 14.154C13.3315 14.2098 13.2744 14.2519 13.2088 14.2751C13.1433 14.2982 13.0723 14.3013 13.005 14.284C12.9398 14.2666 12.876 14.2446 12.814 14.218C12.69 14.166 12.647 14.146 12.562 14.11C11.9879 13.8599 11.4565 13.5215 10.987 13.107C10.861 12.997 10.744 12.877 10.624 12.761C10.2306 12.3842 9.88777 11.958 9.60401 11.493L9.54501 11.398C9.50328 11.3338 9.46905 11.265 9.44302 11.193C9.40502 11.046 9.50402 10.928 9.50402 10.928C9.50402 10.928 9.74702 10.662 9.86002 10.518C9.97002 10.378 10.063 10.242 10.123 10.145C10.241 9.955 10.278 9.76 10.216 9.609C9.93602 8.925 9.64668 8.24467 9.34802 7.568C9.28902 7.434 9.11402 7.338 8.95502 7.319C8.90102 7.31233 8.84701 7.307 8.79302 7.303C8.65874 7.2953 8.52411 7.29664 8.39002 7.307L8.59202 7.3Z"
          fill="#FFC917"
        />
      </svg>
    </SVGUniqueID>
  );
};
