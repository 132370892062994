import React from 'react';
import { Row, Col, Typography, Select, Switch, TimePicker, Checkbox } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import WarningAlert from './WarningAlert';
import { useNotificationsState, getActionTypeName } from './State';
import translator from '@/translator';
import moment from 'moment';
const t = translator('front_office.views.yclients_notification_settings.');
const { Text } = Typography;

const createVisitFixedTimeOffsetStep = ({
  offsetsStateKey,
  fixedOffsetsStateKey,
  fixedTimeText = '',
  fixedTimeOnText = '',
  fixedTimeOffText = '',
  withoutDuplicatesText = '',
  helpText = '',
  alerts = [],
} = {}) => ({
  title: t('steps.time'),
  validation: ({ selected_fixed_time, fixed_time }) =>
    selected_fixed_time ? moment(fixed_time, 'HH:mm', true).isValid() : true,
  Component: ({ isVisible }) => {
    const { state } = useNotificationsState();
    const { action_type } = useParams();
    const { control, watch } = useFormContext();
    const isFixedTime = watch('selected_fixed_time');

    const handleFixedTimeChange = (onChange) => (timeAsMoment) => {
      if (!timeAsMoment || !timeAsMoment.isValid()) {
        onChange(state['default_fixed_time_value']);
      } else {
        onChange(timeAsMoment.format('HH:mm'));
      }
    };

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
        {alerts.map((alert, index) => (
          <WarningAlert key={index} message={alert} />
        ))}
        <Col sm={24} xl={11}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text strong>{getActionTypeName(action_type)}</Text>
            </Col>
            <Col span={24}>
              <Text>{helpText}</Text>
            </Col>
          </Row>
        </Col>
        <Col sm={0} xl={2} />
        <Col sm={24} xl={11}>
          {isFixedTime ? (
            <div className="yclients-notification-settings-create__fixed-time-pickers">
              <Controller
                control={control}
                name="visit_day_offset"
                defaultValue={state[fixedOffsetsStateKey][0].value}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="ant-custom-select"
                    style={{ width: '100%', maxWidth: 320 }}
                    popupClassName="ant-custom-select-dropdown"
                    options={state[fixedOffsetsStateKey]}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="fixed_time"
                defaultValue={state['default_fixed_time_value']}
                render={({ field: { onChange, value, name } }) => (
                  <TimePicker
                    name={name}
                    allowClear={false}
                    format="HH:mm"
                    placeholder=""
                    style={{ minWidth: 128, maxWidth: 128 }}
                    className="ant-custom-input"
                    popupClassName="ant-custom-picker-dropdown"
                    clearIcon={false}
                    showNow={false}
                    value={moment(value, 'HH:mm', true)}
                    onSelect={handleFixedTimeChange(onChange)}
                  />
                )}
              />
            </div>
          ) : (
            <Controller
              control={control}
              name="visit_time_offset"
              defaultValue={state[offsetsStateKey][0].value}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="ant-custom-select"
                  style={{ width: '100%', maxWidth: 320 }}
                  popupClassName="ant-custom-select-dropdown"
                  options={state[offsetsStateKey]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          )}

          <Col span={24} className="yclients-notification-settings-create__fixed-time">
            <div className="yclients-notification-settings-create__fixed-time-switch">
              <Controller
                control={control}
                name="selected_fixed_time"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Switch id="selected_fixed_time" checked={value} onChange={onChange} />
                )}
              />
              <div className="yclients-notification-settings-create__fixed-time-switch_label">
                <label htmlFor="selected_fixed_time">{fixedTimeText}</label>
                <Text>{isFixedTime ? fixedTimeOnText : fixedTimeOffText}</Text>
              </div>
            </div>
            {isFixedTime && (
              <div className="yclients-notification-settings-create__fixed-time-duplicates">
                <Controller
                  defaultValue={false}
                  control={control}
                  name="without_duplicates"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox id="without_duplicates" checked={value} onChange={onChange}>
                      {withoutDuplicatesText}
                    </Checkbox>
                  )}
                />
              </div>
            )}
          </Col>
        </Col>
      </Row>
    );
  },
});

export default createVisitFixedTimeOffsetStep;
