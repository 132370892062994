import React from 'react';
import { Skeleton } from 'antd';
import { locale } from '@/translator';

const TextPreview = ({ sender_name, text, attachmentUrl, shortUrl, isLoading, skeletonHeight }) => {
  const shortLinksInText = (text, shortUrl) => {
    let urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    if (text && shortUrl) {
      return text.replace(urlRegex, 'p-sms.ru/testme');
    } else {
      return text;
    }
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div className={`sms_presenter sms_presenter-${locale()}`}>
        <div className="sms_presenter">
          <div id="sms_presenter_avatar" className="sms_presenter-avatar">
            {sender_name.charAt(0)}
          </div>
          <div id="sms_presenter_header" className="sms_presenter-header">
            {sender_name}
          </div>
          <div id="sms_presenter_message" style={{ width: '100%' }} className="sms_presenter-message">
            <Skeleton loading={!text || isLoading} title={false} avatar={false} active paragraph={{ rows: skeletonHeight }}>
              {attachmentUrl && <img className="sms_presenter-message-image" src={attachmentUrl} />}
              <div dangerouslySetInnerHTML={{ __html: shortLinksInText(text, shortUrl) }} />
            </Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextPreview;
