import translator from "@/translator";
import React, { useState, useEffect } from "react";
import { Form, message, Spin, Space } from "antd";
import axios from "axios";
import BackButton from "@/note_office/shared/BackButton";
import ContactAvatar from "@/note_office/shared/ContactAvatar";
import ContactForm from "./components/ContactForm";
import Helper from "@/helper";

const t = translator("note_office.views.contacts.edit_view.");

const EditView = ({ contactsData }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const csrfToken = Helper.getCSRFToken();

  useEffect(() => {
    if (contactsData) {
      form.setFieldsValue({
        name: contactsData.name,
        phone: contactsData.phone,
        telegram: contactsData.telegram,
        instagram: contactsData.instagram,
        email: contactsData.email,
      });
    }
    setLoading(false);
  }, [contactsData]);

  const onFinish = async (values) => {
    setSaving(true);

    try {
      const response = await axios.put(
        contactsData.update_contact_url,
        { customer_contact: values },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
          },
        },
      );
      message.success(t("success_message"));
      window.location.href = response.data.redirect_path;
    } catch (error) {
      if (error.response?.data?.errors) {
        form.setFields(
          Object.entries(error.response.data.errors).map(([field, messages]) => ({
            name: field,
            errors: messages,
          })),
        );
      } else {
        message.error(t("error_message"));
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="wrapper overflow">
      {loading ? (
        <Spin size="large" className="loader" />
      ) : (
        <>
          <BackButton link={contactsData.show_contact_url} />

          <ContactAvatar />
          <ContactForm form={form} loading={loading} saving={saving} onFinish={onFinish} />
        </>
      )}
    </div>
  );
};

export default EditView;
