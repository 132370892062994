import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default () => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="7.2" r="3.2" fill="#FFC917" />
        <ellipse cx="12" cy="15.9998" rx="5.6" ry="3.2" fill="#FFC917" />
      </svg>
    </SVGUniqueID>
  );
};
