import React, { useState } from 'react';
import SelectorView from '../../components/SelectorView';
import SelectorInfo from './SelectorInfo';
import axios from 'axios';
import { FooterButtons } from './FooterButtons';
import { Button, Modal } from 'antd';

import translator from '@/translator';
const t = translator('front_office.views.integrations.routing_selector.');

interface Props {
  routingList: [];
  currentRoutingSet: [];
  dispatchingRouteUrl: string;
  forwardStep?: string;
  backwardStep?: string;
  setStep?: (arg: string) => void;
  hasTelegramBot: boolean;
  hasTdLib: boolean;
  editTelegramBotsUrl: string;
  editTdLibSessionsUrl: string;
}
export default ({
  forwardStep,
  backwardStep,
  routingList,
  currentRoutingSet,
  dispatchingRouteUrl,
  setStep,
  hasTelegramBot,
  hasTdLib,
  editTelegramBotsUrl,
  editTdLibSessionsUrl,
}: Props) => {
  const activeChannels: [string, boolean][] = currentRoutingSet.map((channel) => [channel, true]);
  const [modalMessage, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const channelsWithStatus = routingList.reduce(
    (acc: [string, boolean][], channel: never): [string, boolean][] => {
      if (currentRoutingSet.includes(channel)) {
        return acc;
      }
      return [...acc, [channel, false]];
    },
    [...activeChannels],
  );

  const [orderedItems, setItems] = useState(channelsWithStatus);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const saveRoutingOrder = () => {
    const dispatchingRouteData = new FormData();
    const selectedChannels = orderedItems
      .filter(([, isActive]) => isActive)
      .map(([channel]) => channel)
      .join(',');

    dispatchingRouteData.append('dispatch[dispatch_routing]', selectedChannels);

    axios({
      method: 'PATCH',
      url: dispatchingRouteUrl,
      data: dispatchingRouteData,
      headers: {
        Accept: 'application/javascript',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).catch(() => {
      console.log('server error');
    });
  };
  const updateChannels = () => {
    const selectedChannels = orderedItems.filter(([, isActive]) => isActive);
    if (selectedChannels.length > 0) {
      saveRoutingOrder();
      if (setStep) {
        setStep(forwardStep);
      } else {
        window.go_to_step('action', 5);
        window.play_firework();
      }
    } else {
      setMessage(t('choose_routing'));
      showModal();
    }
  };
  const handleNextClick = () => {
    updateChannels();
  };

  const handleBackClick = () => {
    if (setStep) {
      setStep(backwardStep);
    } else {
      window.go_to_step('action', 3);
    }
  };

  return (
    <div className="telegram-flow_container">
      <div className="telegram-flow_container-left">
        <SelectorInfo
          forwardStep={forwardStep}
          backwardStep={backwardStep}
          orderedChannels={orderedItems}
          setStep={setStep}
        />
      </div>
      <div className="telegram-flow_container-right">
        <SelectorView
          channels={orderedItems}
          setChannelsOrder={setItems}
          title={t('perfect_current_order')}
          hasTelegramBot={hasTelegramBot}
          hasTdLib={hasTdLib}
          editTelegramBotsUrl={editTelegramBotsUrl}
          editTdLibSessionsUrl={editTdLibSessionsUrl}
        />
      </div>
      <FooterButtons
        forwardAction={handleNextClick}
        backwardAction={handleBackClick}
        className="telegram-flow_container-buttons"
      />
      <Modal
        open={isModalOpen}
        title={t('notify')}
        onCancel={handleCancel}
        footer={<Button onClick={handleCancel}>{t('ok')}</Button>}
      >
        <p>{modalMessage}</p>
      </Modal>
    </div>
  );
};
