import translator from "@/translator";
import React from "react";
import { Avatar, Typography, Card } from "antd";
import ProfileMenu from "./components/ProfileMenu";
import TariffCard from "@/note_office/shared/TariffCard";

const { Title, Text } = Typography;

const t = translator("note_office.views.profile.index_view.");

const getRandomColor = () => {
  const colors = ["#FFC107", "#03A9F4", "#8BC34A", "#FF5722", "#9C27B0", "#FF9800"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const IndexView = ({ profileData, avatar, tariffEndDate, balance }) => {
  return (
    <div className="wrapper wrapper__menu-layout overflow">
      <div className="profile__header">
        <Avatar
          size={80}
          src={avatar || undefined}
          className="profile__avatar"
          style={{ backgroundColor: avatar ? "transparent" : getRandomColor() }}
        >
          {!avatar && profileData.account_letter}
        </Avatar>
        <Title level={4} className="profile__name">
          {profileData.name || profileData.email}
        </Title>
      </div>

      <div className="profile__card">
        <TariffCard tariffEndDate={tariffEndDate} />
        <p type="secondary" className="profile__balance">
          {t("sms_balance")}: ${balance}
        </p>
      </div>

      <div className="profile__menu-container overflow">
        <ProfileMenu profileData={profileData} />
      </div>
    </div>
  );
};

export default IndexView;
