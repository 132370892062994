import React, { useState, FC } from 'react';
import { Container } from '@components/grid';
import { Row, Col } from 'antd';
import { Header } from '../Header';
import { Notification } from './Notification';
import { PhoneForm } from './PhoneForm';
import { CodeConfirmation } from './CodeConfirmation';
import { PasswordForm } from './PasswordForm';
import LastStep from './LastStep';
import RoutingSelector from '../RoutingSelector';

import translator from '@/translator';
const t = translator('front_office.views.integrations.telegram_tdlib.telegram_tdlib.');

interface Props {
  setVariant: React.Dispatch<React.SetStateAction<string>>;
  variantsStep: string;
  dispatchingRouteUrl: string;
  authorizedNumber: string;
  routingList: [];
  currentRoutingSet: [];
  hasTelegramBot: boolean;
  hasTdLib: boolean;
  editTelegramBotsUrl: string;
  editTdLibSessionsUrl: string;
  foreignCustomer: boolean;
}
export const TelegramTdLib: FC<Props> = ({
  setVariant,
  variantsStep,
  dispatchingRouteUrl,
  authorizedNumber,
  routingList,
  currentRoutingSet,
  hasTelegramBot,
  hasTdLib,
  editTelegramBotsUrl,
  editTdLibSessionsUrl,
  foreignCustomer,
}) => {
  const [step, setStep] = useState('notification');
  const [customerHasTdLib, setCustomerHasTdLib] = useState(hasTdLib);

  const currentComponent = {
    notification: {
      component: (
        <Notification
          setStep={setStep}
          backAction={setVariant}
          forwardStep="phoneForm"
          authorizedNumber={authorizedNumber}
          backwardStep={variantsStep}
          setCustomerHasTdLib={setCustomerHasTdLib}
        />
      ),
      stepNumber: 1,
    },
    phoneForm: {
      component: (
        <PhoneForm
          setStep={setStep}
          forwardStep="codeConfirmation"
          backwardStep="notification"
          routingSelectorStep="routingSelector"
          passwordStep="passwordForm"
          foreignCustomer={foreignCustomer}
          setCustomerHasTdLib={setCustomerHasTdLib}
        />
      ),
      stepNumber: 2,
    },
    codeConfirmation: {
      component: (
        <CodeConfirmation
          setStep={setStep}
          routingSelectorStep="routingSelector"
          backwardStep="phoneForm"
          passwordStep="passwordForm"
          setCustomerHasTdLib={setCustomerHasTdLib}
        />
      ),
      stepNumber: 3,
    },
    passwordForm: {
      component: (
        <PasswordForm
          setStep={setStep}
          forwardStep="routingSelector"
          backwardStep="codeConfirmation"
          setCustomerHasTdLib={setCustomerHasTdLib}
        />
      ),
      stepNumber: 4,
    },
    routingSelector: {
      component: (
        <RoutingSelector
          routingList={routingList}
          currentRoutingSet={currentRoutingSet}
          dispatchingRouteUrl={dispatchingRouteUrl}
          setStep={setStep}
          editTdLibSessionsUrl={editTdLibSessionsUrl}
          editTelegramBotsUrl={editTelegramBotsUrl}
          forwardStep="lastStep"
          backwardStep="passwordForm"
          hasTdLib={customerHasTdLib}
          hasTelegramBot={hasTelegramBot}
        />
      ),
      stepNumber: 5,
    },
    lastStep: {
      component: <LastStep />,
    },
  };
  const { component, stepNumber } = currentComponent[step];

  return (
    <Container className="telegram-flow">
      {stepNumber && (
        <Row justify="center">
          <Col>
            <Header stepNumber={stepNumber} stepsQuantity={5} text={t('connect_telegram_number')} />
          </Col>
        </Row>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column">
        {component}
      </div>
    </Container>
  );
};
