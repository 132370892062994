import React from 'react';

import translator from '@/translator';
const t = translator('front_office.views.integrations.header.');

export const Header = ({ stepNumber, stepsQuantity, text }) => (
  <div className="telegram-flow_header">
    <h1>{text}</h1>
    <p>{t('step_number_of', { number: stepNumber, quantity: stepsQuantity })}</p>
  </div>
);
