import React from 'react';
import { locale } from '@/translator';

const SmsPresenter = ({ sender_name, text, imageFile }) => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div className={`sms_presenter sms_presenter-${locale()}`}>
        <div className="sms_presenter">
          <div id="sms_presenter_avatar" className="sms_presenter-avatar">
            {sender_name.charAt(0)}
          </div>
          <div id="sms_presenter_header" className="sms_presenter-header">
            {sender_name}
          </div>
          <div id="sms_presenter_message" className="sms_presenter-message">
            {imageFile && (
              <img className="sms_presenter-message-image" src={URL.createObjectURL(imageFile)} />
            )}
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsPresenter;
