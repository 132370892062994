import { Card } from "antd";
import React from "react";
import translator from "@/translator";

const t = translator("note_office.shared.tariff_card.");

const TariffCard = ({ tariffEndDate }) => {
  return (
    <Card className="tariff-card">
      <div className="tariff-card__header">
        <p type="secondary" className="tariff-card__text">
          {t("tariff")}
        </p>
        <span className="tariff-card__badge">
          {t("active_until")} {tariffEndDate}
        </span>
      </div>
      <h2 className="tariff-card__title">{t("free_plan")}</h2>
    </Card>
  );
};

export default TariffCard;
