import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Input, Space, notification } from 'antd';
import axios from 'axios';
import Captcha from '@front_office/components/Captcha';

import translator from '@/translator';
const t = translator('front_office.views.registration.sign_up.confirmation_code.');

const ConfirmationCode = ({
  customer,
  setSection,
  setCustomerData,
  confirmationType,
  confimRouting,
  projectScopeHeader,
}) => {
  const skipCaptcha = new URLSearchParams(window.location.search).get('skip_captcha');
  const [disableResend, setDisableResend] = useState(skipCaptcha != 'true');
  const forRegistration = confirmationType == 'register_code';
  const nextSection = forRegistration ? 'email' : 'reset_password';
  const codeEndpoint = forRegistration ? customer.phone : customer.login;
  const [form] = Form.useForm();
  const NotificationDescription = ({ text }) => <span dangerouslySetInnerHTML={{ __html: text }} />;

  const captchaRef = useRef(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const onChangeCaptcha = () => {
    setCaptchaToken(captchaRef.current.getValue());
    setShowCaptcha(false);
    setDisableResend(false);
  };

  const smsTimer = () => {
    const timerBlock = document.getElementById('update_sms');

    let seconds = 60;
    const timerId = setInterval(function () {
      timerBlock.innerHTML = t('resend_button_seconds', { seconds: --seconds });
    }, 1000);

    setTimeout(function () {
      clearInterval(timerId);
      setShowCaptcha(!showCaptcha);
      timerBlock.innerHTML = t('resend_button');
    }, seconds * 1000);
  };

  const onClickResendSms = () => {
    setDisableResend(skipCaptcha != 'true');

    const data = {
      confirmation: {
        customer_id: customer.id,
        confirmation_type: confirmationType,
        confimation_routing: confimRouting,
      },
      'smart-token': captchaToken,
      skip_captcha: skipCaptcha,
    };

    axios({
      method: 'post',
      url: '/front_office/confirmation_codes/resend',
      data: data,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        smsTimer();
      } else {
        data.errors.forEach((error) => {
          return notification['error']({
            description: <NotificationDescription text={error} />,
            placement: 'top',
          });
        });

        captchaRef.current.reset();
      }
    });
  };

  const onFinish = (values) => {
    axios({
      method: 'post',
      url: '/front_office/confirmation_codes/check',
      data: values,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        const customer = Object.assign(data.customer, { code: values.confirmation.code });
        setCustomerData(customer);
        setSection(nextSection);
      } else {
        data.errors.forEach((error) => {
          return notification['error']({
            description: <NotificationDescription text={error} />,
            placement: 'top',
          });
        });
      }
    });
  };

  const skipPhoneWhenForeignCustomer = () => {
    if (nextSection == 'email' && projectScopeHeader != 0) {
      form.submit();
    }
  };

  useEffect(() => {
    skipPhoneWhenForeignCustomer();
    smsTimer();
  }, []);

  return (
    <>
      <div>
        <p>
          <span dangerouslySetInnerHTML={{ __html: t('code_sent_to') }}></span>{' '}
          <b>{codeEndpoint}</b>
        </p>
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.List name="confirmation">
          {(_fields) => (
            <>
              <Form.Item hidden name="customer_id" initialValue={customer.id}>
                <Input />
              </Form.Item>
              <Form.Item hidden name="confirmation_type" initialValue={confirmationType}>
                <Input />
              </Form.Item>
              <Form.Item label={t('enter_code_label')} name="code">
                <Input size="large" />
              </Form.Item>
              <Form.Item>
                <Space direction="vertical" style={{ width: '100%' }}>
                  {showCaptcha && <Captcha ref={captchaRef} onChange={onChangeCaptcha} />}

                  <Button block={true} type="primary" htmlType="submit">
                    {t('confirm_button')}
                  </Button>
                  <Button
                    id="update_sms"
                    block={true}
                    type="default"
                    disabled={disableResend}
                    onClick={() => onClickResendSms()}
                    htmlType="button"
                  >
                    {t('resend_button_seconds', { seconds: 60 })}
                  </Button>
                  {forRegistration && (
                    <Button
                      block={true}
                      type="text"
                      htmlType="button"
                      onClick={() => setSection('change_phone')}
                    >
                      {t('change_number_button')}
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
};

export default ConfirmationCode;
