import React, { useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import ClientsTable from './ClientsTable';
import FinishStep from './FinishStep';
import Form from './Form';
import { StateContext } from './State';

const IndexView = (props) => {
  const [state, setState] = useState({
    ...props,
    selectedClientIds: [],
  });

  const updateState = (newState) => {
    setState((state) => ({ ...state, ...newState }));
  };

  return (
    <StateContext.Provider value={{ state, updateState }}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<ClientsTable />}></Route>
          <Route path="/new" element={<Form />} />
          <Route path="/finish" element={<FinishStep />} />
        </Routes>
      </HashRouter>
    </StateContext.Provider>
  );
};

export default IndexView;
