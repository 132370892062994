import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default () => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6 20H10.4C7.38301 20 5.87452 20 4.93726 19.0627C4 18.1255 4 16.617 4 13.6V10.4C4 7.38301 4 5.87452 4.93726 4.93726C5.87452 4 7.39096 4 10.4238 4C10.9086 4 11.2971 4 11.624 4.01333C11.6132 4.07727 11.6076 4.1425 11.6074 4.20845L11.6 6.47597C11.5999 7.35366 11.5999 8.12931 11.6839 8.75453C11.775 9.43222 11.9842 10.1098 12.5373 10.6628C13.0903 11.2158 13.7679 11.425 14.4456 11.5162C15.0708 11.6002 15.8464 11.6002 16.7241 11.6001L16.8 11.6001H19.9659C20 12.0275 20 12.5521 20 13.2503V13.6C20 16.617 20 18.1255 19.0627 19.0627C18.1255 20 16.617 20 13.6 20ZM6.6 14C6.6 13.6686 6.86863 13.4 7.2 13.4H13.6C13.9314 13.4 14.2 13.6686 14.2 14C14.2 14.3314 13.9314 14.6 13.6 14.6H7.2C6.86863 14.6 6.6 14.3314 6.6 14ZM6.6 16.8C6.6 16.4686 6.86863 16.2 7.2 16.2H11.6C11.9314 16.2 12.2 16.4686 12.2 16.8C12.2 17.1314 11.9314 17.4 11.6 17.4H7.2C6.86863 17.4 6.6 17.1314 6.6 16.8Z"
          fill="#FFC917"
        />
        <path
          d="M17.8814 8.49332L14.7144 5.643C13.8121 4.83094 13.3609 4.42491 12.8074 4.2125L12.8 6.40009C12.8 8.2857 12.8 9.22851 13.3858 9.8143C13.9716 10.4001 14.9144 10.4001 16.8 10.4001H19.6641C19.374 9.83671 18.8547 9.36931 17.8814 8.49332Z"
          fill="#FFC917"
        />
      </svg>
    </SVGUniqueID>
  );
};
