import React, { useRef, useEffect } from 'react';
import 'emoji-picker-element';
import ru from 'emoji-picker-element/i18n/ru_RU';
import { locale } from '@/translator';

const EmojiPicker = ({ onClick, stylesStr }) => {
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    const emojiPicker = emojiPickerRef.current;
    if (locale() === 'ru') {
      emojiPicker.i18n = ru;
      emojiPicker.locale = 'ru_RU';
    }

    if (stylesStr) {
      const style = document.createElement('style');
      style.textContent = stylesStr;
      emojiPicker.shadowRoot.appendChild(style);
    }

    emojiPicker.addEventListener('emoji-click', (e) => {
      onClick(e);
    });
  }, []);

  return React.createElement('emoji-picker', { class: 'light', ref: emojiPickerRef });
};

export default EmojiPicker;
