import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default () => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.74464 11.3604C4.80198 7.39194 8.06098 4.2002 12.0631 4.2002C14.7099 4.2002 17.0296 5.59561 18.3147 7.68596C18.4883 7.96825 18.4001 8.33778 18.1178 8.51133C17.8355 8.68488 17.466 8.59672 17.2925 8.31443C16.2182 6.56695 14.2787 5.4002 12.0631 5.4002C8.71563 5.4002 6.003 8.06235 5.94483 11.3587L6.26513 11.0409C6.50036 10.8075 6.88026 10.809 7.11365 11.0443C7.34704 11.2795 7.34555 11.6594 7.11032 11.8928L5.76647 13.2261C5.53254 13.4582 5.15521 13.4582 4.92128 13.2261L3.57743 11.8928C3.3422 11.6594 3.3407 11.2795 3.5741 11.0443C3.80749 10.809 4.18739 10.8075 4.42262 11.0409L4.74464 11.3604ZM18.2292 10.7735C18.4629 10.5424 18.839 10.5424 19.0727 10.7735L20.4217 12.1068C20.6574 12.3397 20.6596 12.7196 20.4267 12.9553C20.1937 13.191 19.8138 13.1932 19.5782 12.9603L19.2502 12.6361C19.1947 16.6085 15.9214 19.8002 11.906 19.8002C9.25106 19.8002 6.92307 18.4058 5.63298 16.3153C5.45895 16.0333 5.54648 15.6636 5.82848 15.4896C6.11047 15.3156 6.48015 15.4031 6.65418 15.6851C7.7325 17.4324 9.68002 18.6002 11.906 18.6002C15.271 18.6002 17.9937 15.9339 18.0501 12.6377L17.7237 12.9603C17.4881 13.1932 17.1082 13.191 16.8752 12.9553C16.6423 12.7196 16.6445 12.3397 16.8802 12.1068L18.2292 10.7735Z"
          fill="#FFC917"
        />
      </svg>
    </SVGUniqueID>
  );
};
