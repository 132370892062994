import React from "react";
import { Pagination } from "antd";

const CustomPagination = ({ current, total, pageSize, onChange }) => {
  return (
    <Pagination
      current={current}
      total={total}
      pageSize={pageSize}
      onChange={onChange}
      className="pagination"
      showSizeChanger={false}
      showLessItems
    />
  );
};

export default CustomPagination;
