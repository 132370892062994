import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNotificationsState } from './State';
import { useFormContext } from 'react-hook-form';
import {
  Popconfirm,
  Space,
  Alert,
  Skeleton,
  Button,
  Upload,
  Drawer,
  Typography,
  Grid,
  Progress,
  notification,
} from 'antd';
import { PictureFilled, DeleteOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
const { Text } = Typography;
import translator from '@/translator';
import classNames from 'classnames';
const t = translator('front_office.views.yclients_notification_settings.');

const showNotification = (type, config) => {
  const defaultConfig = {
    className: 'ant-custom-notification',
  };

  notification[type]({ ...defaultConfig, ...config });
};

const GalleryDrawer = ({
  open,
  onClose,
  imagesList,
  maxImagesCount,
  isGalleryLoading,
  onSelect,
  onRemove,
  appendImage,
}) => {
  const screens = Grid.useBreakpoint();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [isMaxImgCount, setIsMaxImgCount] = useState(false);
  const [fileToRemove, setFileToRemove] = useState(null);
  const [fileRemoveQuestion, setFileRemoveQuestion] = useState('');
  const [isPopconfirmLoading, setIsPopconfirmLoading] = useState(false);
  const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(false);

  const handleFileChange = (info) => {
    switch (info.file.status) {
      case 'uploading':
        setIsUploading(true);
        setUploadingProgress(info.file.percent);
        return;

      case 'error':
        setIsUploading(false);
        showNotification('error', {
          description: t('something_went_wrong'),
          message: t('create.error'),
        });
        return;

      case 'success':
        setIsUploading(false);
        return;

      case 'done':
        setIsUploading(false);
        const data = info.file.response;

        if (data.success) {
          appendImage({
            uid: data.attachment_id,
            url: data.file_access_url,
            name: info.file.name,
            status: 'done',
          });
        } else {
          data.errors.file.forEach((error) =>
            showNotification('error', { description: error, message: t('create.error') }),
          );
        }
        return;
    }
  };

  const handleRemoveOpenChange = (open, _e) => {
    setIsPopconfirmOpen(open);
    if (!open) {
      setFileRemoveQuestion('');
      setFileToRemove(null);
    }
  };

  const checkBeforeRemove = (file) => {
    setIsPopconfirmLoading(true);
    return axios({
      method: 'GET',
      url: `/front_office/attachments/${file.uid}/check_destroy`,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(({ data }) => {
        if (data.success) {
          setFileRemoveQuestion(
            t(
              data.is_assigned
                ? 'gallery.remove_when_assigned'
                : 'gallery.remove_when_not_assigned',
            ),
          );
        }
      })
      .finally(() => setIsPopconfirmLoading(false));
  };

  useEffect(() => {
    if (fileToRemove && isPopconfirmOpen) {
      checkBeforeRemove(fileToRemove);
    }
  }, [fileToRemove, isPopconfirmOpen]);

  useEffect(() => {
    setIsMaxImgCount(imagesList.length >= maxImagesCount);
  }, [imagesList, maxImagesCount]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      className="ant-custom-drawer"
      width={screens.md ? 508 : 'calc(100% - 12px)'}
      title={
        <div className="yclients-notification-settings-create__attachment-gallery-title">
          <div>
            <Text>{t('gallery.choose_one_img')}</Text>
          </div>
          <div>
            <Text type="secondary">{t('gallery.img_specs')}</Text>
          </div>
        </div>
      }
      footer={
        <div className="yclients-notification-settings-create__attachment-gallery-footer">
          <div className="yclients-notification-settings-create__attachment-gallery-footer-counter">
            <Skeleton
              loading={isGalleryLoading}
              round
              title={false}
              paragraph={{ rows: 1, width: 72 }}
              active
              className="ant-custom-skeleton"
            >
              <PictureFilled style={{ opacity: 0.45, fontSize: 16 }} />
              {t('gallery.counter', { x: imagesList.length, max: maxImagesCount })}
            </Skeleton>
          </div>
          {isGalleryLoading ? (
            <Skeleton.Button
              style={{ width: 222 }}
              shape="round"
              active
              className="ant-custom-skeleton"
            />
          ) : (
            <div
              className={classNames({
                'yclients-notification-settings-create__attachment-gallery-footer-actions': true,
                'yclients-notification-settings-create__attachment-gallery-footer-actions_uploading':
                  isUploading,
              })}
            >
              <div className="yclients-notification-settings-create__attachment-gallery-footer-progress">
                <Progress showInfo={false} percent={uploadingProgress} size="small" />
                {t('gallery.loading')}
              </div>
              <Upload
                className="yclients-notification-settings-create__attachment-gallery-footer-btn"
                accept="image/jpeg,image/png"
                action="/front_office/attachments"
                headers={{
                  'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
                }}
                name="attachment[file]"
                showUploadList={false}
                maxCount={1}
                onChange={handleFileChange}
              >
                <Button
                  disabled={isUploading || isMaxImgCount}
                  className="ant-custom-btn"
                  type="dashed"
                  icon={<UploadOutlined />}
                >
                  {t('gallery.upload_img')}
                </Button>
              </Upload>
            </div>
          )}
        </div>
      }
    >
      {isGalleryLoading ? (
        <div className="yclients-notification-settings-create__attachment-gallery-skeleton">
          <Skeleton.Image className="ant-custom-skeleton" active />
          <Skeleton.Image className="ant-custom-skeleton" active />
          <Skeleton.Image className="ant-custom-skeleton" active />
          <Skeleton.Image className="ant-custom-skeleton" active />
          <Skeleton.Image className="ant-custom-skeleton" active />
          <Skeleton.Image className="ant-custom-skeleton" active />
          <Skeleton.Image className="ant-custom-skeleton" active />
        </div>
      ) : imagesList.length === 0 ? (
        <div className="yclients-notification-settings-create__attachment-gallery-empty">
          {t('gallery.empty')}
        </div>
      ) : (
        <Space direction="vertical" size={12}>
          {isMaxImgCount && (
            <Alert
              className="ant-custom-alert"
              type="error"
              message={t('gallery.max_img_count', { max: maxImagesCount })}
            />
          )}
          <Upload
            className="ant-custom-upload"
            listType="picture-card"
            fileList={imagesList}
            onPreview={onSelect}
            onRemove={(file) => setFileToRemove(file)}
            showUploadList={{
              showPreviewIcon: true,
              showRemoveIcon: true,
              previewIcon: <PlusOutlined title="" style={{ color: 'white', fontSize: 20 }} />,
              removeIcon: (
                <Popconfirm
                  onOpenChange={handleRemoveOpenChange}
                  onConfirm={() => onRemove(fileToRemove)}
                  popupClassName="ant-custom-popconfirm"
                  cancelText={t('gallery.keep_img')}
                  cancelButtonProps={{ className: 'ant-custom-btn', disabled: isPopconfirmLoading }}
                  okText={t('gallery.remove_img')}
                  okButtonProps={{ className: 'ant-custom-btn', disabled: isPopconfirmLoading }}
                  title={fileRemoveQuestion}
                  placement="bottomRight"
                >
                  <DeleteOutlined title="" style={{ color: 'white', fontSize: 24 }} />
                </Popconfirm>
              ),
            }}
          />
        </Space>
      )}
    </Drawer>
  );
};

const AttachmentUpload = () => {
  const [isGalleryLoading, setIsGalleryLoading] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [maxImagesCount, setMaxImgCount] = useState(50);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState([]);
  const { updateState } = useNotificationsState();
  const { register, watch, setValue } = useFormContext();
  const currentImageUid = watch('attachment_id');

  const fetchGallery = () => {
    setIsGalleryLoading(true);
    axios({
      method: 'GET',
      url: '/front_office/attachments',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(({ data }) => {
        setImagesList(data.attachments);
        setMaxImgCount(data.image_max_count);
      })
      .finally(() => setIsGalleryLoading(false));
  };

  const selectImage = (file) => {
    setValue('attachment_id', file.uid);
  };

  const appendImage = (file) => {
    setImagesList((currentList) => [...currentList, file]);
  };

  const removeImage = (file) => {
    return axios({
      method: 'DELETE',
      url: `/front_office/attachments/${file.uid}`,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
    }).then(({ data }) => {
      if (data.success) {
        setImagesList(imagesList.filter((f) => f.uid !== data.uid));
      }
    });
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  useEffect(() => {
    const file = imagesList.find((f) => f.uid === currentImageUid);
    setCurrentImage(file ? [file] : []);
    updateState({ currentFileUrl: file?.url });
    setValue('attachment_id', file?.uid || null);
  }, [currentImageUid, imagesList]);

  const uploadProps = {
    className: 'ant-custom-upload',
    listType: 'picture-card',
    openFileDialogOnClick: false,
    maxCount: 1,
  };

  const emptyUpload = (
    <Upload {...uploadProps} fileList={[]} showUploadList={false}>
      <button
        className="yclients-notification-settings-create__attachment-upload-gallery-btn"
        type="button"
        onClick={() => setIsGalleryOpen(true)}
      >
        <div>
          <PlusOutlined style={{ fontSize: 32 }} />
        </div>
        <div>{t('gallery.add_img')}</div>
      </button>
    </Upload>
  );

  const filledUpload = (
    <Upload
      {...uploadProps}
      fileList={currentImage}
      onRemove={() => setValue('attachment_id', null)}
      showUploadList={{
        showPreviewIcon: false,
        showRemoveIcon: true,
        removeIcon: <DeleteOutlined title="" style={{ color: 'white', fontSize: 24 }} />,
      }}
    />
  );

  return (
    <div className="yclients-notification-settings-create__attachment-upload">
      <input type="hidden" {...register('attachment_id')} />

      <GalleryDrawer
        open={isGalleryOpen}
        onClose={() => setIsGalleryOpen(false)}
        imagesList={imagesList}
        maxImagesCount={maxImagesCount}
        appendImage={appendImage}
        onSelect={selectImage}
        onRemove={removeImage}
        isGalleryLoading={isGalleryLoading}
      />

      <div>
        <Text>{t('gallery.notification_img')}</Text>
        <Text type="secondary"> {t('gallery.only_one_img')}</Text>
      </div>

      {currentImage.length === 0 ? emptyUpload : filledUpload}
    </div>
  );
};

export default AttachmentUpload;
