import translator from "@/translator";
import React, { useState } from "react";
import { Alert, Button, Form, Space } from "antd";
import SuccessView from "@/note_office/shared/SuccessView";
import ImportFailureView from "./components/ImportFailureView";
import BackButton from "@/note_office/shared/BackButton";
import Helper from "@/helper";

const t = translator("note_office.views.contacts.import_view.");
const f = translator("note_office.views.contacts.import_success_view.");

const ImportView = ({ contactsData }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [view, setView] = useState("import");
  const [contactsCount, setContactsCount] = useState(0);
  const csrfToken = Helper.getCSRFToken();

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      form.setFields([{ name: "file", errors: [] }]);
    }
  };

  const handleImport = async () => {
    if (!file) {
      form.setFields([{ name: "file", errors: [t("errors.no_file")] }]);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(contactsData.import_file_url, {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || t("errors.server"));
      }

      setContactsCount(data.created);

      form.resetFields();
      setFile(null);

      setView("success");
      setTimeout(() => setView("import"), 3000);

      const fileInput = document.getElementById("fileUpload");
      if (fileInput) {
        fileInput.value = "";
      }
    } catch (error) {
      setView("failure");
    } finally {
      setLoading(false);
    }
  };

  const successText = (
    <>
      {f("contacts.title")} <span style={{ color: "#EA9308" }}>{contactsCount}</span>{" "}
      {f("contacts", { count: contactsCount })} {f("contacts.title_tail")}
    </>
  );

  return (
    <div className="wrapper overflow">
      <BackButton link={contactsData.contacts_url} />

      {view === "success" && <SuccessView text={successText} />}
      {view === "failure" && <ImportFailureView contactsData={contactsData} />}
      {view === "import" && (
        <>
          <h2 className="contacts-import__title">{t("title")}</h2>
          <p className="contacts-import__text">{t("description")}</p>

          <Form
            form={form}
            layout="vertical"
            className="wrapper_form wrapper_form__with-button overflow"
          >
            <div>
              <p className="contacts-import__label">{t("file_label")}</p>
              {file && (
                <p className="contacts-import__file-name">
                  {t("file_selected", { name: file.name })}
                </p>
              )}

              <input
                type="file"
                id="fileUpload"
                accept=".csv"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <Form.Item>
                <Button
                  type="primary"
                  className="button-base button-secondary"
                  block
                  onClick={() => document.getElementById("fileUpload")?.click()}
                >
                  {t("upload")}
                </Button>
              </Form.Item>

              <Alert
                message={
                  <span>
                    {t("instructions")} <span className="contacts-import__bold">Name</span>,{" "}
                    {t("and_phone")} <span className="contacts-import__bold">Phone</span>
                  </span>
                }
                type="info"
                showIcon
                className="contacts-import__alert"
              />
            </div>
            <Form.Item style={{ marginBottom: "20px" }}>
              <Button
                type="primary"
                className="button-base"
                block
                onClick={handleImport}
                disabled={!file}
                loading={loading}
              >
                {t("import")}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default ImportView;
