import React from 'react';
import { useState } from 'react';
import { Row, Cell } from '@components/grid';
import { InfoPane } from '../InfoPane';
import Button from '@components/Button';
import { Form, Input } from 'antd';
import classNames from 'classnames';
import VideoView from '../VideoView';

import translator, { locale_for_video } from '@/translator';
const t = translator('front_office.views.integrations.telegram_bot.connecting_bot.');

export default ({ setStep, telegramBotsUrl, customerHasBot, setCustomerHasBot, botName }) => {
  const [inputStatus, setInputStatus] = useState({});
  const [forwardButtonDisabled, setForwardButtonDisabled] = useState(false);
  const [telegramBotName, setTelegramBotName] = useState(botName);

  const onFinish = ({ telegramToken }) => {
    customerHasBot ? setStep('botInfo') : sendApiToken(telegramToken);
  };

  const setError = (errorMessage) => {
    setInputStatus({
      validateStatus: 'error',
      help: errorMessage,
    });

    setForwardButtonDisabled(true);
  };

  const processBody = (body) => {
    if (body.errors) {
      const [error] = body.errors;
      setError(error);
    }
    if (body.success) {
      const { newBotName } = body.success;
      setTelegramBotName(newBotName);
      setCustomerHasBot(true);
    }
  };

  const sendApiToken = (apiToken = '') => {
    const telegramData = new FormData();
    telegramData.append('telegram_bot[api_token]', apiToken);
    telegramData.append('telegram_bot[bot_update]', 'false');

    const queryOptions = {
      method: 'PATCH',
      headers: {
        Accept: 'text/javascript',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      body: telegramData,
    };

    const getToken = async (url, options = {}) => {
      const response = await fetch(url, options);
      const body = await response.json();
      return body;
    };

    getToken(telegramBotsUrl, queryOptions)
      .then((body) => processBody(body))
      .catch((error) => {
        console.error('Cant fetch', error);
        setError(t('server_error'));
      });
  };

  const unbindBot = () => {
    fetch(telegramBotsUrl, {
      method: 'DELETE',
      headers: {
        Accept: 'text/html',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      })
      .then(() => {
        setTelegramBotName('');
        setCustomerHasBot(false);
      })
      .catch((error) => {
        console.error('Could not fetch', error);
      });
  };

  const handleInputChange = () => {
    setInputStatus({});
    setForwardButtonDisabled(false);
  };

  const renderBotDetails = () => {
    return (
      <InfoPane title={customerHasBot ? t('bot_selection_title') : t('bot_connection_title')}>
        {customerHasBot ? (
          <>
            <p>
              {t('you_have_bot')}{' '}
              <span className="telegram-flow_bold-text">@{telegramBotName}</span>.{' '}
              {t('bot_connected_info')}
            </p>
            <Button
              className="btn_v2-secondary telegram-flow_unbind_button"
              text={t('unbind_button_text')}
              onClick={unbindBot}
            />
          </>
        ) : (
          <p>{t('enter_api_token')}</p>
        )}
      </InfoPane>
    );
  };
  return (
    <div className="telegram-flow_split-pane">
      <VideoView link={`/step3_${locale_for_video()}.mp4`} />
      <div className="telegram-flow_split-pane_right">
        <Row>
          <Cell>{renderBotDetails()}</Cell>
        </Row>
        <Form onFinish={onFinish}>
          {!customerHasBot && (
            <Row>
              <Cell>
                <Form.Item
                  {...inputStatus}
                  name="telegramToken"
                  className="telegram-flow_bottom-spacing"
                >
                  <Input className="telegram-flow_input" onChange={handleInputChange} />
                </Form.Item>
              </Cell>
            </Row>
          )}
          <Row>
            <Cell>
              <Form.Item>
                <div className="telegram-flow_footer">
                  <Button
                    className="btn_v2-secondary"
                    text={t('back_button_text')}
                    onClick={() => setStep('botCreation')}
                  />
                  <Button
                    className={classNames(
                      'btn_v2-primary',
                      forwardButtonDisabled ? 'btn_v2-primary_disabled' : null,
                    )}
                    disabled={forwardButtonDisabled}
                    text={t('forward_button_text')}
                  />
                </div>
              </Form.Item>
            </Cell>
          </Row>
        </Form>
      </div>
    </div>
  );
};
