import React from 'react';
import { Card, Button, Typography, Space } from 'antd';
import { LeftOutlined, CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const IndexView = () => {
  const bonusCount = 15;
  const referralLink = 'Chategy.io/registration';

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
  };

  return (
    <div style={{ padding: '16px', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      {/* Header */}
      <Space style={{ marginBottom: '24px' }}>
        <a href="/note_office/profile">
          <LeftOutlined />
        </a>
        <Text strong>Реферальная программа</Text>
      </Space>

      {/* Bonus Card */}
      <Card
        style={{
          backgroundColor: '#e8a44d',
          color: 'white',
          marginBottom: '16px',
          borderRadius: '12px',
        }}
      >
        <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
          <div>
            <Text style={{ color: 'white' }}>Количество бонусов</Text>
            <Title level={1} style={{ color: 'white', margin: 0 }}>
              {bonusCount}
            </Title>
          </div>
          <InfoCircleOutlined style={{ color: 'white' }} />
        </Space>
      </Card>

      {/* Use Bonuses Button */}
      <Button
        type="text"
        block
        style={{
          marginBottom: '24px',
          color: '#e8a44d',
          fontWeight: 'bold',
        }}
      >
        Использовать бонусы
      </Button>

      {/* Share Text */}
      <Text strong style={{ display: 'block', marginBottom: '16px' }}>
        Поделитесь ссылкой с коллегами, чтобы получить бонус
      </Text>

      {/* Referral Link */}
      <Card
        size="small"
        style={{
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Text>{referralLink}</Text>
          <CopyOutlined onClick={handleCopyLink} style={{ color: '#e8a44d' }} />
        </Space>
      </Card>
    </div>
  );
};

export default IndexView;
