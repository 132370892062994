import React from "react";
import { SVGUniqueID } from "react-svg-unique-id";

export default (props) => {
  return (
    <SVGUniqueID>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path d="M25 10L5 10" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M22 15L8 15" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M19 20H11" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </SVGUniqueID>
  );
};
