import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row, Checkbox, Typography, Spin, message } from "antd";
import AdvancedSettings from "./AdvancedSettings";
import WarningAlert from "./WarningAlert";
import { useNotificationsState } from "./State";
import { useFormContext, Controller } from "react-hook-form";
import translator from "@/translator";
const t = translator("front_office.views.altegio_notification_settings.");
const { Text } = Typography;

const showMessage = (type, content, duration = 5) => {
  message[type]({ content, duration, key: "services_sync", className: "ant-custom-message" });
};

const LoadingWrapper = ({ loading, children }) => {
  return loading ? <Spin>{children}</Spin> : children;
};

const createServicesStep = ({ alerts = [], helpText = "" } = {}) => ({
  title: t("steps.services"),
  validation: ({ salon_service_id_and_title, filters = {} }) => {
    const {
      booking_cats_enabled,
      booking_cats,
      service_cats_enabled,
      service_cats,
      client_cats_enabled,
      client_cats,
      staff_cats_enabled,
      staff_cats,
    } = filters;
    return (
      (salon_service_id_and_title || []).length > 0 &&
      (!booking_cats_enabled || booking_cats?.length > 0) &&
      (!service_cats_enabled || service_cats?.length > 0) &&
      (!client_cats_enabled || client_cats?.length > 0) &&
      (!staff_cats_enabled || staff_cats?.length > 0)
    );
  },
  Component: ({ isVisible }) => {
    const { state, updateState } = useNotificationsState();
    const { control, watch, setValue } = useFormContext();
    const [allServicesSelected, setAllServicesSelected] = useState(false);
    const [previouslySelectedServices, setPreviouslySelectedServices] = useState([]);
    const [syncInProgress, setSyncInProgress] = useState(false);
    const selectedServices = watch("salon_service_id_and_title", []);

    const services = state.services[state.selectedSalon];
    const all_services = state.all_services_option;
    const all_services_action = state.all_services_action[state.selectedSalon];
    const options = [all_services, ...services];

    useEffect(() => {
      setAllServicesSelected(selectedServices.some((s) => s.startsWith("0$")));
    }, [selectedServices]);

    const syncServices = (e) => {
      e.preventDefault();
      setValue("salon_service_id_and_title", []);
      showMessage("loading", t("fields.services_sync_in_progress"));
      setSyncInProgress(true);
      axios({
        method: "POST",
        data: { salon_id: state.selectedSalon },
        url: state.sync_services_url,
        headers: {
          Accept: "application/javascript",
          "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
        },
      })
        .then(({ data }) => {
          if (data.success) {
            showMessage("success", t("fields.services_sync_success"));
            updateState({ services: data.services });
          } else {
            showMessage("error", data.errors.join(", "));
          }
        })
        .catch((error) => {
          if (error.response) {
            showMessage("error", error.response.data);
          } else if (error.request) {
            showMessage("error", t("create.network_error"));
          } else {
            showMessage("error", error.message);
          }
        })
        .finally(() => {
          setSyncInProgress(false);
        });
    };

    const handleServicesChange = (onChange) => (selectedServices) => {
      const [lastCheckedValue = ""] = selectedServices.slice(-1);
      // All services checked
      if (lastCheckedValue.startsWith("0$")) {
        onChange(options.map((s) => s.value));
        setPreviouslySelectedServices(selectedServices.slice(0, -1));
        // All services unchecked (was checked + something changed == 100% it was unchecked now)
      } else if (allServicesSelected) {
        onChange(previouslySelectedServices);
        // any other check
      } else {
        onChange(selectedServices);
      }
    };

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? "flex" : "none" }}>
        {all_services_action && (
          <WarningAlert
            message={<span dangerouslySetInnerHTML={{ __html: t("alerts.all_services") }} />}
          />
        )}
        {alerts.map((alert, index) => (
          <WarningAlert key={index} message={alert} />
        ))}
        <Col sm={24} xl={11}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text strong>{t("fields.services")}</Text>
            </Col>
            <Col span={24}>
              <Text>
                <span dangerouslySetInnerHTML={{ __html: helpText }}></span>
              </Text>
            </Col>
            <Col span={24}>
              <Text className="altegio-notification-settings-create__services-sync">
                {t("fields.services_sync_start")}
                <a href="#" onClick={syncServices}>
                  {t("fields.services_sync_link_text")}
                </a>
                {t("fields.services_sync_end")}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col sm={0} xl={2} />
        <Col sm={24} xl={11}>
          <Controller
            control={control}
            name="salon_service_id_and_title"
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <LoadingWrapper loading={syncInProgress}>
                <Checkbox.Group value={value} onChange={handleServicesChange(onChange)}>
                  <Row gutter={[0, 2]}>
                    {options.map(({ value, label }) => (
                      <Col span={24} key={value}>
                        <Checkbox
                          disabled={allServicesSelected && !value.startsWith("0$")}
                          value={value}
                        >
                          {label}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </LoadingWrapper>
            )}
          />
        </Col>
        <AdvancedSettings />
      </Row>
    );
  },
});

export default createServicesStep;
