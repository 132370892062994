import translator from "@/translator";
import React from "react";
import { Typography, Avatar, Button, Space, Form, Input, Select } from "antd";
import { EditOutlined, InstagramOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import BackButton from "@/note_office/shared/BackButton";
import PhoneInput from "react-phone-input-2";
import RedirectButton from "@/note_office/shared/RedirectButton";
import Telegram from "@/note_office/icons/Telegram";

const t = translator("note_office.views.contacts.show_view.");

const { Text, Title } = Typography;

const ShowView = ({ contactsData }) => {
  if (!contactsData) {
    return <Text>{t("contact_not_found")}</Text>;
  }

  return (
    <div className="wrapper overflow">
      <BackButton link={contactsData.contacts_url}>
        <Space size={10}>
          <RedirectButton
            url={contactsData.dialogs_url}
            icon={<MessageOutlined style={{ fontSize: "22px" }} />}
            type="text"
            className="contacts-show__btn"
          />
          <RedirectButton
            url={contactsData.edit_contact_url}
            icon={<EditOutlined style={{ fontSize: "22px" }} />}
            type="text"
            className="contacts-show__btn"
          />
        </Space>
      </BackButton>

      <div className="contacts-avatar contacts-show__avatar">
        <Avatar size={80} icon={<UserOutlined />} className="contacts-avatar__icon" />
        <Title level={3} className="contacts-show__name">
          {contactsData.name}
        </Title>
      </div>

      <Form layout="vertical" className="wrapper_form overflow">
        <Form.Item className="contacts-form__item contacts-form__item-disabled">
          <PhoneInput
            value={contactsData.phone || ""}
            enableSearch={true}
            disableDropdown={true}
            disabled={true}
            inputClass="contacts-form__input-phone contacts-show__input"
            containerClass="contacts-form__phone-container"
            buttonClass="contacts-form__phone-dropdown"
          />
        </Form.Item>

        <Text className="contacts__label">{t("labels.additional")}</Text>

        <Form.Item className="contacts-form__item">
          <a
            href={
              contactsData.instagram
                ? `https://www.instagram.com/${contactsData.instagram}`
                : undefined
            }
            target="_blank"
            rel="noopener noreferrer"
            className={`contacts-form__clickable ${contactsData.instagram ? "" : "contacts-form__disabled"}`}
          >
            <div className="contacts-form__input-fake">
              <InstagramOutlined className="contacts-form__prefix-icon" />
              {contactsData.instagram ? (
                <span className="contacts-form__text">{contactsData.instagram}</span>
              ) : (
                <span className="contacts-form__placeholder">{t("placeholders.instagram")}</span>
              )}
            </div>
          </a>
        </Form.Item>

        <Form.Item className="contacts-form__item">
          <a
            href={contactsData.telegram ? `https://t.me/${contactsData.telegram}` : undefined}
            target="_blank"
            rel="noopener noreferrer"
            className={`contacts-form__clickable ${contactsData.telegram ? "" : "contacts-form__disabled"}`}
          >
            <div className="contacts-form__input-fake">
              <span className="contacts-form__prefix-icon">
                <Telegram className="contacts-form__prefix-icon" />
              </span>
              {contactsData.telegram ? (
                <span className="contacts-form__text">{contactsData.telegram}</span>
              ) : (
                <span className="contacts-form__placeholder">{t("placeholders.telegram")}</span>
              )}
            </div>
          </a>
        </Form.Item>

        <Form.Item className="contacts-form__item">
          <Input
            value={contactsData.email}
            placeholder={t("placeholders.email")}
            disabled
            className="contacts-form__input contacts-show__input-phone"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ShowView;
