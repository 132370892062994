import React from 'react';
import { Row, Col, Typography, Skeleton } from 'antd';
import translator from '@/translator';
import classNames from 'classnames';
const t = translator('front_office.views.yclients_notification_settings.');
const { Text } = Typography;

const TextPreview = ({
  text,
  attachmentUrl,
  selectedSalonName,
  isLoading,
  skeletonHeight,
  disabled = false,
}) => {
  return (
    <Row gutter={[0, 8]}>
      <Col sm={24} />
      <Col span={24}>
        <Text disabled={disabled}>{t('fields.text_preview')}</Text>
      </Col>
      <Col span={24}>
        <div
          className={classNames('yclients-notification-settings-create__preview', {
            'yclients-notification-settings-create__preview-disabled': disabled,
          })}
        >
          <Skeleton
            loading={!text || isLoading}
            avatar={{ size: 36 }}
            title
            active
            paragraph={{ rows: skeletonHeight }}
          >
            <div className="yclients-notification-settings-create__preview-avatar">
              {selectedSalonName[0]}
            </div>
            <div className="yclients-notification-settings-create__preview-text">
              <div className="yclients-notification-settings-create__preview-text-header">
                <Text disabled={disabled} strong>
                  {selectedSalonName}
                </Text>
                <Text disabled={disabled} type="secondary">
                  {t('fields.text_preview_time')}
                </Text>
              </div>
              {attachmentUrl && (
                <img
                  src={attachmentUrl}
                  className="yclients-notification-settings-create__preview-attachment"
                />
              )}
              <Text disabled={disabled} style={{ whiteSpace: 'pre-wrap' }}>
                {text}
              </Text>
            </div>
          </Skeleton>
        </div>
      </Col>
    </Row>
  );
};

export default TextPreview;
