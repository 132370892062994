import React from 'react';
import { Row, Cell } from '@components/grid';
import { InfoPane } from '../InfoPane';
import { FooterButtons } from '../FooterButtons';
import DocumentIcon from '../../../Icons/DocumentIcon';

import translator from '@/translator';
const t = translator('front_office.views.integrations.telegram_bot.notification.');

export default ({ setStep, forwardStep, backwardStep, backAction }) => {
  const handleForward = () => {
    setStep(forwardStep);
  };
  return (
    <>
      <Row>
        <Cell>
          <InfoPane title={t('title')} icon={<DocumentIcon />}>
            <p>{t('text')}</p>
          </InfoPane>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <FooterButtons
            backwardAction={() => backAction(backwardStep)}
            forwardAction={handleForward}
          />
        </Cell>
      </Row>
    </>
  );
};
