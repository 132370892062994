import translator from "@/translator";
import React, { useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { StateContext } from "./State";
import ContactsView from "./components/ContactsView";
import DeliveryView from "./components/DeliveryView";
import SuccessView from "@/note_office/shared/SuccessView";
import TemplateView from "./components/TemplateView";
import ScheduleView from "./components/ScheduleView";
const t = translator("note_office.views.bulk_deliveries.new_view.");

const NewView = (props) => {
  const [state, setState] = useState({
    ...props,
    selectedContacts: [],
  });

  const updateState = (newState) => {
    setState((state) => ({ ...state, ...newState }));
  };

  const { profile_url } = state.bulkDeliveriesData || {};

  const successSendText = <>{t("send_delivery")}</>;
  const successScheduleText = <>{t("schedule_delivery")}</>;

  return (
    <StateContext.Provider value={{ state, updateState }}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<ContactsView />} />
          <Route path="/create" element={<DeliveryView />} />
          <Route
            path="/send_delivery"
            element={
              <SuccessView
                text={successSendText}
                link={profile_url}
                buttonText={t("back_to_main")}
              />
            }
          />
          <Route
            path="/schedule_delivery"
            element={
              <SuccessView
                text={successScheduleText}
                link={profile_url}
                buttonText={t("back_to_main")}
              />
            }
          />
          <Route path="/template" element={<TemplateView />} />
          <Route path="/schedule" element={<ScheduleView />} />
        </Routes>
      </HashRouter>
    </StateContext.Provider>
  );
};

export default NewView;
