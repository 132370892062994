import React, { useState } from 'react';

import Notification from './Notification';
import BotCreation from './BotCreation';
import ConnectingBot from './ConnectingBot';
import BotInfo from './BotInfo';
import LastStep from './LastStep';
import QuestionStep from './QuestionStep';
import RoutingSelector from '../RoutingSelector';

import { Container, Row, Cell } from '@components/grid';
import { Header } from './Header';

export default ({
  setVariant,
  variantsStep,
  telegramBotsUrl,
  botName,
  hasTelegramBot,
  hasTdLib,
  dispatchingRouteUrl,
  customerBotMenuTitle,
  customerBotMenuLink,
  currentRoutingSet,
  routingList,
  editTelegramBotsUrl,
  editTdLibSessionsUrl,
  foreignCustomer,
}) => {
  const [step, setStep] = useState('notification');
  const [customerHasBot, setCustomerHasBot] = useState(hasTelegramBot);

  const currentComponent = {
    notification: {
      component: (
        <Notification
          setStep={setStep}
          backAction={setVariant}
          forwardStep="botCreation"
          backwardStep={variantsStep}
        />
      ),
      stepNumber: 1,
    },

    botCreation: {
      component: <BotCreation setStep={setStep} />,
      stepNumber: 2,
    },
    connectingBot: {
      component: (
        <ConnectingBot
          setStep={setStep}
          telegramBotsUrl={telegramBotsUrl}
          customerHasBot={customerHasBot}
          setCustomerHasBot={setCustomerHasBot}
          botName={botName}
        />
      ),
      stepNumber: 3,
    },
    botInfo: {
      component: <BotInfo setStep={setStep} />,
      stepNumber: 4,
    },
    routingSelector: {
      component: (
        <RoutingSelector
          routingList={routingList}
          currentRoutingSet={currentRoutingSet}
          dispatchingRouteUrl={dispatchingRouteUrl}
          setStep={setStep}
          forwardStep="questionStep"
          backwardStep="botInfo"
          hasTelegramBot={customerHasBot}
          hasTdLib={hasTdLib}
          editTdLibSessionsUrl={editTdLibSessionsUrl}
          editTelegramBotsUrl={editTelegramBotsUrl}
        />
      ),
      stepNumber: 5,
    },
    questionStep: {
      component: (
        <QuestionStep
          setStep={setStep}
          telegramBotsUrl={telegramBotsUrl}
          customerBotMenuTitle={customerBotMenuTitle}
          customerBotMenuLink={customerBotMenuLink}
        />
      ),
      stepNumber: 6,
    },
    lastStep: {
      component: <LastStep setStep={setStep} />,
    },
  };
  const { component, stepNumber } = currentComponent[step];

  return (
    <Container className="telegram-flow">
      {stepNumber && (
        <Row>
          <Cell>
            <Header stepNumber={stepNumber} />
          </Cell>
        </Row>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column">
        {component}
      </div>
    </Container>
  );
};
