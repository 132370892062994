import React from 'react';
import { Container, Row, Cell } from '@components/grid';

import translator from '@/translator';
const t = translator('front_office.views.payments.variants.success.');

const Success = ({ textElements, endImg }) => {
  const {
    buttonLink,
    success_header,
    success_message,
    card_link_text,
    card_link_desc,
    isButtonShowed,
  } = textElements;
  const Button = () => (
    <a href={buttonLink} className="btn_v2-primary">
      {card_link_text}
    </a>
  );

  return (
    <Container>
      <Row>
        <Cell md={4} lg={4}></Cell>
        <Cell md={4} lg={4}>
          <div className="payment-header end">
            <div className="flex flex__center">
              <img src={endImg} style={{ maxWidth: '219px' }} />
            </div>
            <h3>{success_header}</h3>
            <h3>{success_message}</h3>
            <div className="desc">{card_link_desc}</div>
            {isButtonShowed && <Button />}
          </div>
        </Cell>
        <Cell md={4} lg={4}></Cell>
      </Row>
    </Container>
  );
};

export default Success;
