import translator from "@/translator";
import React, { useContext, useEffect, useState } from "react";
import { Sms, Whatsapp } from "@/note_office/icons/profile";
import { Menu, Switch, Input, Button, message, Space } from "antd";
import { StateContext } from "../State";
import { useNavigate } from "react-router-dom";
import BackButton from "@/note_office/shared/BackButton";

const t = translator("note_office.views.bulk_deliveries.new_view.");
const { TextArea } = Input;

const DeliveryView = () => {
  const { state, updateState } = useContext(StateContext);
  const {
    selectedTemplateText = "",
    selectedChannels = { whatsapp: true, sms: true },
    selectedContacts = [],
  } = state;

  const [messageText, setMessageText] = useState(state.selectedTemplateText || "");
  const [channels, setChannels] = useState({
    whatsapp: state.selectedChannels?.whatsapp ?? true,
    sms: state.selectedChannels?.sms ?? true,
  });

  const navigate = useNavigate();

  const toggleChannel = (channel) => {
    setChannels((prev) => {
      const updated = { ...prev, [channel]: !prev[channel] };
      updateState({ selectedChannels: updated });
      return updated;
    });
  };

  const handleMessageChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleCreateTemplate = () => {
    message.success(t("template_created"));
  };

  const handleGoToTemplates = () => {
    navigate("/template");
  };

  const handleSendBulkDelivery = () => {
    navigate("/send_delivery");
  };

  const handleScheduleBulkDelivery = () => {
    navigate("/schedule");
  };

  const whatsapp = [
    {
      key: "whatsapp",
      icon: <Whatsapp />,
      label: (
        <div className="profile__menu-item" onClick={() => toggleChannel("whatsapp")}>
          <span className="bulk_deliveries-new__channels">{t("whatsapp")}</span>
          <Switch checked={channels.whatsapp} />
        </div>
      ),
    },
  ];

  const sms = [
    {
      key: "sms",
      icon: <Sms />,
      label: (
        <div className="profile__menu-item" onClick={() => toggleChannel("sms")}>
          <span className="bulk_deliveries-new__channels">{t("sms")}</span>
          <Switch checked={channels.sms} />
        </div>
      ),
    },
  ];

  const handleBackToContacts = () => {
    navigate("/");
  };

  useEffect(() => {
    if (selectedTemplateText && selectedTemplateText !== messageText) {
      setMessageText(selectedTemplateText);
    }
    if (selectedChannels && JSON.stringify(selectedChannels) !== JSON.stringify(channels)) {
      setChannels(selectedChannels);
    }
  }, [selectedTemplateText, selectedChannels]);

  return (
    <div className="wrapper wrapper__menu-layout overflow">
      <BackButton onClick={handleBackToContacts} text={t("title")} />

      <div className="bulk_deliveries-new__content overflow">
        <div>
          <div>
            <h3 className="bulk_deliveries-new__label">{t("channels_label")}</h3>
            <p className="bulk_deliveries-new__description">{t("channels_description")}</p>
          </div>

          <Menu
            mode="vertical"
            items={whatsapp}
            className="profile__menu profile__menu-margin"
            selectedKeys={[]}
          />

          <Menu mode="vertical" items={sms} className="profile__menu" selectedKeys={[]} />

          <div className="bulk_deliveries-new__text">
            <h3 className="bulk_deliveries-new__label">{t("message_text_label")}</h3>
            <TextArea
              value={messageText}
              onChange={handleMessageChange}
              placeholder={t("message_placeholder")}
              maxLength={600}
              rows={5}
              className="bulk_deliveries-new__textarea"
            />
          </div>

          <div className="bulk_deliveries__actions">
            <a
              className="bulk_deliveries__actions_button bulk_deliveries-new__btn-action"
              onClick={handleCreateTemplate}
            >
              {t("create_template")}
            </a>

            <a
              className="bulk_deliveries__actions_button bulk_deliveries-new__btn-action"
              onClick={handleGoToTemplates}
            >
              {t("my_templates")}
            </a>
          </div>
        </div>
        <div className="button-group">
          <Button type="primary" className="button-base" block onClick={handleSendBulkDelivery}>
            {t("send_to_contacts", { count: selectedContacts.length })}
          </Button>

          <Button
            type="primary"
            className="button-base button-blue"
            block
            onClick={handleScheduleBulkDelivery}
          >
            {t("schedule")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryView;
