import React from 'react';
import { useState } from 'react';
import { Row, Cell } from '@components/grid';
import { InfoPane } from '../InfoPane';
import Button from '@components/Button';
import { Popover } from 'antd';
import { FooterButtons } from '../FooterButtons';
import DocumentIcon from '../../../Icons/DocumentIcon';
import VideoView from '../VideoView';

import translator, { locale_for_video } from '@/translator';
const t = translator('front_office.views.integrations.telegram_bot.bot_info.');

export default ({ setStep }) => {
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const popoverContent = (
    <div>
      <p className="telegram-flow_popover-text">{t('popover_content')}</p>
      <a className="d-flex justify-content-end telegram-flow_popover-close" onClick={hide}>
        {t('close_button')}
      </a>
    </div>
  );

  return (
    <div className="telegram-flow_split-pane">
      <VideoView link={`/step4_${locale_for_video()}.mp4`} />
      <div className="telegram-flow_split-pane_right">
        <Row>
          <Cell>
            <InfoPane title={t('info_pane_title')}>
              <p>{t('info_pane_text')}</p>
              <Popover
                content={popoverContent}
                placement="bottom"
                trigger="click"
                open={visible}
                onOpenChange={handleVisibleChange}
              >
                <Button className="btn_v2-secondary telegram-flow_unbind_button">
                  <span className="telegram-flow_document_icon">
                    <DocumentIcon background="gray" height={16} />
                  </span>
                  {t('example_button_text')}
                </Button>
              </Popover>
            </InfoPane>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <FooterButtons
              forwardAction={() => setStep('routingSelector')}
              backwardAction={() => setStep('connectingBot')}
            ></FooterButtons>
          </Cell>
        </Row>
      </div>
    </div>
  );
};
