import React from 'react';
import { Row } from 'antd';
import { useNotificationsState } from './State';
import TextWithPreview from './TextWithPreview';
import translator from '@/translator';
const t = translator('front_office.views.altegio_notification_settings.');

const createFeedbackStep = ({ type, helpText = t('fields.bot_response_help') }) => ({
  title: t(`steps.${type}_feedback`),
  validation: (formValues, state) =>
    (formValues[`${type}_feedback_body`] || '').length > 0 &&
    (state[`${type}_feedback_body_errors`] || []).length === 0,
  Component: ({ isVisible }) => {
    const { state } = useNotificationsState();

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
        <TextWithPreview
          defaultValue={state['bot_responses'][`${type}_feedback`]['text']}
          fieldName={`${type}_feedback_body`}
          isWithAttachment={false}
          isVisible={isVisible}
          title={t('fields.bot_response')}
          helpText={helpText}
        />
      </Row>
    );
  },
});

export default createFeedbackStep;
