import React, { useState, useEffect } from 'react';
import { Upload, Button, Form, Row, Col, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import translator from '@/translator';
const t = translator('front_office.views.bulk_deliveries.form.upload_image.');

const UploadImage = ({ imageFile, onFileChange, isTelegramBotSelected, isSmsSelected }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!imageFile) setFileList([]);
  }, [imageFile]);

  const handleChange = (e) => {
    onFileChange(e.fileList.length > 0 ? e.file : '');
    setFileList(e.fileList);
  };

  return (
    <Form.Item>
      <Row gutter={30}>
        <Col span={24} md={24} lg={16}>
          <Space direction="vertical">
            <Upload
              fileList={fileList}
              onChange={handleChange}
              beforeUpload={(_file) => false}
              maxCount={1}
              accept="image/jpeg,image/png,image/webp"
            >
              {!imageFile && <Button icon={<UploadOutlined />}>{t('attach_image')}</Button>}
            </Upload>
            {(isTelegramBotSelected || isSmsSelected) && (
              <p className="help-block">{t('help_block')}</p>
            )}
          </Space>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default UploadImage;
