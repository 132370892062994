import React, { useEffect, useState } from "react";
import { Table, DatePicker, Button, Space, Spin } from "antd";
import axios from "axios";
import DropdownMenu from "./components/DropdownMenu";
import BackButton from "@/note_office/shared/BackButton";
import Calendar from "@/note_office/icons/Calendar2";
import translator from "@/translator";
import CustomPagination from "@/note_office/shared/CustomPagination";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import enUS from "antd/es/locale/en_US";
import ptPT from "antd/es/locale/pt_PT";

const t = translator("note_office.views.deliveries.index_view.");

const localeMapping = {
  ru: "ru",
  en: "en",
  "pt-BR": "pt",
};

const antdLocales = {
  ru: ruRU,
  en: enUS,
  pt: ptPT,
};

const IndexView = ({ deliveriesData }) => {
  const {
    deliveries: initialDeliveries = [],
    total_pages: initialTotalPages = 1,
    per_page: initialPerPage = 8,
    profile_url,
    deliveries_url,
  } = deliveriesData || {};

  const [deliveries, setDeliveries] = useState(initialDeliveries);
  const [totalPages, setTotalPages] = useState(initialTotalPages);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [sortOrder, setSortOrder] = useState("executed_at desc");
  const [pickerOpen, setPickerOpen] = useState(false);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [loading, setLoading] = useState(false);

  const customerLocale = localeMapping[deliveriesData?.customer?.locale] || "en";

  useEffect(() => {
    fetchDeliveries(1);
  }, [selectedDate, sortOrder]);

  const fetchDeliveries = async (page = 1) => {
    setLoading(true);

    const query = { s: sortOrder };
    if (selectedDate) {
      query.executed_at_gteq = selectedDate.startOf("day").format("YYYY-MM-DD");
    }

    const params = { q: query, page };

    try {
      const { data } = await axios.get(deliveries_url, { params });

      setDeliveries(data.deliveries);
      setTotalPages(data.total_pages);
      setCurrentPage(page);
      setPerPage(data.per_page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: t("columns.id"), dataIndex: "id", key: "id" },
    { title: t("columns.executed_at"), dataIndex: "executed_at", key: "executed_at" },
    {
      title: t("columns.text"),
      dataIndex: "text",
      key: "text",
      className: "text-col",
    },
    { title: t("columns.status"), dataIndex: "status", key: "status" },
    { title: t("columns.sender_name"), dataIndex: "sender_name", key: "sender_name" },
  ];

  const handleSortChange = (key) => {
    const [currentField, currentDirection] = sortOrder.split(" ");
    const newField = key;

    const defaultDirections = {
      executed_at: "desc",
      "delivery_status.status": "asc",
      sender_name: "asc",
    };

    if (currentField === newField) {
      const toggledDirection = currentDirection === "asc" ? "desc" : "asc";
      setSortOrder(`${newField} ${toggledDirection}`);
    } else {
      setSortOrder(`${newField} ${defaultDirections[newField] || "asc"}`);
    }
  };

  return (
    <ConfigProvider locale={antdLocales[customerLocale]}>
      <div className="wrapper wrapper__menu-layout overflow">
        <BackButton link={profile_url} text={t("title")}>
          <Space>
            <DropdownMenu onSortChange={handleSortChange} />
            <DatePicker
              open={pickerOpen}
              onOpenChange={setPickerOpen}
              value={selectedDate}
              allowClear={false}
              showToday={false}
              popupClassName="calendar"
              style={{
                width: 0,
                padding: 0,
                border: "none",
                visibility: "hidden",
              }}
              onChange={(date) => {
                setSelectedDate(date);
                setPickerOpen(false);
              }}
              renderExtraFooter={() => (
                <div style={{ textAlign: "center" }}>
                  <Button type="link" onClick={() => setSelectedDate(null)}>
                    {t("reset_date")}
                  </Button>
                </div>
              )}
            />
            <Button type="text" icon={<Calendar />} onClick={() => setPickerOpen(true)} />
          </Space>
        </BackButton>

        <div className="overflow wrapper" style={{ borderRadius: "6px", position: "relative" }}>
          {deliveries.length === 0 ? (
            <div
              style={{ textAlign: "center", padding: "2rem 0", fontSize: "16px", color: "#999" }}
            >
              {t("no_deliveries_message")}
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={deliveries}
              pagination={false}
              rowKey="id"
              scroll={{ x: "max-content" }}
            />
          )}
          {loading && <Spin size="large" className="loader" />}
        </div>

        {totalPages > 1 && (
          <CustomPagination
            current={currentPage}
            total={totalPages * perPage}
            pageSize={perPage}
            onChange={(page) => fetchDeliveries(page)}
          />
        )}
      </div>
    </ConfigProvider>
  );
};

export default IndexView;
