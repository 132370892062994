import React from 'react';
import { Row, Col, Form, Input } from 'antd';

import translator from '@/translator';
const t = translator('front_office.views.bulk_deliveries.form.phones_field.');

const { TextArea } = Input;

const PhonesField = ({ phonesTexarea, onClick, onChange, phonesCount }) => {
  return (
    <Form.Item label={t('label')}>
      <Row gutter={30} vgutter={15}>
        <Col span={24} md={24} lg={16}>
          <TextArea id="phones" name="phone_numbers" value={phonesTexarea} onChange={onChange} />
          <input type="hidden" name="phone_list" id="hidden_field_phones" />
        </Col>
        <Col span={24} md={24} lg={8}>
          <div className="help-box">
            <span
              className="btn btn-sm btn-secondary small"
              id="clear_phones_list_area"
              onClick={onClick}
            >
              {t('clear_button')}
            </span>
            <p className="help-block pt-3">
              {t('entered_numbers')}&nbsp;<b id="phones_count_value"> {phonesCount} </b>
            </p>
            <p className="help-block">{t('info_text')}</p>
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default PhonesField;
